import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import Admin from '../models/admin.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private dbPath = '/users';

  adminsRef: AngularFirestoreCollection<Admin>;

  constructor(private db: AngularFirestore) {
    this.adminsRef = db.collection(this.dbPath);
  }

  getAll(): AngularFirestoreCollection<Admin> {
    return this.adminsRef;
  }

  create(admin: Admin): any {
    return this.adminsRef.add({ ...admin });
  }

  update(id: string, data: any): Promise<void> {
    return this.adminsRef.doc(id).update(data);
  }

  delete(id: string): Promise<void> {
    return this.adminsRef.doc(id).delete();
  }
}
