<div *ngIf="auth.user | async as user; else show">
    <div class="flexscreen">
        <div class="heading">
            <div class="formtitle">Loan Payments</div>
            <div class="formsubtitle">Add received payments for gold loans.</div>
        </div>

        <input class="search-box" placeholder="Search using pawn ticket" (keyup)=search($event.target.value)
            type="text">
        <div class="users" *ngFor="let loan of filteredLoans$ | async">
            <div class="user">
                <div class = "loantitle">PAWN TICKET #{{loan?.id}}</div>
            <div class = "userlabel">Date : {{loan?.date}}/{{loan?.month}}/{{loan?.year}}</div>
            <div class = "userlabel">Customer Name : {{loan?.details.name}}</div>
            <div class = "userlabel">Customer Address : {{loan?.details.address}}</div>
            <div class = "userlabel">Customer Phone : {{loan?.details.phone}}</div>
            <div class = "userlabel">Customer Email : {{loan?.details.email}}</div>
            <div class = "userlabel">Transaction Fee : ${{ loan?.details.transactionfee }}</div>
            <div class = "userlabel">Loan Amount : ${{loan?.details.principle}}</div>
            <div class = "userlabel">Rate of Interest : {{loan?.details.rate}}%</div>
            <div class = "userlabel">Duration : {{loan?.details.time}} <span *ngIf = "loan?.details.type == 'Weekly'">weeks</span><span *ngIf = "loan?.details.type == 'Monthly'">months</span><span *ngIf = "loan?.details.type == 'Fortnightly'">fortnights</span></div>
            <div class = "userlabel">Total Interest : ${{ loan?.details.totalinterest }}</div>
            <div class="userlabel">{{ loan?.details.type }} Interest : ${{
                loan?.details.totalinterest/loan?.details.time | number: '1.0-2' }}</div>
            <div class = "userlabel">Total Revenue Received : ${{ loan?.totalrevenue }}</div>
            <div *ngIf = "loan?.fullpaymentloanpaid>0">
                <div class = "userlabel">Full Payment received on : {{ loan?.fullpaymentdate }}</div>
                <div class = "userlabel">Full Payment Amount received : ${{ loan?.fullpaymentloanpaid }}</div>
            <div class = "userlabel">Full Payment Interest received : ${{ loan?.fullpaymentinterestpaid }}</div>
                </div>
                <div class = "userlabel">Comments : {{ loan?.comment }}</div>
            <div class = "buttons">
                <div *ngIf = "!loan.fullpaymentloanpaid">
                <button class = "gbutton" (click) = "showCloseLoanForm(loan?.id)">Full Payment Recieved</button>
                </div>
                <div *ngIf = "!loan.loanclosefinalpaid">
                <button class = "sbutton" (click) = "showSoldLoanForm(loan?.id)">Sold</button>
                </div>
                <button class = "cbutton" (click) = "showItemForm(loan?.id)">Add Comment</button>
                </div>
                <form class = "gform" id = "gform{{loan?.id}}">
                <mat-form-field>
                  <input matInput
                  class="form-control"
                  name="comment"
                  [(ngModel)]="loan.comment"  
                  >
                </mat-form-field> 
                <button mat-raised-button color="primary" class = "txbutton" (click)="updateStatus(loan?.id, loan?.comment)">Add Comment</button>
                </form> 


                <form class = "closeloanform" id = "closeloanform{{ loan?.id }}">
                  <mat-form-field>
                    <mat-label>
                        Final Loan returned
                    </mat-label>
                    <input matInput
                    class="form-control"
                    name="finalloanreturned"
                    [(ngModel)]="finalloanreturned"
                    type="number"
                    required 
                    >
                </mat-form-field>
                <mat-form-field>
                    <mat-label>
                        Final Interest received
                    </mat-label>
                    <input matInput
                    class="form-control"
                    name="finalinterestreceived"
                    [(ngModel)]="finalinterestreceived"
                    type="number"
                    required 
                    >
                </mat-form-field>
                <button mat-raised-button color="primary" class = "closebutton" (click) = "closeLoan(loan.id, loan.details.time, finalinterestreceived, finalloanreturned, loan.totalrevenue)">Submit</button>
                </form>


                <form class = "soldloanform" id = "soldloanform{{ loan?.id }}">
                    <mat-form-field>
                      <mat-label>
                          Final Loan returned
                      </mat-label>
                      <input matInput
                      class="form-control"
                      name="lcfinalloanreturned"
                      [(ngModel)]="lcfinalloanreturned"
                      type="number"
                      required 
                      >
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label>
                          Final Interest paid
                      </mat-label>
                      <input matInput
                      class="form-control"
                      name="lcfinalinterestreceived"
                      [(ngModel)]="lcfinalinterestreceived"
                      type="number"
                      required 
                      >
                  </mat-form-field>
                  <button mat-raised-button color="primary" class = "soldbutton" (click) = "soldLoan(loan.id, loan.details.principle, lcfinalinterestreceived, lcfinalloanreturned, loan.totalrevenue)">Submit</button>
                  </form>
            

            <div class = "paymentss" *ngFor = "let doc of loan?.pendingpayments | keyvalue: returnZero; let ix = index" id = "payment{{loan?.id}}">  
            <div class = "pendingtitle">Payment {{ ix+1 }}</div>
            <div class = "pgrid">
            <div class = "pleft">
            <div class = "userlabel">Due on : {{ doc.value.day }}.{{ doc.value.month }}.{{ doc.value.year }}</div>
            <div class = "userlabel">Status : {{ doc.value.status }}</div>
            <div *ngIf = "doc.value.status == 'Paid'">
            <div class = "userlabel">Payment received on : {{ doc.value.date | date: 'dd-MM-yyyy' }}</div>
            <div class = "userlabel">Interest received : ${{ doc.value.interestreceived }}</div>
            <div class = "userlabel">Loan returned : ${{ doc.value.loanreturned }}</div>
            </div>
            </div>  
            <div *ngIf = "doc.value.status !== 'Paid'" class = "pright">
                <button mat-raised-button color="primary" class = "paymentbutton" (click) = "showEditor(loan.id, ix+1)">Update Payment {{ ix+1 }}</button>
            </div>
            </div>
                    <form class = "editform" id = "{{ loan.id }}payment{{ ix+1 }}" #f="ngForm">
                        <mat-form-field>
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="picker"
                          name="date"
                          class="form-control"
                          [(ngModel)]="doc.value.date"
                          required/>
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>
                                Status
                            </mat-label>
                            <select matNativeControl required name="status" [(ngModel)]="doc.value.status">
                                <option value="Pending">Pending</option>
                                <option value="Half Paid">Half Paid</option>
                                <option value="Paid">Paid</option>
                              </select>
                        </mat-form-field>

                        <mat-form-field>
                          <mat-label>
                              Interest received
                          </mat-label>
                          <input matInput
                          class="form-control"
                          name="interestrecieved"
                          [(ngModel)]="doc.value.interestreceived"
                          required 
                          type="number"
                          >
                      </mat-form-field>
                      <mat-form-field>
                        <mat-label>
                            Loan returned
                        </mat-label>
                        <input matInput
                        class="form-control"
                        name="loanreturned"
                        [(ngModel)]="doc.value.loanreturned"
                        required 
                        type="number"
                        >
                    </mat-form-field>
                        <button mat-raised-button color="primary" class="paymentbutton"
                            (click)="updateLoan(loan.id, ix, loan.details.principle, doc.value.interestreceived, doc.value.loanreturned, doc.value.date, loan.totalrevenue, doc.value.status)">Finish adding payment</button>   
                      </form> 
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #show>
    <div class="errorscreen">
        <div class="errortitle">Unauthorised Access.</div>
    </div>
</ng-template>

<div class="notification" id="notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
        viewBox="0 0 24 24">
        <path
            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z" />
    </svg>
    <div id="notificationtext"></div>
</div>