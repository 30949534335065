import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";

@Component({
  selector: 'app-payloan',
  templateUrl: './payloan.component.html',
  styleUrls: ['./payloan.component.css']
})
export class PayloanComponent implements OnInit {

  loans: Array<any>;
  loans$: Observable<any[]>;
  payments$: Observable<any[]>;
  filteredLoans$: Observable<any[]>;
  math = Math;
  parsefloat = parseFloat;
  now: any;
  parsedate = this.parseDate;
  loanData: any;
  value: number;

  constructor(private firestore: AngularFirestore,
    public auth: AngularFireAuth) {
    this.loans$ = firestore.collection('loans', ref => ref.orderBy('id', 'desc').limit(1)).valueChanges({ idField: 'loanid' });
    this.loans$.subscribe(this.loanData);
  }

  ngOnInit() {
    this.filteredLoans$ = this.loans$;
    this.now = DateTime.now().toString();
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value: string) {
    if (value) {
      this.value = parseInt(value);
      this.filteredLoans$ = this.firestore.collection('loans', ref => ref.where('id', '==', this.value)).valueChanges({ idField: 'loanid' });
      this.filteredLoans$.subscribe(this.loanData);
    } else {
      this.filteredLoans$ = this.loans$;
    }
  }

  selectUser(email) {
    alert(email);
  }
  showEditor(pawnticket, paymentno) {
    var loanid = pawnticket + "payment" + paymentno;
    document.getElementById(loanid).classList.toggle('showform');
  }
  updateLoan(loanid, pid, ploan, interestreceived, loanreturned, date, revenue, status) {

    var id = loanid.toString();
    var paydate = date.toString();
    var xstatus = "pendingpayments." + pid + ".status";
    var xdate = "pendingpayments." + pid + ".date";
    var xinterestreceived = "pendingpayments." + pid + ".interestreceived";
    var xloanreturned = "pendingpayments." + pid + ".loanreturned";
    var xloanoutstanding = "pendingpayments." + pid + ".loanoutstanding";
    var pendingloan = ploan - loanreturned;
    var totalrevenue = parseInt(revenue) + parseInt(interestreceived);

    return this.firestore.collection('loans').doc(id).update({
      [xinterestreceived]: interestreceived,
      [xloanreturned]: loanreturned,
      [xloanoutstanding]: pendingloan,
      "pendingloan": pendingloan,
      "totalrevenue": totalrevenue,
      [xstatus]: status,
      [xdate]: paydate
    }).then(() => {
      this.notificationPop("Payment added successfully!");
    }).catch((error) => {
      this.notificationPop("Payment adding failed.");
    });
  }

  notificationHide() {
    document.getElementById('notification').classList.remove('pop');
  }

  notificationDisplay() {
    document.getElementById('notification').classList.add('pop');
  }

  notificationPop(message: string) {
    document.getElementById('notificationtext').innerHTML = message;
    this.notificationDisplay();
    setTimeout(this.notificationHide, 2500);
  }
  parseDate(gdate) {
    return DateTime.fromISO(gdate);
  }

  closeLoan(loanid, count, finalinterestreceived, finalloanreturned, revenue) {
    var id = loanid.toString();
    var now = DateTime.now().toString();
    var paydate = now;
    var totalrevenue = parseInt(finalinterestreceived) + parseInt(revenue);
    var eltbc = 'colseloanform' + loanid;
    for (var it = 0; it < count; it++) {
      var xstatus = "pendingpayments." + it + ".status";
      this.firestore.collection('loans').doc(id).update({
        [xstatus]: "Paid"
      });
    }
    return this.firestore.collection('loans').doc(id).update({
      "fullpaymentdate": paydate,
      "fullpaymentloanpaid": parseInt(finalloanreturned),
      "fullpaymentinterestpaid": parseInt(finalinterestreceived),
      "pendingloan": 0,
      "status": 1,
      "totalrevenue": totalrevenue,
    }).then(() => {
      document.getElementById(eltbc).classList.remove('visible');
      this.notificationPop("Payment added successfully!");
    }).catch((error) => {
      this.notificationPop("Payment adding failed.");
    });
  }

  soldLoan(loanid, ploan, finalinterestreceived, finalloanreturned, revenue) {
    var id = loanid.toString();
    var pendingloan = ploan - finalloanreturned;
    var totalrevenue = parseInt(revenue) + parseInt(finalinterestreceived);
    var now = DateTime.now().toString();
    var eltbc = 'soldloanform' + loanid;
    return this.firestore.collection('loans').doc(id).update({
      "loanclosedate": now,
      "loanclosefinalpaid": parseInt(finalloanreturned),
      "loancloseinterestpaid": parseInt(finalinterestreceived),
      "pendingloan": pendingloan,
      "status": 4,
      "totalrevenue": totalrevenue,
    }).then(() => {
      document.getElementById(eltbc).classList.remove('visible');
      this.notificationPop("Loan labelled sold successfully!");
    }).catch((error) => {
      this.notificationPop("Loan labelled sold failed.");
      console.log(error);
    });
  }

  showCloseLoanForm(loanid) {
    var closer = 'closeloanform' + loanid;
    var solder = 'soldloanform' + loanid;
    if (document.getElementById(solder).classList.contains('visible')) {
      document.getElementById(solder).classList.remove('visible');
    }
    document.getElementById(closer).classList.add('visible');
  }

  showSoldLoanForm(loanid) {
    var close = 'soldloanform' + loanid;
    var solde = 'closeloanform' + loanid;
    if (document.getElementById(solde).classList.contains('visible')) {
      document.getElementById(solde).classList.remove('visible');
    }
    document.getElementById(close).classList.add('visible');
  }

  returnZero() {
    return 0
  }


  updateStatus(id, comment) {
    var sid = id.toString();
    return this.firestore.collection('loans').doc(sid).update({
      "comment": comment
    }).then(() => {
      this.notificationPop("Comment added successfully.");
    }).catch((error) => {
      this.notificationPop("Comment could not be added.");
    });
  }

  showItemForm(theid) {
    var rtheid = "gform" + theid;
    document.getElementById(rtheid).classList.toggle('edititem');
  }


}
