import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DateTime } from "luxon";
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';
import { map, filter } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-tc',
  templateUrl: './tc.component.html',
  styleUrls: ['./tc.component.css']
})
export class TcComponent implements OnInit {

  fileName = 'RevenueGoldLoans.xlsx';
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  now: any;
  loanarray: any;

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit(): void {
    this.now = DateTime.now();
    this.loanarray = [];
  }

  filterByDate() {
    const queryObservable = this.afs.collection('loans', ref => ref.orderBy('id','desc')).valueChanges();
    queryObservable.subscribe(queriedItems => {
      for (let [index, loan] of queriedItems.entries()) {
        const [cchecker, crevenue] = this.filterLoans(loan);
        if(cchecker == true) {
          this.loanarray[index] = loan;
          this.loanarray[index].erevenue = crevenue;
        }
      }
    });
  }

  filterLoans(loan) {
    var revenue : number = 0;
    var checker = false;
    for (var looper = 0; looper < loan.details.time; looper++) {
      var datetobechecked = DateTime.fromISO(loan.pendingpayments[looper].date);
      if (datetobechecked > this.range.controls['start'].value && datetobechecked < this.range.controls['end'].value) {
        revenue = revenue + parseInt(loan.pendingpayments[looper].interestreceived);
        checker = true;
      }
    }
    return [checker, revenue];
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
}