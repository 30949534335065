import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-gold',
  templateUrl: './gold.component.html',
  styleUrls: ['./gold.component.css']
})
export class GoldComponent implements OnInit {

  item$: Observable<any[]>;

  constructor(
    private firestore: AngularFirestore,
    public auth: AngularFireAuth
    ) {
      this.item$ = firestore.collection('purchaseditems', ref => ref.orderBy('id','desc')).valueChanges();
    }

  ngOnInit() {
  }

}
