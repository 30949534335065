<div *ngIf="loggedin; else showLogin">
  <div class="flexuserscreen">
    <div class="paymentdetails">
      <div class="paymentdetailstext">
          <span>Payment Details</span><br>
          Account Name : Prakahs<br>
          BSB : 063-245<br>
          Account : 10843869<br>
          Ref : Pawn Ticket #{{ id }}
      </div>
  </div>
      <div class = "username">Hello, {{ uname }}</div>
      <div class="xbutton" (click)="logout()">Log out</div>
      <p>The following is your loan history.</p>
    
    <h2>Gold loans</h2>
    <div class="users" *ngFor="let goldloan of goldloan$ | async">
      <div class="user">
        <div class="loantitle">PAWN TICKET #{{goldloan.id}}</div>
        <div class="userlabel">Type : {{goldloan.details.type}}</div>
        <div class="userlabel">Date : {{goldloan.date}}/{{goldloan.month}}/{{goldloan.year}}</div>
        <div class="userlabel">Transaction Fee : ${{ goldloan.details.transactionfee }}</div>
        <div class="userlabel">Loan Amount : ${{goldloan.details.principle}}</div>
        <div class="userlabel">Rate of Interest % : {{goldloan.details.rate}}</div>
        <div class="userlabel">Duration : {{goldloan.details.time}}</div>
        <div class="userlabel">{{ goldloan?.details.type }} Interest : ${{
          goldloan?.details.totalinterest/goldloan?.details.time | number: '1.0-2' }}</div>
        <div class="gitemholder">
          <div *ngFor="let doc of goldloan.items | keyvalue; let ix = index">
            <div class="itemc">
              <div class="itemp">
                <img src="{{ goldloan.urls[ix] }}" />
              </div>
              <div class="userlabel">Gold Item {{ ix+1 }}</div>
              <div class="userlabel">Type : {{ doc.value.type }}</div>
              <div class="userlabel">Weight : {{ doc.value.weight }}</div>
              <div class="userlabel">Diamond : {{ doc.value.diamond }}</div>
              <div class="userlabel">Carats : {{ doc.value.carats }}</div>
              <div class="userlabel">Additional info : {{ doc.value.additional }}</div>
            </div>
          </div>
        </div>
        <div class="deets">
          <div class="phead">Payment schedule :</div>
          <div class="paymentss" *ngFor="let pdoc of goldloan?.pendingpayments | keyvalue; let px = index"
            id="payment{{ix+1}}">
            <div class="ebox">
              <div class="ehead">Payment {{ px+1 }}</div>
              <div class="userlabel">Due on : {{ pdoc.value.day }}.{{ pdoc.value.month }}.{{ pdoc.value.year }}</div>
              <div class="userlabel">Status : {{ pdoc.value.status }}</div>
              <div *ngIf="pdoc.value.status == 'Paid' && loan?.status == 0">
                <div class="userlabel">Payment received on : {{ pdoc.value.date }}</div>
                <div class="userlabel">Loan Outstanding : ${{ pdoc.value.loanoutstanding }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2>Personal loans</h2>
    <div class="users" *ngFor="let loan of loan$ | async">
      <div class="user">
        <div class="loantitle">PAWN TICKET #{{loan.id}}</div>
        <div class="userlabel">Type : {{loan.details.type}}</div>
        <div class="userlabel">Date : {{loan.date}}/{{loan.month}}/{{loan.year}}</div>
        <div class="userlabel">Transaction Fee : ${{ loan.details.transactionfee }}</div>
        <div class="userlabel">Loan Amount : ${{loan.details.principle}}</div>
        <div class="userlabel">Rate of Interest % : {{loan.details.rate}}</div>
        <div class="userlabel">Duration : {{loan.details.time}}</div>

        <div class="deets">
          <div class="phead">Payment schedule :</div>
          <div class="paymentss" *ngFor="let pdoc of loan?.pendingpayments | keyvalue; let px = index"
            id="payment{{ix+1}}">
            <div class="ebox">
              <div class="ehead">Payment {{ px+1 }}</div>
              <div class="userlabel">Due on : {{ pdoc.value.day }}.{{ pdoc.value.month }}.{{ pdoc.value.year }}</div>
              <div class="userlabel">Status : {{ pdoc.value.status }}</div>
              <div *ngIf="pdoc.value.status == 'Paid' && loan?.status == 0">
                <div class="userlabel">Payment received on : {{ pdoc.value.date }}</div>
                <div class="userlabel">Loan Outstanding : ${{ pdoc.value.loanoutstanding }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<ng-template #showLogin>
  <div class="xscreen">
    <div class="formcontainer">
      <div class="formtitle">Welcome to Prakah's Gold</div>
      <div class="formsubtitle">Please login to view your details.</div>
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group col-5">
          <input type="text" formControlName="user" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.user.errors }" placeholder="Enter your User ID" />
          <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
            <div *ngIf="f.user.errors.required">User ID is required</div>
          </div>
        </div>
        <div class="form-group col">
          <input type="password" formControlName="password" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Enter your password" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
          </div>
        </div>
        <button class="sbutton">Sign in</button>
      </form>
    </div>
  </div>
</ng-template>