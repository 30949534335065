<div *ngIf="auth.user | async as user; else show">
    <div class = "flexscreen">
        <div class = "exportbutton" (click)="exportexcel()">Export to Excel</div>
        <table id="excel-table"> 
        <tr>
            <th>#</th>
            <th>Pending Dates</th>
            <th>Date</th>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Loan Amount</th>
            <th>Rate of Interest</th>
            <th>Duration</th>
        </tr>
<tr class = "users notpending" *ngFor="let loan of loans$ | async; let ux = index" id = "{{ux}}">
<td>{{loan.id}}</td>
<td>
<div *ngFor = "let pdates of loan.pendingpayments | keyvalue; let px = index">
<div class = "pendingdates pending" *ngIf = "now > pdates.value.pendingdate && pdates.value.status !== 'Paid' && pdates.value.status !== 4">
  Payment {{ px+1 }} pending date - {{ pdates.value.day }}.{{ pdates.value.month }}.{{ pdates.value.year }}
  </div>
  <div class = "processor" *ngIf = "now > pdates.value.pendingdate && pdates.value.status !== 'Paid' && pdates.value.status !== 4; then showPending(ux, pdates.value.pendingdate, pdates.value.status)"></div>
  </div>
  </td>
<td>{{loan.date}}/{{loan.month}}/{{loan.year}}</td>
<td>{{loan?.details.name}}</td>
<td>{{loan?.details.address}}</td>
<td>{{loan?.details.phone}}</td>
            <td>${{loan.details.principle}}</td>
            <td>{{loan.details.rate}}</td>
            <td>{{loan.details.time}}</td>
            
</tr>
</table>
    </div>
    </div>
    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>