import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatStepper } from '@angular/material/stepper';
import { DateTime } from "luxon";

interface Dur {
  value: string;
}

@Component({
  selector: 'app-newloan',
  templateUrl: './newloan.component.html',
  styleUrls: ['./newloan.component.css']
})
export class NewloanComponent implements OnInit {
  selected: string;
  showTransactionFee: boolean = false;
  completed: false;
  durs: Dur[] = [
    { value: 'Weekly' },
    { value: 'Fortnightly' },
    { value: 'Monthly' }
  ];
  imgu: String;
  math = Math;
  itemurls = [];
  users$: Observable<any[]>;
  filteredUsers$: Observable<any[]>;
  loanuid: string;
  submitted = false;
  loanDetails: FormGroup;
  loanItems: FormGroup;
  loanHidden: FormGroup;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  loans$: any;
  loans: number;

  ploan: any;
  loanid: any;

  constructor(
    private firestore: AngularFirestore,
    private _formBuilder: FormBuilder,
    public auth: AngularFireAuth
  ) {
    this.users$ = firestore.collection('users').valueChanges({ idField: 'uid' });
  }

  ngOnInit() {
    this.filteredUsers$ = this.users$;

    this.loanDetails = this._formBuilder.group({
      loanuid: [''],
      name: [''],
      address: [''],
      phone: [''],
      email: [''],
      type: ['', Validators.required],
      date: ['', Validators.required],
      transactionfee: [0],
      principle: ['', Validators.required],
      rate: ['', Validators.required],
      time: ['', Validators.required],
      emi: [''],
      totalinterest: ['']
    });
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value: string) {
    if (value) {


      this.filteredUsers$ = this.users$
        .pipe(
          // the stream is of a single item that is of type array
          // map(user => user.name) would not work because it is not // a stream of items inside the array
          map((users: any[]) => {
            // inside the map we use the native Array.prototype.filter() method to filter down the results by name
            return users.filter((user: any) => user.details.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
          })

        )
    } else {
      // reload the full data set
      this.filteredUsers$ = this.users$;
    }
  }

  selectUser(email) {
    alert(email);
  }

  goForward(loanuid, name, phone, email, address, stepper: MatStepper) {
    this.loanDetails.controls['loanuid'].setValue(loanuid);
    this.loanDetails.controls['name'].setValue(name);
    this.loanDetails.controls['address'].setValue(address);
    this.loanDetails.controls['phone'].setValue(phone);
    this.loanDetails.controls['email'].setValue(email);
    stepper.next();
  }
  get fdetails() { return this.loanDetails.controls; }
  get fitems() { return this.loanItems.controls; }


  addLoan(stepper: MatStepper) {
    this.submitted = true;
    this.loanDetails.markAllAsTouched();
    if (this.loanDetails.invalid) {
      this.notificationPop("Check credentials");
      return;
    }
    this.createLoan();
  }



  onReset() {
    this.submitted = false;
    this.loanDetails.reset();
    this.loanItems.reset();
  }
  notificationHide() {
    document.getElementById('notification').classList.remove('pop');
  }

  notificationDisplay() {
    document.getElementById('notification').classList.add('pop');
  }

  notificationPop(message: string) {
    document.getElementById('notificationtext').innerHTML = message;
    this.notificationDisplay();
    setTimeout(this.notificationHide, 2500);
  }

  createLoan() {
    document.getElementById('useraddform').classList.add('vanish');
    document.getElementById('loading').classList.add('load');
      var formDate = this.loanDetails.controls['date'].value;
      var formISODate = formDate.toISOString();
      var loandate = DateTime.fromISO(formISODate);
      var monthadded = loandate.month;
      var dateadded = loandate.day;
      var yearadded = loandate.year;
    var cmi = this.loanDetails.controls['principle'].value * (this.loanDetails.controls['rate'].value / 100) * Math.pow((1 + (this.loanDetails.controls['rate'].value / 100)), this.loanDetails.controls['time'].value) / (Math.pow((1 + (this.loanDetails.controls['rate'].value / 100)), this.loanDetails.controls['time'].value) - 1);
    var cam = cmi * this.loanDetails.controls['time'].value;
    var cre = cam - this.loanDetails.controls['principle'].value + parseInt(this.loanDetails.controls['transactionfee'].value);

    var intt = (this.loanDetails.controls['principle'].value * this.loanDetails.controls['rate'].value * this.loanDetails.controls['time'].value)/100;
    var emi = Math.round((cmi + Number.EPSILON) * 100) / 100;
    var int = Math.round((intt + Number.EPSILON) * 100) / 100;

    


    this.loanDetails.controls['emi'].setValue(emi);
    this.loanDetails.controls['totalinterest'].setValue(int);
    
    var dt = DateTime.fromObject({day: dateadded, month: monthadded, year: yearadded});
    var wtime = parseInt(this.loanDetails.controls['time'].value);
    var pendingpayments = {};
    var i, weekly, fnightly, monthly;
    ///// Monthly
    if (this.loanDetails.controls['type'].value == 'Monthly') {
    for(i = 0; i < wtime; i++) {
      monthly = dt.plus({ months: i });
      pendingpayments[i] = {
        "pendingdate": monthly.toISO(),
        "day": monthly.day,
        "month": monthly.month,
        "year": monthly.year,
        "status": "Pending",
        "amount": 0,
        "paymentdate" : ""
      }; 
    }
    }
    ////// Weekly
    else if (this.loanDetails.controls['type'].value == 'Weekly') {
    for(i = 0; i < wtime; i++) {
      weekly = dt.plus({ days: i*7 });
      pendingpayments[i] = {
        "pendingdate": weekly.toISO(),
        "day": weekly.day,
        "month": weekly.month,
        "year": weekly.year,
        "status": "Pending",
        "amount": 0,
        "paymentdate" : ""
      }; 
    }
  }
  ////// Fortnightly
  else if (this.loanDetails.controls['type'].value == 'Fortnightly') {
    for(i = 0; i < wtime; i++) {
      fnightly = dt.plus({ days: i*14 });
      pendingpayments[i] = {
        "pendingdate": fnightly.toISO(),
        "day": fnightly.day,
        "month": fnightly.month,
        "year": fnightly.year,
        "status": "Pending",
        "amount": 0,
        "paymentdate" : ""
      };
    }
  }
      this.firestore.collection('metrics').doc('loans').ref.get().then((udoc) => {
        if (udoc.exists) {
          this.ploan = udoc.data();
          this.loanid = parseInt(this.ploan.loanidgen) + 1;
          
          let ploan = [
            { "details" : this.loanDetails.value },
            { "totalrevenue" : 0 },
            { "status" : 0 },
            { "id" : this.loanid },
            { "date" : dateadded, "month" : monthadded, "year" : yearadded },
            { "pendingpayments" : pendingpayments }
          ],
            object = Object.assign({}, ...ploan);

          this.firestore.collection(`loans`).doc(this.loanid.toString()).set(object);
          this.firestore.collection('metrics').doc('loans').update({
            "loanidgen": this.loanid
        }).then(() => {
        this.notificationPop("New loan added successfully!");
        document.getElementById('loading').classList.remove('load');
        document.getElementById('uploaded').classList.add('finished');
        }).catch((error) => {
        this.notificationPop("New loan add failed!");
        });

        }
        else {
          console.log("No such document!");
      }
  }).catch((error) => {
      console.log("Error getting document:", error);
  });
  }

  toggleTransactionFee(completed: boolean) {
    this.showTransactionFee = completed;
  }

}