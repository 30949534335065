<div *ngIf="auth.user | async as user; else show">
  <div class="selectscreen" id = "selectscreen">
  <div class = "loanformcontainer">
      <div class = "formtitle">Add a new loan</div>
      <div class = "formsubtitle">Select user for loan.</div>
</div> 
<div class = "search"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"/></svg>
<input class="search-box" placeholder="Search for a user" (keyup)=search($event.target.value) type="text"></div>
    <div class = "users">
        <div *ngFor="let user of filteredUsers$ | async">
        <div class = "user" (click) = "proceedLoan(user.email)">
        <div class = "userdp">
        <img [src] = "user.photoid" /></div>
        <div class = "usertitle">{{user.name}}</div>
        <div class = "userlabel"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg><div class = "llabel">{{user.email}}</div></div>
        <div class = "userlabel"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg><div class = "llabel">{{user.phone}}</div></div>
      </div>
  </div>
</div>
</div>

<div class = "loanscreen" id = "loanscreen">
      <div class = "loanformcontainer">
          <div class = "formtitle">Add a new loan</div>
          <div class = "formsubtitle">Add gold items for the loan.</div>
      </div>
      <form [formGroup]="newloanForm">
          <div class = "loanpage1" id = "loanpage1">
      <div class="inputsection">
          <mat-form-field>
          <mat-label>Loan ID</mat-label>
          <input matInput
          formControlName="loanid" 
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.loanid.errors }"
          >
        </mat-form-field>
      </div>

      <div class="inputsection">
      <mat-form-field>
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker"
          formControlName="date"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.date.errors }" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      

      <div class="inputsection">
          <input type="hidden"
          formControlName="selectedemail"
          class="form-control"
          placeholder="Selected Email"/>
          <div *ngIf="submitted && f.selectedemail.errors" class="invalid-feedback">
              <div *ngIf="f.selectedemail.errors.required">There was an error in selecting the user</div>
              </div>
      </div>
                  <div class="text-center">
                      <button class = "xbutton" mat-button (click)="addItems()">Proceed to add gold items</button>
                  </div>
          </div>
      <div class = "loanpage2" id = "loanpage2">
        <button class = "xbutton" mat-button (click)="addCreds()" id = "topbutton">Click to add gold item</button>
        <div formArrayName="golditems" *ngFor="let creds of newloanForm.controls.golditems?.value; let i = index; trackBy:trackByFn">
          <div class = "golditemcontainer">
              <div class = "itemheading">Item {{ i+1 }}</div>
          <ng-container [formGroupName]="i">
            <div class="inputsection">
              <mat-form-field>
              <mat-label>Type</mat-label>
              <input matInput
              formControlName="type" 
              class="form-control"
              >
            </mat-form-field>
          </div>
          <div class="inputsection">
              <mat-form-field>
              <mat-label>Weight</mat-label>
              <input matInput
              formControlName="weight" 
              class="form-control"
              >
            </mat-form-field>
          </div>
          <div class="inputsection">
              <mat-form-field>
              <mat-label>Additional Info</mat-label>
              <input matInput
              formControlName="additional" 
              class="form-control"
              >
            </mat-form-field>
          </div>

      <div class="inputsection">
          <mat-form-field>
          <mat-label>Carats (If item has diamond)</mat-label>
          <input matInput
          formControlName="carats" 
          class="form-control"
          >
        </mat-form-field>
      </div>

      <div class="inputsection" id = "uploader">
          <label for="file">Upload Image of Item</label>
          <input type="file" (change)="uploadFile($event, i)" id = "file" accept=".png, .jpg, .jpeg, .gif"/>
          <div *ngIf="uploadPercent | async" class = "loader">{{ uploadPercent | async }}% uploaded.</div>
        </div>

          </ng-container>
        </div></div>
        <div class="financebutton" id ="financebutton">
          <button class = "xbutton" mat-button (click)="addFinance()">Proceed to add finance details</button>
      </div>
      </div>


      <div class = "loanpage3" id = "loanpage3">
          <div class="inputsection">
              <mat-form-field>
              <mat-label>Loan Amount</mat-label>
              <input matInput
              #principle (keyup)="0"
              formControlName="principle" 
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.principle.errors }"
              >
            </mat-form-field>
          </div>
  
          <div class="inputsection">
              <mat-form-field>
              <mat-label>Rate of Interest</mat-label>
              <input matInput
              #rate (keyup)="0"
              formControlName="rate" 
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.rate.errors }"
              >
            </mat-form-field>
          </div>
  
          <div class="inputsection">
              <mat-form-field>
              <mat-label>Time Period (months)</mat-label>
              <input matInput
              #time (keyup)="0"
              formControlName="time" 
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.time.errors }"
              >
            </mat-form-field>
          </div>
          <div class = "interest">Monthly EMI : {{ (principle.value * rate.value * (time.value/12))/100 }}</div>
                      <div class="text-center">
                          <button class = "xbutton" mat-button (click)="addLoan()">Proceed to add loan</button>
                      </div>
      </div>
</form>
      </div>
</div>
<ng-template #show>
  <div class = "errorscreen">
  <div class = "errortitle">Unauthorised Access.</div>
  </div>
</ng-template>