import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-selectuser',
  templateUrl: './selectuser.component.html',
  styleUrls: ['./selectuser.component.css']
})
export class SelectuserComponent implements OnInit {

  users$: Observable<any[]>;
  filteredUsers$: Observable<any[]>;
  newloanForm: FormGroup;
  selectedemail: string;
    submitted = false;
    uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private firestore: AngularFirestore,
    private formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    public auth: AngularFireAuth) {
    this.users$ = firestore.collection('users').valueChanges();
    }

  ngOnInit() {
    this.filteredUsers$ = this.users$;
    this.newloanForm = this.formBuilder.group({
      loanid: ['', Validators.required],
      date: ['', Validators.required],
      selectedemail: ['', Validators.required],
      golditems: this.formBuilder.array([]),
      principle: ['', Validators.required],
      rate: ['', Validators.required],
      time: ['', Validators.required]
  });
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value:string) {
    if(value) {

      
      this.filteredUsers$ = this.users$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((users: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return users.filter((user: any) => user.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
        })
       
      )
  } else {
    // reload the full data set
    this.filteredUsers$ = this.users$;
  }
}

selectUser(email) {
  alert(email);
}

    get f() { return this.newloanForm.controls; }

    changeView() {
        this.submitted = true;
        if (this.newloanForm.invalid) {
            return;
        }     
    }

proceedLoan(loanemail) {
  this.selectedemail = loanemail;
  this.newloanForm.controls['selectedemail'].setValue(loanemail);
  document.getElementById('selectscreen').classList.add('shower');
  document.getElementById('loanscreen').classList.add('shower'); 
}

addLoan() {
  this.submitted = true;
        if (this.newloanForm.invalid) {
            return;
        }     
            var currentDate = new Date();
            var cmonth = currentDate.getMonth();
            var dateadded = currentDate.getDate();
            var yearadded = currentDate.getFullYear();
            var monthadded = cmonth + 1;
            var info = this.newloanForm.value;
            var carray = {dateadded, monthadded, yearadded, info};
           this.firestore.collection(`loans`).add(carray);
}

addItems() {
  if (this.f.date.errors || this.f.loanid.errors) {
    return;
}     
let subscription = this.firestore.collection(`loans`, ref => ref.where('loanid', "==", this.newloanForm.value.loanid)).snapshotChanges().subscribe(res => {
  if (res.length > 0)
  {
  alert("Loan ID "+ this.newloanForm.value.loanid + " already exists. Please enter a unique Loan ID.");
  }
  else
  {
    document.getElementById('loanpage1').classList.add('shower');
    document.getElementById('loanpage2').classList.add('shower'); 
  }
  subscription.unsubscribe();
});    
}

addFinance() {
  document.getElementById('loanpage2').classList.remove('shower'); 
  document.getElementById('loanpage3').classList.add('shower'); 
}

    onReset() {
        this.submitted = false;
        this.newloanForm.reset();
    }  
    fakeArray(length: number): Array<any> {
      if (length >= 0) {
        return new Array(length);
      }
    }

  addCreds() {
    document.getElementById('financebutton').classList.add('curtain');
    const creds = this.newloanForm.controls.golditems as FormArray;
    creds.push(this.formBuilder.group({
      type: '',
      weight: '',
      additional: '',
      diamond: '',
      carats: '',
    }));
  }

  trackByFn(index: any, item: any) {
    return index;
 }

  uploadFile(event, itemindex) {
    const file = event.target.files[0];
    const filePath = 'loanphotos/' + this.newloanForm.value.loanid + 'photoitem' + itemindex;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
        finalize(() => this.downloadURL = fileRef.getDownloadURL() )
     )
    .subscribe()
  }
  
}
