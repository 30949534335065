import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../helpers/must-match.validator';


@Component({
  selector: 'app-addadmin',
  templateUrl: './addadmin.component.html',
  styleUrls: ['./addadmin.component.css']
})

export class AddadminComponent {
  adminForm: FormGroup;
  submitted = false;
  constructor(public auth: AngularFireAuth, private formBuilder: FormBuilder) {
  }
  
  logout() {
    this.auth.signOut();
  }
  ngOnInit() {
      this.adminForm = this.formBuilder.group({
          user: ['', Validators.required],
          password: ['', [Validators.required, Validators.minLength(6)]]
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.adminForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.adminForm.invalid) {
          return;
      }

      // display form values on success
      console.log(this.adminForm.value.user)
      this.addAdmin(this.adminForm.value.user, this.adminForm.value.password);

  }

  onReset() {
      this.submitted = false;
      this.adminForm.reset();
  }
  addAdmin(xuser, xpassword) {
  this.auth.createUserWithEmailAndPassword(xuser, xpassword)
  .then((user) => {
    this.notificationPop('Admin created successfully!');
  })
  .catch((error) => {
    this.notificationPop('Error creating Admin Account');
  });
}

notificationHide() {
  document.getElementById('notification').classList.remove('pop');
  }
  
notificationDisplay() {
  document.getElementById('notification').classList.add('pop');
  }
  
notificationPop(message: string) {
    document.getElementById('notificationtext').innerHTML = message;
    this.notificationDisplay();
    setTimeout(this.notificationHide, 2500);
  }
}


