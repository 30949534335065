import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { AdminflagService } from '../app/services/adminflag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'prakahs';
  authnot:string;
  env=environment;
  constructor(
    public adminflagService: AdminflagService
  ) {
  }
}