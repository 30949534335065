<ng-container *ngIf="auth.user | async as user; else show">

  <div class="flexscreen">
    <div class="pickerarea">
      <div class="formtitle">Pending Personal Loan Payments</div>
      <div class="buttons">
        <button class="filterbutton excel" (click)="exportexcel()">Export to Excel</button>
      </div>
    </div>
    <input class="search-box" placeholder="Search using name" (keyup)=search($event.target.value) type="text">
    <div class="xarea">
      <table id="excel-table">
        <tr>
          <th>#</th>
          <th>Pending</th>
          <th>Day</th>
          <th>Month</th>
          <th>Year</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Loan</th>
          <th>Interest Recieved</th>
          <th>Loan Returned</th>
          <th>Fees</th>
          <th>Calculated Interest</th>
          <th>Actual Interest (till today)</th>
          <th>Remaining Loan</th>
        </tr>
        <ng-container class="users notpending" *ngFor="let loan of filteredLoanarray">
          <tr class="tdeets" *ngIf="loan?.id" [routerLink]="['/loandetails', loan.id]">
            <td>{{loan?.id}}</td>
            <td class="pending">
              <div *ngFor="let pdates of loan?.epending | keyvalue; let px = index">
                Payment {{ px+1 }} - {{ pdates.value | date }}
              </div>
            </td>
            <td>{{loan?.date}}</td>
            <td>{{loan?.month}}</td>
            <td>{{loan?.year}}</td>
            <td>{{loan?.details.name}}</td>
            <td>{{loan?.details.phone}}</td>
            <td>${{loan?.details.principle}} at {{loan?.details.rate}}% for {{loan?.details.time}} ({{
              loan?.details.type }})</td>
            <td>${{loan?.erevenue}}</td>
            <td>${{loan?.remainingloan}}</td>
            <td>${{loan?.details.transactionfee}}</td>
            <td>
              Duration : {{ loan?.details.time }}
              <ng-container *ngIf="loan.details.type == 'Monthly'">months</ng-container>
              <ng-container *ngIf="loan.details.type == 'Weekly'">weeks</ng-container>
              <ng-container *ngIf="loan.details.type == 'Fortnightly'">fortnights</ng-container>
              <br>
              Interest : ${{ loan.details.totalinterest }}<br>
              Remaining : ${{ loan.details.totalinterest - loan.erevenue }}
            </td>
            <td>
              Duration : {{loan?.ctime }}
              <ng-container *ngIf="loan.details.type == 'Monthly'">months</ng-container>
              <ng-container *ngIf="loan.details.type == 'Weekly'">weeks</ng-container>
              <ng-container *ngIf="loan.details.type == 'Fortnightly'">fortnights</ng-container>
              <br>
              Interest : ${{ loan?.cinterest }}<br>
              Remaining : ${{ loan.cinterest - loan.erevenue }}
              <br><br>
              <span class="red" *ngIf="loan?.ctime > loan?.ftime">Overdue by {{ loan?.ctime - loan?.ftime }}
                <ng-container *ngIf="loan.details.type == 'Monthly'">month(s)</ng-container>
                <ng-container *ngIf="loan.details.type == 'Weekly'">week(s)</ng-container>
                <ng-container *ngIf="loan.details.type == 'Fortnightly'">fortnight(s)</ng-container>
              </span>
            </td>
            <td>${{loan?.remainingloan}}</td>
          </tr>
        </ng-container>
      </table>
    </div>

    <ng-template #loading>
      <div class="container" id="loading">
        <div class="loader">
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--text"></div>
        </div>
      </div>
    </ng-template>

  </div>
</ng-container>
<ng-template #show>
  <div class="errorscreen">
    <div class="errortitle">Unauthorised Access.</div>
  </div>
</ng-template>