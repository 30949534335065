<div *ngIf="auth.user | async as user; else show">
    <div class = "flexscreen">
      <div class = "heading">
        <div class = "formtitle">View messages</div>
        <div class = "formsubtitle">Search database for messages</div>
      </div>
    
      <input class="search-box" placeholder="Search for a message" (keyup)=search($event.target.value) type="text">
    
        <div class = "users">
            <div *ngFor="let user of filteredUsers$ | async">
            <div class = "user" id = "{{ user.id }}">
            <div class = "userlabel usertitle">{{user.details.name}}</div>
            <div class = "userlabel">
            <div class = "usvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14 18.597v.403h-10v-.417c-.004-1.112.044-1.747 1.324-2.043 1.403-.324 2.787-.613 2.122-1.841-1.973-3.637-.563-5.699 1.554-5.699 2.077 0 3.521 1.985 1.556 5.699-.647 1.22.688 1.51 2.121 1.841 1.284.297 1.328.936 1.323 2.057zm6-9.597h-4v2h4v-2zm0 4h-4v2h4v-2zm0 4h-4v2h4v-2zm-4-12v2h6v14h-20v-14h6v-2h-8v18h24v-18h-8zm-6-4v6h4v-6h-4zm2 4c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z"/></svg></div>
            <div class = "utxt">{{user.id}}</div>
            </div>
            <div class = "userlabel">
              <div class = "usvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"/></svg></div>
                <div class = "utxt">{{user.details.email}}</div>
            </div>
            <div class = "userlabel">
              <div class = "usvg"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M.026 24l11.974-11.607 11.974 11.607h-23.948zm11.964-23.961l-11.99 8.725v12.476l7.352-7.127-5.653-4.113 10.291-7.488 10.309 7.488-5.655 4.108 7.356 7.132v-12.476l-12.01-8.725z"/></svg></div>
              <div class = "utxt">{{user.details.message}}</div>
            </div>
          </div>
      </div>
    </div>
    </div>
    </div>
    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>