<ng-container *ngIf="auth.user | async as user; else show">

  <div class="flexscreen">
    <div class="pickerarea">
      <div class="formtitle">View Personal loan report</div>
      <div class="parea">
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date" (keyup)="0">
            <input matEndDate formControlName="end" placeholder="End date" (keyup)="0">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      <div class="buttons">
        <button class="filterbutton" (click)="filterByDate()">Filter by Date</button>
        <button class="filterbutton excel" (click)="exportexcel()">Export to Excel</button>
      </div>
    </div>
    <div class="xarea">
      <table id="excel-table">
        <tr>
          <th>Ticket</th>
          <th>Day</th>
          <th>Month</th>
          <th>Year</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Amount</th>
          <th>Rate</th>
          <th>Duration</th>
          <th>Revenue</th>
          <th>Fees</th>
          <th>Status</th>
          <th>Closed Amounts</th>
          <th>Comments</th>
        </tr>
        <ng-container class="users notpending" *ngFor="let loan of loanarray">
          <tr *ngIf="loan?.id" [routerLink]="['/loandetails', loan.id]">
            <td>{{loan?.id}}</td>
            <td>{{loan?.date}}</td>
            <td>{{loan?.month}}</td>
            <td>{{loan?.year}}</td>
            <td>{{loan?.details.name}}</td>
            <td>{{loan?.details.phone}}</td>
            <td>${{loan?.details.principle}}</td>
            <td>{{loan?.details.rate}}</td>
            <td>{{loan?.details.time}} ({{ loan?.details.type }})</td>
            <td>${{loan?.erevenue}}</td>
            <td>${{loan?.details.transactionfee}}</td>
            <td *ngIf="loan?.status == 1" class="green">Paid</td>
            <td *ngIf="loan?.status == 0">Pending</td>
            <td *ngIf="loan?.status == 4">Closed</td>
            <td *ngIf="loan?.status == 1"></td>
            <td *ngIf="loan?.status == 0"></td>
            <td *ngIf="loan?.status == 4">Loan paid - ${{loan?.loanclosefinalpaid}}<br>
              Interest paid - ${{loan?.loancloseinterestpaid}}
            </td>
            <td>{{loan?.comment}}</td>
          </tr>
        </ng-container>
      </table>
    </div>

    <ng-template #loading>
      <div class="container" id="loading">
        <div class="loader">
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--text"></div>
        </div>
      </div>
    </ng-template>

  </div>
</ng-container>
<ng-template #show>
  <div class="errorscreen">
    <div class="errortitle">Unauthorised Access.</div>
  </div>
</ng-template>