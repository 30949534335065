import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DateTime } from "luxon";
import * as XLSX from 'xlsx';
import { AngularFireAuth } from '@angular/fire/auth';
import { ExcelService } from '../../services/excel.service';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-viewgoldloans',
  templateUrl: './viewgoldloans.component.html',
  styleUrls: ['./viewgoldloans.component.css']
})
export class ViewgoldloansComponent implements OnInit {

  loans: Array<any>;
  loans$: Observable<any[]>;
  filteredLoans$: Observable<any[]>;
  math = Math;
  parsefloat = parseFloat;

  now: any;
  loanarray: any;
  filteredLoanarray: Observable<any[]>;

  constructor(private firestore: AngularFirestore,
    public auth: AngularFireAuth) {
    this.loans$ = firestore.collection('goldloans', ref => ref.orderBy('id','desc')).valueChanges({ idField: 'goldloanid' });
    }

  ngOnInit() {
    this.now = DateTime.now().toISO();
    this.loanarray = [];
    const queryObservable = this.firestore.collection('goldloans', ref => ref.orderBy('id', 'desc')).valueChanges();
    queryObservable.subscribe(queriedItems => {
      for (let [index, loan] of queriedItems.entries()) {
        const [cchecker, crevenue, cremaininginterest, cremainingloan, pendingc, timec, intc, ccc, cintc] = this.filterLoans(loan);
        if (cchecker == true) {
          this.loanarray[index] = loan;
          this.loanarray[index].erevenue = crevenue;
          this.loanarray[index].remaininginterest = cremaininginterest;
          this.loanarray[index].remainingloan = cremainingloan;
          this.loanarray[index].epending = pendingc;
          this.loanarray[index].ctime = timec;
          this.loanarray[index].cinterest = intc;
          this.loanarray[index].ftime = ccc;
          this.loanarray[index].finterest = cintc;
        }
      }
    });
    this.filteredLoanarray = this.loanarray;
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(svalue: any) {
    this.filteredLoanarray = this.loanarray.filter(loan => {
      return loan.id.toString().includes(svalue);
    })
  }
  

selectUser(email) {
  alert(email);
}


filterLoans(loan) {

  var revenue: number = 0;
  var count: number = 0;
  var pendingd = [];
  var checker = true;
  var looper;

  var loanreturned: number = 0;
  var interestreturned: number = 0;


  function getDateDiff(date1, date2) {

    var diff = Math.abs(date1.getTime() - date2.getTime());
    var days = Math.ceil(diff / (1000 * 3600 * 24));
    var weeks = Math.ceil(days / 7);
    var fortnights = Math.ceil(weeks / 2);
    var months = Math.ceil(days / 30);
    return {
      days: days,
      weeks: weeks,
      fortnights: fortnights,
      months: months
    };
  }

  var date1 = loan.details.date.toDate();
  var date2 = new Date();

  var diff = getDateDiff(date1, date2);
  var monthdiff = diff.months;


  if (loan.details.type == 'Monthly') {
    var tcount = diff.months;
  }
  else if (loan.details.type == 'Weekly') {
    var tcount = diff.weeks;
  }
  else if (loan.details.type == 'Fortnightly') {
    var tcount = diff.fortnights;
  }

  var intt = (loan.details.principle * loan.details.rate * tcount)/100;
  var int = Math.round((intt + Number.EPSILON) * 100) / 100;
  for (looper = 0; looper < loan.details.time; looper++) {
    var datetobechecked = loan.pendingpayments[looper].pendingdate;

    var status = loan.pendingpayments[looper].status;

    if (loan.pendingpayments[looper].interestreceived) {
      revenue = revenue + parseInt(loan.pendingpayments[looper].interestreceived);
    }
    if (loan.pendingpayments[looper].loanreturned) {
      loanreturned = loanreturned + parseInt(loan.pendingpayments[looper].loanreturned);
    }
    if (loan.status == 0 && this.now >= datetobechecked && status !== 'Paid') {
      pendingd[looper] = datetobechecked;
      checker = true;
    }
    if (this.now >= datetobechecked) {
      count = count + 1;
    }
    var remaininginterest = loan.details.totalinterest - revenue;
    var remainingloan = loan.details.principle - loanreturned;
  }

  var cintt = (loan.details.principle * loan.details.rate * count)/100;
  var cint = Math.round((cintt + Number.EPSILON) * 100) / 100;

  return [checker, revenue, remaininginterest, remainingloan, pendingd, tcount, int, count, cint];
}

}














