<div class = "flexscreen">
<div *ngIf="currentAdmin" class="edit-form">
    <h4>Admin</h4>
    <form>
      <div class="form-group">
        <label for="name">Name</label>
        <input
          type="text"
          class="form-control"
          id="name"
          [(ngModel)]="currentAdmin.details.name"
          name="name"
        />
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input
          type="text"
          class="form-control"
          id="email"
          [(ngModel)]="currentAdmin.details.email"
          name="email"
        />
      </div>
      <div class="form-group">
        <label for="phone">Phone</label>
        <input
          type="text"
          class="form-control"
          id="phone"
          [(ngModel)]="currentAdmin.details.phone"
          name="phone"
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="text"
          class="form-control"
          id="password"
          [(ngModel)]="currentAdmin.details.password"
          name="password"
        />
      </div>
  
    </form>
  
    <button class="badge badge-danger mr-2" (click)="deleteAdmin()">
      Delete
    </button>
  
    <button type="submit" class="badge badge-success" (click)="updateAdmin()">
      Update
    </button>
    <p>{{ message }}</p>
  </div>
  
  <div *ngIf="!currentAdmin">
    <br />
    <p>Cannot access this Admin...</p>
  </div>
  </div>