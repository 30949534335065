<div *ngIf="auth.user | async as user; else show">
<div class = "flexscreen">

    <div class = "heading">
        <div class = "formtitle">Upload IDs</div>
        <div class = "formsubtitle">Upload ID for customer #{{ pageid }}.</div>
    </div>
    
    <div class = "restarea">
    
    <div class = "uploader" id = "uploader">
        <div class = "uploadertitle">Click on button or drag drop image below.</div>
        <input type="file" (change)="uploadFile($event)" accept=".png, .jpg, .jpeg, .gif"/>
    </div>
    
    <div class = "uploaded" id = "uploaded">
        <div class = "uploadertitle">ID has been successfully uploaded for customer #{{ pageid }}.</div>
        <div class = "roption" [routerLink]="['/users']">View Customers</div>
        <div class = "roption" [routerLink]="['/addusers']">Add New Customers</div>
    </div>
    
    <div class="container" id ="loading">
        <div class="loader">
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--text"></div>
        </div>
    </div>
    
    </div>
    </div>
    </div>
    <div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
    <div id = "notificationtext"></div>
    </div>
    
    <ng-template #show>
        <div class = "errorscreen">
        <div class = "errortitle">Unauthorised Access.</div>
        </div>
      </ng-template>