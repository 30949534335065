import { Component, OnInit } from '@angular/core';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-goldsell',
  templateUrl: './goldsell.component.html',
  styleUrls: ['./goldsell.component.css']
})
export class GoldsellComponent implements OnInit {

  constructor(
    private adminflagService: AdminflagService,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService
  ) { 
    this.adminflagService.setAdminFlag('coco');
    this.meta.addTags([
      { name: 'description', content: 'Do you want to sell gold in Melbourne? With us, it’s easy. We buy your unwanted jewellery, coins, etc. and pay cash instantly. Contact us today.' },
      { name: 'author', content: 'Prakahs Gold Finance' },
      { name: 'keywords', content: 'sell gold melbourne, sell gold for cash, sell scrap gold, sell your gold jewellery, selling your gold, jewellery buyers' }
    ]);
    this.setTitle('Sell Gold Melbourne | Prakahs Gold Finance');
    this.createLinkForCanonicalURL();
  }

  ngOnInit(): void {
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 