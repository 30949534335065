import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";

@Component({
  selector: 'app-pgoldloan',
  templateUrl: './pgoldloan.component.html',
  styleUrls: ['./pgoldloan.component.css']
})
export class PgoldloanComponent implements OnInit {

  loans: Array<any>;
  loans$: Observable<any[]>;
  payments$: Observable<any[]>;
  filteredLoans$: Observable<any[]>;
  math = Math;
  parsefloat = parseFloat;
  now:any;
  parsedate = this.parseDate;
  loanData: any;

  constructor(private firestore: AngularFirestore,
    public auth: AngularFireAuth) {
    this.loans$ = firestore.collection('goldloans', ref => ref.orderBy('id','desc')).valueChanges({ idField: 'goldloanid' });
    this.loans$.subscribe(this.loanData);
  }

  ngOnInit() {
    this.filteredLoans$ = this.loans$;
    this.now = DateTime.now().toString();
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value:string) {
    if(value) {

      
      this.filteredLoans$ = this.loans$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((loans: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return loans.filter((loan: any) => loan.goldloanid.indexOf(value) > -1)
        })
       
      )
  } else {
    // reload the full data set
    this.filteredLoans$ = this.loans$;
  }
}

selectUser(email) {
  alert(email);
}
showEditor(pawnticket, paymentno) {
  var loanid = pawnticket+"payment"+paymentno;
  document.getElementById(loanid).classList.toggle('showform');
}
updateLoan(loanid, pid, ploan, interestreceived, loanreturned, date, revenue) {

  var id = loanid.toString();
  var formISODate = date.toISOString();
  var paydate = DateTime.fromISO(formISODate).toString();
  var xstatus = "pendingpayments."+pid+".status";
  var xdate = "pendingpayments."+pid+".date";
  var xinterestreceived = "pendingpayments."+pid+".interestreceived";
  var xloanreturned = "pendingpayments."+pid+".loanreturned";
  var xloanoutstanding = "pendingpayments."+pid+".loanoutstanding";
  var pendingloan = ploan - loanreturned;
  var totalrevenue = parseInt(revenue) + parseInt(interestreceived);

return this.firestore.collection('goldloans').doc(id).update({
    [xinterestreceived]: interestreceived,
    [xloanreturned]: loanreturned,
    [xloanoutstanding]: pendingloan,
    "pendingloan" : pendingloan,
    "totalrevenue" : totalrevenue,
    [xstatus] : "Paid",
    [xdate]: paydate
}).then(() => {
this.notificationPop("Payment added successfully!");
}).catch((error) => {
this.notificationPop("Payment adding failed.");
});
}

notificationHide() {
  document.getElementById('notification').classList.remove('pop');
  }
  
  notificationDisplay() {
  document.getElementById('notification').classList.add('pop');
  }
  
  notificationPop(message: string) {
    document.getElementById('notificationtext').innerHTML = message;
    this.notificationDisplay();
    setTimeout(this.notificationHide, 2500);
  }
  parseDate(gdate) {
    return DateTime.fromISO(gdate);  
  }

  closeLoan(loanid, count, finalinterestreceived, finalloanreturned, revenue) {
    var id = loanid.toString();
    var now = DateTime.now().toString();
    var paydate = now;
    var totalrevenue = parseInt(finalinterestreceived) + parseInt(revenue);
    for(var it=0; it<count; it++) {
      var xstatus = "pendingpayments."+it+".status";
      this.firestore.collection('goldloans').doc(id).update({
        [xstatus]: "Paid"
    });
  }
    return this.firestore.collection('goldloans').doc(id).update({
      "fullpaymentdate": paydate,
      "fullpaymentloanpaid": parseInt(finalloanreturned),
      "fullpaymentinterestpaid": parseInt(finalinterestreceived),
      "pendingloan" : 0,
      "status": 1,
      "totalrevenue" : totalrevenue,
  }).then(() => {
    document.getElementById('closeloanform').classList.remove('visible');
  this.notificationPop("Payment added successfully!");
  }).catch((error) => {
  this.notificationPop("Payment adding failed.");
  });
}

showCloseLoanForm(loanid) {
  var closer = 'closeloanform'+loanid;
  document.getElementById(closer).classList.add('visible');
}
returnZero() {
  return 0
}

}
