import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AdminflagService } from '../../../app/services/adminflag.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintComponent implements OnInit {
  id:string;

  loansCollection: AngularFirestoreCollection<any>;
  loans: Observable<any[]>;
  gloan: any;

  usersCollection: AngularFirestoreCollection<any>;
  users: Observable<any[]>;
  guser: any;

  signCollection: AngularFirestoreCollection<any>;
  signs: Observable<any[]>;
  gsign: any;

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private adminflagService: AdminflagService
    ) {
      this.loansCollection = this.afs.collection('goldloans');
      this.loans = this.loansCollection.valueChanges();
      this.usersCollection = this.afs.collection('users');
      this.users = this.loansCollection.valueChanges();
      this.signCollection = this.afs.collection('signatures');
      this.signs = this.signCollection.valueChanges();
    }
  ngOnInit() {
    this.adminflagService.setAdminFlag('boco');
    this.route.paramMap.subscribe(params => { 
      this.id = params.get('id'); 
      this.loansCollection.doc(this.id).ref.get().then((doc) => {
        this.gloan = doc.data();

        this.usersCollection.doc(this.gloan.details.loanuid).ref.get().then((udoc) => {
            if (udoc.exists) {
              this.guser = udoc.data();
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });


        this.signCollection.doc('owner').ref.get().then((sdoc) => {
          if (sdoc.exists) {
            this.gsign = sdoc.data();
          } else {
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });


        });
  });
  
}
ngOnDestroy() {
  this.adminflagService.setAdminFlag('');
}
}