import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../helpers/must-match.validator';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.css']
})
export class ControlComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  loggedin: boolean;
  puser: any;
  uname: string;
  loan$: Observable<any[]>;
  goldloan$: Observable<any[]>;

  constructor(private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private adminflagService: AdminflagService
    ) { 
        if (sessionStorage.getItem("prakahsuser") === null) {
            this.loggedin = false;
            }
            else {
            this.loggedin = true;
            this.uname = sessionStorage.getItem("prakahsname");
            this.loan$ = afs.collection('loans', ref => ref.where('details.loanuid', "==", sessionStorage.getItem("prakahsuid"))).valueChanges({ idField: 'id' });
            this.goldloan$ = afs.collection('goldloans', ref => ref.where('details.loanuid', "==", sessionStorage.getItem("prakahsuid"))).valueChanges({ idField: 'id' });
            }
            this.adminflagService.setAdminFlag('coco');
     }

  ngOnInit() {
      this.registerForm = this.formBuilder.group({
          user: ['', Validators.required],
          password: ['', [Validators.required, Validators.minLength(6)]]
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }
      this.saveCredentials(this.registerForm.value.user, this.registerForm.value.password);

  }

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

  logout() {
    sessionStorage.removeItem('prakahsuser');
    window.location.reload();
}

  saveCredentials(xuser, xpassword) {
    this.afs.collection('users').doc(xuser).ref.get().then((udoc) => {
        if (udoc.exists) {
           this.puser = udoc.data();
           this.uname = this.puser.details.name;
           var checkpass = this.puser.details.password;
           var sessemail = this.puser.details.email;
           if(checkpass == xpassword) {
            sessionStorage.setItem('prakahsuser', sessemail);
            sessionStorage.setItem('prakahsname', this.uname);
            sessionStorage.setItem('prakahsuid', xuser);
            window.location.reload();
           }
           else {
            alert('Invalid credentials');
           }
        }
}).catch((error) => {
    console.log("Error getting document:", error);
});
  }
}
