<ng-container *ngIf="auth.user | async as user; else show">

    <div class="flexscreen">
      <div class="pickerarea">
        <div class="formtitle">View revenue from Gold loans</div>
        <div class="parea">
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date" (keyup)="0">
              <input matEndDate formControlName="end" placeholder="End date" (keyup)="0">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
  
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </div>
        <div class="buttons">
          <button class="filterbutton" (click)="filterByDate()">Filter by Date</button>
          <button class="filterbutton excel" (click)="exportexcel()">Export to Excel</button>
        </div>
      </div>
      <div class="xarea">
        <table id="excel-table"> 
            <tr>
                <th>Id</th>
                <th>Description</th>
                <th>Amount Paid</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Sold On</th>
                <th>Sold For</th>
                <th>Revenue</th>
            </tr>
    
            <ng-container class="users" *ngFor="let item of loanarray">
                <tr *ngIf="item?.id">
                    <td>{{item.id}}</td>
                    <td>{{item.details.description}}</td>
                    <td>{{item.details.amountpaid}}</td>
                    <td>{{item.details.name}}</td>
                    <td>{{item.details.phone}}</td>
                    <td>{{item.details.selldate}}</td>
                    <td>{{item.details.soldamount}}</td>
                    <td>{{item.details.soldamount - item.details.amountpaid}}</td>
                </tr>
                </ng-container>
    </table>
      </div>
  
      <ng-template #loading>
        <div class="container" id="loading">
          <div class="loader">
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
            <div class="loader--dot"></div>
            <div class="loader--text"></div>
          </div>
        </div>
      </ng-template>
  
    </div>
  </ng-container>
  <ng-template #show>
    <div class="errorscreen">
      <div class="errortitle">Unauthorised Access.</div>
    </div>
  </ng-template>








