<div class = "flexscreen">
    <div class = "heading">
        <div class = "formtitle">Redemption Signature (Personal Loans)</div>
        <div class = "formsubtitle">Upload redemption signature for pawn ticket #{{ pageid }}.</div>
    </div>
    
    <div class = "restarea">
    <div class = "uploader" id = "uploader">

        <form [formGroup]="redeemDetails">
            <mat-form-field>
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker"
              formControlName="date"
              class="form-control"
              required/>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
         
              <mat-form-field>
                <mat-label>
                    Paid on Redemption
                </mat-label>
                <input matInput
                class="form-control"
                formControlName="paid"
                type="number"
                required
                >
            </mat-form-field>
        </form>

        <div class = "xrtitle">Provide your signature below</div>
        <div class="signature-pad">
          <div class="signature-pad--body">
            <canvas #sPad id = "sPad" style="touch-action: none;"></canvas>
          </div>
        </div>
                <div class = "signbuttons">
                <button class="btn1" (click)="clear()">Clear</button>
                <button class="btn2" (click)="undo()">Undo</button>
                <button class="btn3" (click)="savePNG()">Save</button>
                </div>
                <div id = "imagearea"></div>
        
    </div>
    
    <div class = "uploaded" id = "uploaded">
      <div class = "uploadertitle">Signed documents have already been uploaded for loan with pawn ticket #{{ pageid }}.</div>
      <div class = "roption" [routerLink]="['/loans']">View Personal loans</div>
      <div class = "roption" [routerLink]="['/newloan']">Add Personal loans</div>
    </div>
    
    <div class="container" id ="loading">
        <div class="loader">
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--text"></div>
        </div>
    </div>
    
    </div>
    
    <div *ngIf="!auth.user">
      <div class = "error">
        <div class = "errortitle">Unauthorised Access.</div>
        </div>
    </div>
    
    </div>
    
    
        
    
    <div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
    <div id = "notificationtext"></div>
    </div>
    
    