import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {
  users$: Observable<any[]>;
  filteredUsers$: Observable<any[]>;
  selectedemail: string;
    submitted = false;
    uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  math = Math;

  constructor(
    private firestore: AngularFirestore,
    public auth: AngularFireAuth) {
    this.users$ = firestore.collection('users').valueChanges({idField: 'id'});
    }

  ngOnInit() {
    this.filteredUsers$ = this.users$;
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value:string) {
    if(value) {

      
      this.filteredUsers$ = this.users$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((users: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return users.filter((user: any) => user.details.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
        })
       
      )
  } else {
    // reload the full data set
    this.filteredUsers$ = this.users$;
  }
}

showEditor(event) {
  document.getElementById(event.target.parentNode.parentNode.id).classList.toggle('showform');
}
updateUser(id, name, photoidno, email, phone, address) {
  return this.firestore.collection('users').doc(id).update({
    "details.name": name,
    "details.photoidno": photoidno,
    "details.email": email,
    "details.phone": phone,
    "details.address": address
  }).then(() => {
    this.notificationPop("Edited successfully.");
  }).catch((error) => {
    this.notificationPop("Edit failed.");
  });
}

deleteUser(id, name) {
  if(confirm("Are you sure to delete "+name)) {
return this.firestore.collection('users').doc(id).delete().then(() => {
this.notificationPop("Deleted successfully.");
  }).catch((error) => {
this.notificationPop("Delete failed.");
  });
}
}

notificationHide() {
  document.getElementById('notification').classList.remove('pop');
  }
  
  notificationDisplay() {
  document.getElementById('notification').classList.add('pop');
  }
  
  notificationPop(message: string) {
    document.getElementById('notificationtext').innerHTML = message;
    this.notificationDisplay();
    setTimeout(this.notificationHide, 2500);
  }


}
