import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import SignaturePad from 'signature_pad';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-goldsign',
  templateUrl: './goldsign.component.html',
  styleUrls: ['./goldsign.component.css']
})
export class GoldsignComponent implements OnInit {

    @ViewChild('sPad', { static: true })
    signaturePadElement!: { nativeElement: HTMLCanvasElement; };
    signaturePad: any;
    uploadid:number=1;
  
    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
      this.resizeCanvas();
    }
  
    
    public innerWidth: any;
    public innerHeight: any;

    completed: false;
    submitted = false;
    signatureDetails: FormGroup;



  id:string;
  pageid:string;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  signurl: String;

  constructor(
    private route: ActivatedRoute,
    public auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private _formBuilder: FormBuilder
    ) {
    }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.route.paramMap.subscribe(params => { 
      this.pageid = params.get('id'); 
        this.resizeCanvas();
  });
  this.signatureDetails = this._formBuilder.group({
    date: ['']
  });
}

notificationHide() {
document.getElementById('notification').classList.remove('pop');
}

notificationDisplay() {
document.getElementById('notification').classList.add('pop');
}

notificationPop(message: string) {
  document.getElementById('notificationtext').innerHTML = message;
  this.notificationDisplay();
  setTimeout(this.notificationHide, 2500);
}
  resizeCanvas() {
    var canvas = document.getElementById('sPad') as HTMLCanvasElement;
    if (canvas !== null) {
    if(this.innerWidth < 400) {
      canvas.width = this.innerWidth - 60;
      canvas.height = this.innerWidth/2;
    }
    else {
      canvas.width = 400;
      canvas.height = 200;
      }
    }
  }
 
  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  changeColor() {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    const color = 'rgb(' + r + ',' + g + ',' + b + ')';
    this.signaturePad.penColor = color;
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  savePNG() {
    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
    } 
    else {
    this.signatureDetails.markAllAsTouched();
    if (this.signatureDetails.invalid) {
      this.notificationPop("Check credentials");
      return;
    }
    else {
    document.getElementById('loading').classList.add('load');
    const pngurl = this.signaturePad.toDataURL();
    const file = pngurl.split(',')[1];
    const n = this.pageid;
    const ref = this.storage.ref(`/signatures/gold/${n}.png`);
    const task = ref.putString(file, 'base64');
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        this.downloadURL.subscribe(downloadURLResponse => {
          this.signurl = downloadURLResponse;
          const gloansRef = this.afs.collection('purchaseditems');
          gloansRef.doc(this.pageid).update({ 
            signed: 'yes',
            signurl: this.signurl,
            signatureDate: this.signatureDetails.value
          });
        });
        document.getElementById('loading').classList.remove('load');
        document.getElementById('uploader').classList.add('hide');
        document.getElementById('uploaded').classList.add('finished');
       }),
   )
  .subscribe()
  }

}
}


  dataURLToBlob(dataURL: any) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  get fdetails() { return this.signatureDetails.controls; }

  onReset() {
    this.submitted = false;
    this.signatureDetails.reset();
  }
}