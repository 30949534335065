
    <div class = "navaddress">
        <a href = "https://prakahsgold.com.au">Home</a> > <span>Gold Rates</span>
      </div>
<div class = "xresponsive">
  <h1>Sell gold at the best rates in Melbourne</h1>
<div class = "ratesarea">
  <h4>WE BUY GOLD AT</h4>
  <div class="xrates bottompad">
      <div class="xratesleft">
          <div>24 ct</div>
          <div>22 ct</div>
          <div>18 ct</div>
          <div>14 ct</div>
          <div>9 ct</div>
      </div>
      <div class="xratesright" *ngFor="let loan of loans$ | async">
          <div>${{ loan.e }}</div>
          <div>${{ loan.d }}</div>
          <div>${{ loan.c }}</div>
          <div>${{ loan.b }}</div>
          <div>${{ loan.a }}</div>
      </div>
      <div class="xratesend">
          <div>per gram</div>
          <div>per gram</div>
          <div>per gram</div>
          <div>per gram</div>
          <div>per gram</div>
      </div>
  </div>
  <h4>WE PAWN GOLD AT</h4>
  <div class="xrates">
      <div class="xratesleft">
          <div>24 ct</div>
          <div>22 ct</div>
          <div>18 ct</div>
          <div>14 ct</div>
          <div>9 ct</div>
      </div>
          <div class="xratesright" *ngFor="let loan of loans$ | async">
              <div>${{ loan.j }}</div>
              <div>${{ loan.i }}</div>
              <div>${{ loan.h }}</div>
              <div>${{ loan.g }}</div>
              <div>${{ loan.f }}</div>
          </div>
          <div class="xratesend">
              <div>per gram</div>
              <div>per gram</div>
              <div>per gram</div>
              <div>per gram</div>
              <div>per gram</div>
          </div>

</div>
<div class = "pricedisclaimer">Prices updated: {{ now | date }}. Prices are subject to change.</div>
  </div>

<div class="buttonareax">
  <a class="buttonx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
  <a class="buttonx"
    href="https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit
    Store</a>
</div>

</div>
<app-footer></app-footer>