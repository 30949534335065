<div class = "hmenu" id = "hmenu" (click) = "expandMenu()">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"/></svg><span>MENU</span></div>
    
    <a href = "https://prakahsgold.com.au"><div class = "hlogo" id = "hlogo">
        <img src = "../assets/afterlogo.png"/>
      </div></a>

<div class = "hdeets">127 Walker Street, Dandenong, VIC 3175<br>
        Phone: (03) 8772 2557<br>
        Email: prakahsgold@gmail.com<br>
        Mon - Sat : 9:30am - 5:30pm<br>
        Sun : Closed
</div>



    <div class = "mmenuoptions" id = "mmenuoptions">
        <a (click) = "expandMenu()" href = "https://prakahsgold.com.au"><div class = "moption"><div class = "mup"><span class = "number">01 </span>Home</div><div class = "mlow">/ Website</div></div></a>
        <div class = "moption" [routerLink] = "['/contact-gold-buyer-melbourne']" (click) = "expandMenu()"><div class = "mup"><span class = "number">02 </span>Contact Us</div><div class = "mlow">/ Get in touch</div></div>
        <div class = "moption" [routerLink] = "['/sell-gold-rates-melbourne']" (click) = "expandMenu()"><div class = "mup"><span class = "number">03 </span>Gold rates</div><div class = "mlow">/ View current gold rates</div></div>
        <div class = "moption" [routerLink] = "['/loan-rates-melbourne']" (click) = "expandMenu()"><div class = "mup"><span class = "number">04 </span>Loan Rates</div><div class = "mlow">/ View current loan rates</div></div>
        <div class = "moption" [routerLink] = "['/cash-for-gold-melbourne']" (click) = "expandMenu()"><div class = "mup"><span class = "number">05 </span>Cash for Gold</div><div class = "mlow">/ What to expect from Prakahs Gold Finance regarding cash for your Gold</div></div>
        <div class = "moption" [routerLink] = "['/gold-loan-melbourne']" (click) = "expandMenu()"><div class = "mup"><span class = "number">06 </span>Cash Loan for Gold</div><div class = "mlow">/ Information regarding loans for your Gold</div></div>
        <div class = "moption" [routerLink] = "['/sell-gold-melbourne']" (click) = "expandMenu()"><div class = "mup"><span class = "number">07 </span>Sell Gold Fast</div><div class = "mlow">/ Sell Gold fast in Melbourne</div></div>
        <div class = "moption" [routerLink] = "['/gold-buyers-melbourne']" (click) = "expandMenu()"><div class = "mup"><span class = "number">08 </span>Gold Selling Tips</div><div class = "mlow">/ Tips to sell gold in Melbourne</div></div>
        <div class = "moption" [routerLink] = "['/login']" (click) = "expandMenu()"><div class = "mup"><span class = "number">09 </span>View your Loans</div><div class = "mlow">/ Log in to view your loans</div></div>
        <div class = "infox">
        127 Walker Street, Dandenong, VIC 3175<br>
        Email: prakahsgold@gmail.com<br>
        Phone: (03) 8772 2557</div>
    </div>