import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

export interface User { 
name: string;
email: string;
phone: string;
password: string;
}

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.css']
})
export class NewuserComponent implements OnInit {
  private usersCollection: AngularFirestoreCollection<User>;
  users: Observable<User[]>;
    newuserForm: FormGroup;
    submitted = false;
    uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  durationInSeconds = 4;

    constructor(
      private formBuilder: FormBuilder,
      private storage: AngularFireStorage,
      private afs: AngularFirestore,
      public auth: AngularFireAuth
      ) { 
        this.usersCollection = afs.collection<User>('users');
       }

    ngOnInit() {
        this.newuserForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            phone: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    get f() { return this.newuserForm.controls; }

    changeView() {
        this.submitted = true;
        if (this.newuserForm.invalid) {
            return;
        }
        let subscription = this.afs.collection(`users`, ref => ref.where('details.email', "==", this.newuserForm.value.email)).snapshotChanges().subscribe(res => {
          if (res.length > 0)
          {
          alert("User with email address "+ this.newuserForm.value.email + " already exists.");
          }
          else
          {
          document.getElementById('page1').classList.add('shower');
          document.getElementById('uploader').classList.add('shower');
          }
          subscription.unsubscribe();
      });      
    }

    createUser() {
        this.afs.collection(`users`).add(this.newuserForm.value);
        this.notificationPop('New user added successfully!');
        document.getElementById('uploader').classList.remove('shower');
        document.getElementById('lastpage').classList.add('shower');
      }

    notificationHide() {
      document.getElementById('notification').classList.remove('pop');
      }
      
    notificationDisplay() {
      document.getElementById('notification').classList.add('pop');
      }
      
    notificationPop(message: string) {
        document.getElementById('notificationtext').innerHTML = message;
        this.notificationDisplay();
        setTimeout(this.notificationHide, 2500);
      }
  

    onReset() {
        this.submitted = false;
        this.newuserForm.reset();
    }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = this.newuserForm.value.email;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
        finalize(() => this.downloadURL = fileRef.getDownloadURL() )
     )
    .subscribe()
  }
  
}
