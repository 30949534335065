import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../helpers/must-match.validator';
import Loan from 'src/app/models/loan.model';
import { LoanService } from 'src/app/services/loan.service';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    private loansCollection: AngularFirestoreCollection<Loan>;
    loans: Observable<Loan[]>;
    item$: Observable<any[]>;
    loan: Loan = new Loan();

    registerForm: FormGroup;
    submitted = false;

    constructor(
      private formBuilder: FormBuilder,
      private loanService: LoanService, 
      public auth: AngularFireAuth, 
      firestore: AngularFirestore) { 
        this.loansCollection = firestore.collection<Loan>('loans');
        this.item$ = firestore.collection('loanusers').valueChanges();
       }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            loanId: ['', Validators.required],
            loanUser: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        // display form values on success
          this.loansCollection.add(this.registerForm.value);
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    }
  

    onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }
      saveLoan(): void {
    this.loanService.create(this.loan).then(() => {
      console.log('Created new loan successfully!');
      this.submitted = true;
    });
  }

  showLoanOptions() {
    document.getElementById('loanhead').classList.toggle('clicked');
    document.getElementById('loansub').classList.toggle('showoptions');
  }
  showGoldLoanOptions() {
    document.getElementById('goldhead').classList.toggle('clicked');
    document.getElementById('goldsub').classList.toggle('showoptions');
  }
  showPersonalLoanOptions() {
    document.getElementById('personalhead').classList.toggle('clicked');
    document.getElementById('personalsub').classList.toggle('showoptions');
  }
  showUserOptions() {
    document.getElementById('userhead').classList.toggle('clicked');
    document.getElementById('usersub').classList.toggle('showoptions');
  }
  showPayOptions() {
    document.getElementById('payhead').classList.toggle('clicked');
    document.getElementById('paysub').classList.toggle('showoptions');
  }
  toggleMenu() {
    document.getElementById('sidepanel').classList.toggle('show');
}
logout() {
  this.auth.signOut();
}
}
