import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-buyrates',
  templateUrl: './buyrates.component.html',
  styleUrls: ['./buyrates.component.css']
})
export class BuyratesComponent implements OnInit {

  selected:string;
  loans: Array<any>;
  loans$: Observable<any[]>;

    constructor(private firestore: AngularFirestore,
      public auth: AngularFireAuth) {
      this.loans$ = firestore.collection('buyrates').valueChanges({ idField: 'id' });
      }

      ngOnInit() {
      }

      showHide(user: any) {
        // for each user we can add a property of show and use this as 
        // a boolean flag to turn each one on/off for a list of any size
        user.show = !user.show;
      }
    


showEditor() {
  document.getElementById('editform').classList.toggle('showform');
}

updateLoan(id, a, b, c, d, e, f, g, h, i, j) {

  return this.firestore.collection('buyrates').doc(id).update({
    "a": a,
    "b": b,
    "c": c,
    "d": d,
    "e": e,
    "f": f,
    "g": g,
    "h": h,
    "i": i,
    "j": j
  }).then(() => {
    document.getElementById('editform').classList.toggle('showform');
    this.notificationPop("Edited successfully.");
  }).catch((error) => {
    this.notificationPop("Edit failed.");
  });
}

notificationHide() {
  document.getElementById('notification').classList.remove('pop');
  }
  
  notificationDisplay() {
  document.getElementById('notification').classList.add('pop');
  }
  
  notificationPop(message: string) {
    document.getElementById('notificationtext').innerHTML = message;
    this.notificationDisplay();
    setTimeout(this.notificationHide, 2500);
  }
}


