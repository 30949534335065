<div *ngIf="auth.user | async as user; else show">
  <div class = "flexscreen">
    <div class = "heading">
      <div class = "formtitle">View Personal loans</div>
      <div class = "formsubtitle">Search database for personal loans</div>
    </div>
  
    <input class="search-box" placeholder="Search using pawn ticket" (keyup)=search($event.target.value) type="text">
          <div class = "users" *ngFor="let loan of filteredLoans$ | async">
          <div class = "user">
            <div class = "green"></div>
            <div class = "red" *ngIf = "loan.status == 4"></div>
            
          <div class = "loantitle">PAWN TICKET #{{loan?.id}}</div>
          <div class = "userlabel">Type : {{loan?.details.type}}</div>
          <div class = "userlabel">Date : {{loan?.details.date.toDate() | date:'dd/MM/yyyy'}}</div>
          <div class = "userlabel">Customer Name : {{loan?.details.name}}</div>
          <div class = "userlabel">Customer Address : {{loan?.details.address}}</div>
          <div class = "userlabel">Customer Phone : {{loan?.details.phone}}</div>
          <div class = "userlabel">Customer Email : {{loan?.details.email}}</div>
          <div class = "userlabel">Transaction Fee : ${{ loan?.details.transactionfee }}</div>
          <div class = "userlabel">Loan Amount : ${{loan?.details.principle}}</div>
          <div class = "userlabel">Rate of Interest % : {{loan?.details.rate}}</div>
          <div class = "userlabel">Duration : {{loan?.details.time}}</div>
          <div class = "userlabel">Total Interest : ${{ loan?.details.totalinterest }}</div>
          <div class="userlabel">{{ loan?.details.type }} Interest : ${{
            loan?.details.totalinterest/loan?.details.time | number: '1.0-2' }}</div>

            <ng-container *ngFor = "let pdoc of loan?.pendingpayments | keyvalue: undefined; let px = index"> 
              <div class = "orange" *ngIf = "pdoc.value.status == 'Pending'"></div>
          </ng-container>

          <div class = "editbuttons">
          <a class = "editbutton" *ngIf = "loan.signed == 'yes'"
          href = "{{ loan.signedurl }}">Print Pawn Ticket</a>

          <div class = "editbutton" *ngIf = "loan.signed !== 'yes'"
          [routerLink]="['/printloan', loan.id ]">Print Pawn Ticket</div>


          <div class = "editbutton" *ngIf = "loan.signed !== 'yes' && loan.digitallysigned !== 'yes'"
          [routerLink]="['/uploadsignedloan', loan.id ]">Add Signature</div>
          
          <div class = "editbutton" *ngIf = "loan.signed == 'yes' || loan.digitallysigned == 'yes'"
          [routerLink]="['/uploadsignedloan', loan.id ]">Edit Signature</div>

          

          <div class = "editbutton" *ngIf = "loan.redeemsign == 'yes'"
          [routerLink]="['/redeemloan', loan.id]">Edit Redemption Signature</div>

          <div class = "editbutton" *ngIf = "loan.redeemsign !== 'yes'"
          [routerLink]="['/redeemloan', loan.id]">Redemption Signature</div>

          <div class = "editbutton" [routerLink]="['/loandetails', loan.id]">View Full Details</div>

          </div>
    </div>
  </div>
  </div>
  </div>
  <ng-template #show>
    <div class = "errorscreen">
    <div class = "errortitle">Unauthorised Access.</div>
    </div>
  </ng-template>