import { Component, OnInit } from '@angular/core';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateTime } from "luxon";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  loanrates$: Observable<any[]>;
  goldrates$: Observable<any[]>;
  now:any;

  constructor(
    private adminflagService: AdminflagService,
    private firestore: AngularFirestore
  ) {
    this.adminflagService.setAdminFlag('coco');
    this.now = DateTime.now();
  }

  ngOnInit(): void {
    this.goldrates$ = this.firestore.collection('buyrates').valueChanges({ idField: 'id' });
    this.loanrates$ = this.firestore.collection('rates').valueChanges({ idField: 'id' });
  }
} 