import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminflagService {

  public adminflag: string;
  constructor() { }
  setAdminFlag(val) {
    this.adminflag = val;
}
  getAdminFlag() {
    return this.adminflag;
}
}