import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";


@Component({
  selector: 'app-editpurchased',
  templateUrl: './editpurchased.component.html',
  styleUrls: ['./editpurchased.component.css']
})
export class EditpurchasedComponent implements OnInit {

  item$: Observable<any[]>;

  constructor(
    private firestore: AngularFirestore,
    public auth: AngularFireAuth
    ) {
      this.item$ = firestore.collection('purchaseditems', ref => ref.orderBy('id','desc')).valueChanges();
    }

  ngOnInit() {
  }

  updateStatus(id, status, selldate, amount) {
    var sid = id.toString();
    var formISODate = selldate.toISOString();
    var sdate = DateTime.fromISO(formISODate).toString();

    return this.firestore.collection('purchaseditems').doc(sid).update({
      "details.status":status,
      "details.selldate":sdate,
      "details.soldamount": amount
    }).then(() => {
      this.notificationPop("Updated successfully.");
    }).catch((error) => {
      this.notificationPop("Update failed.");
    });
  }
  
  
  notificationHide() {
    document.getElementById('notification').classList.remove('pop');
    }
    
    notificationDisplay() {
    document.getElementById('notification').classList.add('pop');
    }
    
    notificationPop(message: string) {
      document.getElementById('notificationtext').innerHTML = message;
      this.notificationDisplay();
      setTimeout(this.notificationHide, 2500);
    }
  
    showItemForm(theid) {
      var rtheid = "gform"+theid;
      document.getElementById(rtheid).classList.toggle('edititem');
    }

}