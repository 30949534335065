<div *ngIf="auth.user | async as user; else show">
    <div class = "flexscreen">
      <div class = "heading">
        <div class = "formtitle">Edit rates</div>
        <div class = "formsubtitle">Edit existing gold rates.</div>
      </div>
            <div class = "users" *ngFor="let loan of loans$ | async">
            <div class = "user" id = "{{ loan.id }}">
            <div class = "loantitle">Gold buying rates</div>
            <div class = "userlabel">24 ct : ${{ loan.e }} per gram</div>
            <div class = "userlabel">22 ct : ${{ loan.d }} per gram</div>
            <div class = "userlabel">18 ct : ${{ loan.c }} per gram</div>
            <div class = "userlabel">14 ct : ${{ loan.b }} per gram</div>
            <div class = "userlabel">9 ct : ${{ loan.a }} per gram</div>
            <div class = "belowtitle">Gold pawn rates</div>
            <div class = "userlabel">24 ct : ${{ loan.j }} per gram</div>
            <div class = "userlabel">22 ct : ${{ loan.i }} per gram</div>
            <div class = "userlabel">18 ct : ${{ loan.h }} per gram</div>
            <div class = "userlabel">14 ct : ${{ loan.g }} per gram</div>
            <div class = "userlabel">9 ct : ${{ loan.f }} per gram</div>
            <div class = "editbuttons">
                <div class = "editbutton" (click) = "showEditor()">Edit rates</div>
                </div>
                <form class = "editform" id = "editform" #f="ngForm">
                    <div class = "formtitle">Gold buying rates</div>
<mat-form-field>
    <mat-label>
        24 ct
    </mat-label>
    <input matInput
    class="form-control"
    #e (keyup)="0"
    name="e"
    [(ngModel)]="loan.e"
    >
</mat-form-field>

<mat-form-field>
    <mat-label>
        22 ct
    </mat-label>
    <input matInput
    class="form-control"
    #d (keyup)="0"
    name="d"
    [(ngModel)]="loan.d"
    >
</mat-form-field>

<mat-form-field>
    <mat-label>
        18 ct
    </mat-label>
    <input matInput
    class="form-control"
    #c (keyup)="0"
    name="c"
    [(ngModel)]="loan.c"
    >
</mat-form-field>

<mat-form-field>
    <mat-label>
        14 ct
    </mat-label>
    <input matInput
    class="form-control"
    #b (keyup)="0"
    name="b"
    [(ngModel)]="loan.b"
    >
</mat-form-field>

<mat-form-field>
    <mat-label>
        9 ct
    </mat-label>
    <input matInput
    class="form-control"
    #a (keyup)="0"
    name="a"
    [(ngModel)]="loan.a"
    >
</mat-form-field>

<div class = "formtitle">Gold pawn rates</div>
<mat-form-field>
    <mat-label>
        24 ct
    </mat-label>
    <input matInput
    class="form-control"
    #j (keyup)="0"
    name="j"
    [(ngModel)]="loan.j"
    >
</mat-form-field>

<mat-form-field>
    <mat-label>
        22 ct
    </mat-label>
    <input matInput
    class="form-control"
    #i (keyup)="0"
    name="i"
    [(ngModel)]="loan.i"
    >
</mat-form-field>

<mat-form-field>
    <mat-label>
        18 ct
    </mat-label>
    <input matInput
    class="form-control"
    #h (keyup)="0"
    name="h"
    [(ngModel)]="loan.h"
    >
</mat-form-field>

<mat-form-field>
    <mat-label>
        14 ct
    </mat-label>
    <input matInput
    class="form-control"
    #g (keyup)="0"
    name="g"
    [(ngModel)]="loan.g"
    >
</mat-form-field>

<mat-form-field>
    <mat-label>
        9 ct
    </mat-label>
    <input matInput
    class="form-control"
    #f (keyup)="0"
    name="f"
    [(ngModel)]="loan.f"
    >
</mat-form-field>


<button mat-raised-button color="primary" class = "xbutton" (click)="updateLoan(loan.id, loan.a, loan.b, loan.c, loan.d, loan.e, loan.f, loan.g, loan.h, loan.i, loan.j)">Finish editing rates</button>
                
</form>             

      </div>
    </div>
    </div>
    </div>

    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>
  
    <div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
      <div id = "notificationtext"></div>
      </div>