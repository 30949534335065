import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import User from 'src/app/models/admin.model';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-editadmin',
  templateUrl: './editadmin.component.html',
  styleUrls: ['./editadmin.component.css']
})
export class EditadminComponent implements OnInit, OnChanges {

  @Input() admin?: User;
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  currentAdmin: User = {
    details : { name: '',
                email: '',
                password: '',
                phone: ''
  },
profile : {
  photoid : ''
}};
  message = '';

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.message = '';
  }

  ngOnChanges(): void {
    this.message = '';
    this.currentAdmin = { ...this.admin };
  }



  updateAdmin(): void {
const data = 
{ 
  details: { 
  email: this.currentAdmin.details.email, 
  phone: this.currentAdmin.details.phone, 
  password: this.currentAdmin.details.password, 
  name: this.currentAdmin.details.name }
};

    if (this.currentAdmin.details.email) {
      this.adminService.update(this.currentAdmin.details.email, data)
        .then(() => this.message = 'The admin was updated successfully!')
        .catch(err => console.log(err));
    }
  }
  deleteAdmin(): void {
    if (this.currentAdmin.details.email) {
      this.adminService.delete(this.currentAdmin.details.email)
        .then(() => {
          this.refreshList.emit();
          this.message = 'The admin was deleted successfully!';
        })
        .catch(err => console.log(err));
    }
  }

}
