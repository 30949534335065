<div *ngIf="auth.user | async as user; else show">
      <div class = "flexscreen">
        <div class = "heading">
          <div class = "formtitle">Admin settings</div>
          <div class = "formsubtitle">Hello, <span>{{ user.email.substring(0, user.email.lastIndexOf("@")) }}.</span></div>
        </div>
        <div class = "tabs">
          <div class = "tab" [routerLink]="['/editusers']">
          <div class = "userlabel">Edit Customers</div>
        </div>
        <div class = "tab" [routerLink]="['/editloans']">
          <div class = "userlabel">Edit Personal Loans</div>
        </div>
        <div class = "tab" [routerLink]="['/editgoldloans']">
          <div class = "userlabel">Edit Gold Loans</div>
        </div>
        <div class = "tab" [routerLink]="['/editrates']">
          <div class = "userlabel">Edit Gold loan rates</div>
        </div>
        <div class = "tab" [routerLink]="['/editbuyrates']">
          <div class = "userlabel">Edit Gold buying rates</div>
        </div>
        <div class = "tab" [routerLink]="['/addadmin']">
          <div class = "userlabel">Add Admins</div>
        </div>
        <div class = "tab" [routerLink]="['/pawnsign']">
          <div class = "userlabel">Add Pawn Broker's Signature</div>
        </div>
    </div>
  </div>
      </div>
      <ng-template #show>
        <div class = "errorscreen">
        <div class = "errortitle">Unauthorised Access.</div>
        </div>
      </ng-template>

      <div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
        <div id = "notificationtext"></div>
        </div>