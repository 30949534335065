import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";

interface Dur {
  value: string;
}

@Component({
  selector: 'app-editloan',
  templateUrl: './editloan.component.html',
  styleUrls: ['./editloan.component.css']
})
export class EditloanComponent implements OnInit {
  selected: string;
  durs: Dur[] = [
    { value: 'Weekly' },
    { value: 'Fortnightly' },
    { value: 'Monthly' }
  ];
  loans: Array<any>;
  loans$: Observable<any[]>;
  filteredLoans$: Observable<any[]>;
  math = Math;
  parsefloat = parseFloat;

  constructor(private firestore: AngularFirestore,
    public auth: AngularFireAuth) {
    this.loans$ = firestore.collection('loans', ref => ref.orderBy('details.date', 'desc')).valueChanges({ idField: 'id' });
  }

  ngOnInit() {
    this.filteredLoans$ = this.loans$;
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value: string) {
    if (value) {


      this.filteredLoans$ = this.loans$
        .pipe(
          // the stream is of a single item that is of type array
          // map(user => user.name) would not work because it is not // a stream of items inside the array
          map((loans: any[]) => {
            // inside the map we use the native Array.prototype.filter() method to filter down the results by name
            return loans.filter((loan: any) => loan.id.toLowerCase().indexOf(value.toLowerCase()) > -1)
          })

        )
    } else {
      // reload the full data set
      this.filteredLoans$ = this.loans$;
    }
  }

  showEditor(event) {
    document.getElementById(event.target.parentNode.parentNode.id).classList.toggle('showform');
  }

  showComment(event) {
    document.getElementById(event.target.parentNode.parentNode.id).classList.toggle('showcomment');
  }

  updateLoan(id, prevtype, type, transactionfee, principle, rate, prevtime, time, datecreated) {
    var cmi = principle * (rate / 100) * Math.pow((1 + (rate / 100)), time) / (Math.pow((1 + (rate / 100)), time) - 1);
    var cam = cmi * time;
    var cre = cam - principle + parseInt(transactionfee);
    var emi = Math.round((cmi + Number.EPSILON) * 100) / 100;
    var amo = Math.round((cam + Number.EPSILON) * 100) / 100;
    var rev = Math.round((cre + Number.EPSILON) * 100) / 100;

    var inttt = (principle * rate * time) / 100;
    var totalint = Math.round((inttt) * 100) / 100;
    if (type == 'Monthly') {
      var intt = inttt / 12;
    }
    else if (type == 'Weekly') {
      var intt = inttt / 52;
    }
    else if (type == 'Fortnightly') {
      var intt = inttt / 26;
    }
    var int = Math.round((intt + Number.EPSILON) * 100) / 100;


    var tt = datecreated.toDate();
    var dt = DateTime.fromJSDate(tt);

    var wtime = parseInt(time);
    var pendingpayments = {};
    var i, weekly, fnightly, monthly;
    ///// Monthly
    if (type == 'Monthly') {
      for (i = 0; i < wtime; i++) {
        monthly = dt.plus({ months: i });
        pendingpayments[i] = {
          "pendingdate": monthly.toISO(),
          "day": monthly.day,
          "month": monthly.month,
          "year": monthly.year,
          "status": "Pending",
          "amount": 0,
          "paymentdate": ""
        };
      }
    }
    ////// Weekly
    else if (type == 'Weekly') {
      for (i = 0; i < wtime; i++) {
        weekly = dt.plus({ days: i * 7 });
        pendingpayments[i] = {
          "pendingdate": weekly.toISO(),
          "day": weekly.day,
          "month": weekly.month,
          "year": weekly.year,
          "status": "Pending",
          "amount": 0,
          "paymentdate": ""
        };
      }
    }
    ////// Fortnightly
    else if (type == 'Fortnightly') {
      for (i = 0; i < wtime; i++) {
        fnightly = dt.plus({ days: i * 14 });
        pendingpayments[i] = {
          "pendingdate": fnightly.toISO(),
          "day": fnightly.day,
          "month": fnightly.month,
          "year": fnightly.year,
          "status": "Pending",
          "amount": 0,
          "paymentdate": ""
        };
      }
    }

    if ((time !== prevtime) || (type !== prevtype)) {
      return this.firestore.collection('loans').doc(id).update({
        "details.type": type,
        "details.transactionfee": transactionfee,
        "details.principle": principle,
        "details.rate": rate,
        "details.time": time,
        "details.emi": emi,
        "details.totalinterest": totalint,
        "details.interest": int,
        "details.amount": amo,
        "pendingpayments": pendingpayments
      }).then(() => {
        this.notificationPop("Edited successfully.");
      }).catch((error) => {
        this.notificationPop("Edit failed.");
      });
    }
    else {
      return this.firestore.collection('loans').doc(id).update({
        "details.type": type,
        "details.transactionfee": transactionfee,
        "details.principle": principle,
        "details.rate": rate,
        "details.time": time,
        "details.emi": emi,
        "details.totalinterest": totalint,
        "details.interest": int,
        "details.amount": amo
      }).then(() => {
        this.notificationPop("Edited successfully.");
      }).catch((error) => {
        this.notificationPop("Edit failed.");
      });
    }
  }

  updateComment(id, comment) {
    return this.firestore.collection('loans').doc(id).update({
      "details.comment": comment
    }).then(() => {
      this.notificationPop("Added successfully.");
    }).catch((error) => {
      this.notificationPop("Add failed.");
    });
  }

  deleteLoan(id) {
    if (confirm("Are you sure you want to delete the loan?") == true) {
      return this.firestore.collection('loans').doc(id).delete().then(() => {
        this.notificationPop("Deleted successfully.");
      }).catch((error) => {
        this.notificationPop("Delete failed.");
      });
    } else {
      console.log('cancelled');
    }


  }

  notificationHide() {
    document.getElementById('notification').classList.remove('pop');
  }

  notificationDisplay() {
    document.getElementById('notification').classList.add('pop');
  }

  notificationPop(message: string) {
    document.getElementById('notificationtext').innerHTML = message;
    this.notificationDisplay();
    setTimeout(this.notificationHide, 2500);
  }
}
