import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AdminflagService } from '../../../app/services/adminflag.service';

@Component({
  selector: 'app-printpurchased',
  templateUrl: './printpurchased.component.html',
  styleUrls: ['./printpurchased.component.css']
})
export class PrintpurchasedComponent implements OnInit {
  
  id:string;

  loansCollection: AngularFirestoreCollection<any>;
  loans: Observable<any[]>;
  gloan: any;

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private adminflagService: AdminflagService
    ) {
      this.loansCollection = this.afs.collection('purchaseditems');
      this.loans = this.loansCollection.valueChanges();
    }
  ngOnInit() {
    this.adminflagService.setAdminFlag('boco');
    this.route.paramMap.subscribe(params => { 
      this.id = params.get('id'); 
      this.loansCollection.doc(this.id).ref.get().then((doc) => {
        this.gloan = doc.data();
        });
  });
  
}
ngOnDestroy() {
  this.adminflagService.setAdminFlag('');
}
}