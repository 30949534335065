import { Component, OnInit } from '@angular/core';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';
import { DateTime } from "luxon";

@Component({
  selector: 'app-loanrates',
  templateUrl: './loanrates.component.html',
  styleUrls: ['./loanrates.component.css']
})
export class LoanratesComponent implements OnInit {
  now:any;
  loans: Array<any>;
  loans$: Observable<any[]>;

  constructor(
    private adminflagService: AdminflagService,
    private firestore: AngularFirestore,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService
  ) {
    this.adminflagService.setAdminFlag('coco');
    this.meta.addTags([
      { name: 'description', content: 'Get the best loan rates in Melbourne. Instant cash loans without credit checks. Contact us today.' },
      { name: 'author', content: 'Prakahs Gold Finance' },
      { name: 'keywords', content: 'best loan rates, best loan rates melbourne, cash loan melbourne, gold loan melbourne, sell gold for cash, sell scrap gold, sell your gold jewellery, selling your gold, jewellery buyers' }
    ]);
    this.setTitle('Best loan rates in Melbourne | Prakahs Gold Finance');
    this.now = DateTime.now();
  }

  ngOnInit(): void {
    this.loans$ = this.firestore.collection('rates').valueChanges({ idField: 'id' });
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
    this.createLinkForCanonicalURL();
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 


