import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  iurl = new FormControl('');
  imgu: String;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string|null>;
  messageDetails: FormGroup;
  submitted = false;

  metrics: Array<any>;
  metrics$: Observable<any[]>;

  users$:any;
  users:number;
  puser: any;
  userid: any;

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private formBuilder: FormBuilder,
    private adminflagService: AdminflagService,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService
    ) {
      this.users$ = this.afs.collection(`users`).valueChanges().subscribe(userss => {
        this.users = userss.length + 1;
        });
        this.metrics$ = this.afs.collection('metrics').valueChanges();
        this.adminflagService.setAdminFlag('coco');
        this.meta.addTags([
          { name: 'description', content: 'Contact to sell gold at best rates in Melbourne. No Appointments required. No credit checks for cash loans.' },
          { name: 'author', content: 'Prakahs Gold Finance' },
          { name: 'keywords', content: 'contact gold buyer melbourne, best gold buyer melbourne, loan melbourne, what you need for loan melbourne, gold loan documents melbourne, loan documents melbourne, gold loan melbourne' }
        ]);
        this.setTitle('Contact to sell gold or for instant cash loans for Gold with no credit checks in Melbourne | Prakahs Gold Finance');
        this.createLinkForCanonicalURL();
    }
  
ngOnInit() {
        this.messageDetails = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.email],
            message: ['', Validators.required]
        });
    }

    get fdetails() { return this.messageDetails.controls; }

    addMessage() {
        this.submitted = true;
        this.messageDetails.markAllAsTouched();
        if (this.messageDetails.invalid) {
          this.notificationPop("Check credentials");
            return;
        }
          this.createMessage();      
    }


    createMessage() {

      this.afs.collection('metrics').doc('messages').ref.get().then((udoc) => {
        if (udoc.exists) {
          document.getElementById('useraddform').classList.add('vanish');
          document.getElementById('loading').classList.add('load');
          this.puser = udoc.data();
          var messageid = this.puser.messagecount+1;
          let user = [
            { 
              id: messageid,
              details: this.messageDetails.value }
            ],
            object = Object.assign({}, ...user);
              this.afs.collection(`messages`).doc(messageid.toString()).set(object);
              this.afs.collection('metrics').doc('messages').update({
                "messagecount": messageid
            }).then(() => {
            this.notificationPop("Your message was sent successfully");
            document.getElementById('loading').classList.remove('load');
            document.getElementById('uploaded').classList.add('finished');
            }).catch((error) => {
            this.notificationPop("Your message failed to send");
            });
        } else {
            console.log("No such document!");
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
      }
    getErrorMessage() {
      if (this.messageDetails.controls['email'].hasError('required')) {
        return 'Email is required.';
      }
      return this.messageDetails.controls['email'].hasError('email') ? 'Please enter a valid email.' : '';
    }
notificationHide() {
document.getElementById('notification').classList.remove('pop');
}

notificationDisplay() {
document.getElementById('notification').classList.add('pop');
}

notificationPop(message: string) {
  document.getElementById('notificationtext').innerHTML = message;
  this.notificationDisplay();
  setTimeout(this.notificationHide, 2500);
}
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 