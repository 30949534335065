// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBFR02el0SN7m-TDFEVkJ9atGvVnJIqttw",
  authDomain: "prakahs-website.firebaseapp.com",
  projectId: "prakahs-website",
  storageBucket: "prakahs-website.appspot.com",
  messagingSenderId: "549988662198",
  appId: "1:549988662198:web:0c6573081eb30fa1799f17",
  measurementId: "G-ZKE96SJ5D6"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
