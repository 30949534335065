<div *ngIf="auth.user | async as user; else show">
  <div class="flexscreen">
    <div class="heading">
      <div class="formtitle">Edit customers</div>
      <div class="formsubtitle">Edit existing customers</div>
    </div>

    <input class="search-box" placeholder="Search for a user" (keyup)=search($event.target.value) type="text">

    <div class="users">
      <div *ngFor="let user of filteredUsers$ | async ">
        <div class="user" id="{{ user.id }}">
          <div class="userdp">
            <img [src]="user.profile.photoid" />
          </div>
          <div class="userlabel"><span>Id : </span>{{ user.id }}</div>
          <div class="userlabel"><span>Name : </span>{{ user.details.name }}</div>
          <div class="userlabel"><span>Photo ID : </span>{{ user.details.photoidno }}</div>
          <div class="userlabel"><span>Email : </span>{{ user.details.email }}</div>
          <div class="userlabel"><span>Phone : </span>{{ user.details.phone }}</div>
          <div class="userlabel"><span>Address : </span>{{ user.details.address }}</div>
          <div class="editbuttons">
            <div class="editbutton" (click)="showEditor($event)">Edit</div>
            <div class="editbutton" [routerLink]="['/uploadid', user.id ]">Upload ID</div>
            <div class="editbutton" (click)="deleteUser(user.id, user.details.name)">Delete</div>
          </div>
          <form class="editform" id="editform" #f="ngForm">
            <mat-form-field>
              <mat-label>Name of user</mat-label>
              <input matInput name="name" class="form-control" [(ngModel)]="user.details.name" required>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Photo ID of customer</mat-label>
              <input matInput name = "photoidno" class="form-control" [(ngModel)]="user.details.photoidno" required
              [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email of user</mat-label>
              <input matInput name="email" class="form-control" [(ngModel)]="user.details.email" required>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Phone of user</mat-label>
              <input matInput name="phone" class="form-control" [(ngModel)]="user.details.phone" required>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Address of user</mat-label>
              <input matInput name="address" class="form-control" [(ngModel)]="user.details.address" required>
            </mat-form-field>
            <button mat-raised-button color="primary" class="xbutton"
              (click)="updateUser(user.id, user.details.name, user.details.photoidno, user.details.email, user.details.phone, user.details.address)">Finish
              editing user</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #show>
  <div class="errorscreen">
    <div class="errortitle">Unauthorised Access.</div>
  </div>
</ng-template>

<div class="notification" id="notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
    viewBox="0 0 24 24">
    <path
      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z" />
  </svg>
  <div id="notificationtext"></div>
</div>