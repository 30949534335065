import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DateTime } from "luxon";
import * as XLSX from 'xlsx';
import { AngularFireAuth } from '@angular/fire/auth';
import { ExcelService } from '../../services/excel.service';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import Loan from 'src/app/models/loan.model';

@Component({
  selector: 'app-glpending',
  templateUrl: './glpending.component.html',
  styleUrls: ['./glpending.component.css']
})
export class GlpendingComponent implements OnInit {

  fileName = 'Pending_GoldLoans.xlsx';
  now: any;
  loanarray: any;

  filteredLoanarray: Observable<any[]>;

  constructor(
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private excelService: ExcelService) {
  }

  ngOnInit(): void {
    this.now = DateTime.now().toISO();
    this.loanarray = [];
    const queryObservable = this.afs.collection('goldloans', ref => ref.orderBy('id', 'desc')).valueChanges();
    queryObservable.subscribe(queriedItems => {
      for (let [index, loan] of queriedItems.entries()) {
        const [cchecker, crevenue, cremaininginterest, cremainingloan, pendingc, timec, intc, ccc, cintc] = this.filterLoans(loan);
        if (cchecker == true) {
          this.loanarray[index] = loan;
          this.loanarray[index].erevenue = crevenue;
          this.loanarray[index].remaininginterest = cremaininginterest;
          this.loanarray[index].remainingloan = cremainingloan;
          this.loanarray[index].epending = pendingc;
          this.loanarray[index].ctime = timec;
          this.loanarray[index].cinterest = intc;
          this.loanarray[index].ftime = ccc;
          this.loanarray[index].finterest = cintc;
        }
      }
    });
    this.filteredLoanarray = this.loanarray;
  }

  filterLoans(loan) {

    var revenue: number = 0;
    var count: number = 0;
    var pendingd = [];
    var checker = false;
    var looper;

    var loanreturned: number = 0;
    var interestreturned: number = 0;


    function getDateDiff(date1, date2) {

      var diff = Math.abs(date1.getTime() - date2.getTime());
      var days = Math.ceil(diff / (1000 * 3600 * 24));
      var weeks = Math.ceil(days / 7);
      var fortnights = Math.ceil(weeks / 2);
      var months = Math.ceil(days / 30);
      return {
        days: days,
        weeks: weeks,
        fortnights: fortnights,
        months: months
      };
    }

    var date1 = loan.details.date.toDate();
    var date2 = new Date();
    console.log(date1);

    var diff = getDateDiff(date1, date2);
    var monthdiff = diff.months;


    if (loan.details.type == 'Monthly') {
      var tcount = diff.months;
    }
    else if (loan.details.type == 'Weekly') {
      var tcount = diff.weeks;
    }
    else if (loan.details.type == 'Fortnightly') {
      var tcount = diff.fortnights;
    }

    var intt = (loan.details.principle * loan.details.rate * tcount)/100;
    var int = Math.round((intt + Number.EPSILON) * 100) / 100;
    for (looper = 0; looper < loan.details.time; looper++) {
      var datetobechecked = loan.pendingpayments[looper].pendingdate;

      var status = loan.pendingpayments[looper].status;

      if (loan.pendingpayments[looper].interestreceived) {
        revenue = revenue + parseInt(loan.pendingpayments[looper].interestreceived);
      }
      if (loan.pendingpayments[looper].loanreturned) {
        loanreturned = loanreturned + parseInt(loan.pendingpayments[looper].loanreturned);
      }
      if (loan.status == 0 && this.now >= datetobechecked && status !== 'Paid') {
        pendingd[looper] = datetobechecked;
        checker = true;
      }
      if (this.now >= datetobechecked) {
        count = count + 1;
      }
      var remaininginterest = loan.details.totalinterest - revenue;
      var remainingloan = loan.details.principle - loanreturned;
    }

    var cintt = (loan.details.principle * loan.details.rate * count)/100;
    var cint = Math.round((cintt + Number.EPSILON) * 100) / 100;

    return [checker, revenue, remaininginterest, remainingloan, pendingd, tcount, int, count, cint];
  }




  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    this.excelService.exportAsExcelFile(wb, 'PendingGoldLoans');
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  showPending(event, date, status) {
    var pendingloan = document.getElementById(event);
    if (this.now > date && status !== 'Paid') {
      pendingloan.classList.remove("notpending");
    }
  }

  search(svalue: any) {
    this.filteredLoanarray = this.loanarray.filter(loan => {
      return loan.details.name.toLowerCase().includes(svalue.toLowerCase());
    })
  }

}