import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loansCollection: AngularFirestoreCollection<any>;
  items: Observable<any[]>;
  loans: Observable<any[]>;
  loanstoday: Number;
  loansyesterday: Number;
  todaysub: any;
  totalusersub: any;
  todaygoldsub: any;
  goldloanstoday: Number;
  totalusers: Number;
  constructor(
    public auth: AngularFireAuth,
    private afs: AngularFirestore
    ) {
    var cDate = new Date();
    var monthtod = cDate.getMonth();
    var monthtoday = monthtod + 1;
    var datetoday = cDate.getDate();
    var yeartoday = cDate.getFullYear();
    this.todaysub = this.afs.collection(`loans`, ref => ref.where('date', "==", datetoday).where('year', '==', yeartoday).where('month', '==', monthtoday)).snapshotChanges().subscribe(today => {
    this.loanstoday = today.length;
});
    this.todaygoldsub = this.afs.collection(`goldloans`, ref => ref.where('date', "==", datetoday).where('year', '==', yeartoday).where('month', '==', monthtoday)).snapshotChanges().subscribe(goldtoday => {
    this.goldloanstoday = goldtoday.length;
});
    this.totalusersub = this.afs.collection(`users`).snapshotChanges().subscribe(tusers => {
      this.totalusers = tusers.length;
    });
}
  ngOnInit(): void {
  }
    ngOnDestroy(): void {
      this.todaysub.unsubscribe();
    }
}
