<div class = "flexscreen">
  <div class = "heading">
    <div class = "formtitle">Add a new loan</div>
    <div class = "formsubtitle">Fill in the required details.</div>
    </div>
    <div class = "formarea">

  <mat-horizontal-stepper #stepper linear>
      <mat-step [stepControl]="loanHidden">
          <ng-template matStepLabel>Select a user</ng-template>
          <form [formGroup]="loanHidden">
          <input type="hidden" 
                formControlName="loanuser" 
                class="form-control"/>
                </form>
          <div class = "usertable">
          <div class = "search"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"/></svg>
          <input class="search-box" placeholder="Search for a user" (keyup)=search($event.target.value) type="text"></div>
              <div class = "users">
                  <div *ngFor="let user of filteredUsers$ | async">
                  <div class = "user" (click)="goForward(user.details.email, stepper)">
                  <div class = "userdp">
                  <img [src] = "user.profile.photoid" /></div>
                  <div class = "usertitle">{{user.details.name}}</div>
                  <div class = "userlabel"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg><div class = "llabel">{{user.details.email}}</div></div>
                  <div class = "userlabel"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg><div class = "llabel">{{user.details.phone}}</div></div>
                </div>
            </div>
          </div>
          </div>
      </mat-step>
      <mat-step  label="Fill loan details" [stepControl]="loanDetails">
<div class = "form1">
<form [formGroup]="loanDetails">
<mat-form-field>
  <mat-label>Date</mat-label>
  <input matInput [matDatepicker]="picker"
  formControlName="date"
  class="form-control"
  required/>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<mat-form-field>
  <mat-label>Loan ID</mat-label>
  <input matInput
  formControlName="loanid" 
  >
</mat-form-field>
<input type="hidden"
          formControlName="selectedemail"
          class="form-control"
          placeholder="Selected Email"/>
  <mat-form-field>
      <mat-label>Loan Amount</mat-label>
      <input matInput
      #principle (keyup)="0"
      formControlName="principle" 
      >
    </mat-form-field>
      <mat-form-field>
      <mat-label>Rate of Interest (% per annum)</mat-label>
      <input matInput
      #rate (keyup)="0"
      formControlName="rate" 
      >
    </mat-form-field>
      <mat-form-field>
      <mat-label>Time Period (months)</mat-label>
      <input matInput
      #time (keyup)="0"
      formControlName="time" 
      >
    </mat-form-field>
  <div class = "xinterest">Monthly EMI : {{ principle.value * (rate.value/12/100) * math.pow((1+(rate.value/12/100)), time.value) / (math.pow((1+(rate.value/12/100)), time.value) - 1) }}
  </div>
  <button mat-raised-button color="primary" matStepperNext type="button" class = "xbutton">Proceed to Verification</button>
</form>
</div>
      </mat-step>
      <mat-step [stepControl]="loanItems">
        <ng-template matStepLabel>Add loan items</ng-template>
        <div class = "holderc">
        <button class = "addbutton" mat-raised-button color="primary" (click)="addCreds()" id = "topbutton">Click to add gold item</button>
        <form [formGroup]="loanItems" class = "loanItems">
        
        <div formArrayName="golditems" *ngFor="let creds of loanItems.controls.golditems?.value; let i = index; trackBy:trackByFn">
          <div class = "golditemcontainer">
              <div class = "itemheading">Item {{ i+1 }}</div>
          <ng-container [formGroupName]="i">
            <div class="inputsection">
              <mat-form-field>
              <mat-label>Type</mat-label>
              <input matInput
              formControlName="type"
              required
              >
            </mat-form-field>
          </div>
          <div class="inputsection">
              <mat-form-field>
              <mat-label>Weight</mat-label>
              <input matInput
              formControlName="weight" 
              class="form-control"
              required
              >
            </mat-form-field>
          </div>
          <div class="inputsection">
              <mat-form-field>
              <mat-label>Additional Info</mat-label>
              <input matInput
              formControlName="additional" 
              class="form-control"
              required
              >
            </mat-form-field>
          </div>

      <div class="inputsection">
          <mat-form-field>
          <mat-label>Carats (If item has diamond)</mat-label>
          <input matInput
          formControlName="carats" 
          class="form-control"
          required
          >
        </mat-form-field>
      </div>

      <div class="inputsection" id = "uploader">
          <label for="file">Click on button or drag drop image below.</label>
          <input type="file" (change)="uploadFile($event, i)" id = "file" accept=".png, .jpg, .jpeg, .gif"/>
          </div>


          </ng-container>
        </div></div>
        </form>
          <button mat-raised-button color="primary" (click)= "addLoan(stepper)" class = "addbutton">Finish adding loan</button>
      </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
          <div class = "holderc">The loan has been added successfully.
          <button mat-raised-button color="primary" (click)="stepper.reset()" class = "addbutton">Add a new loan</button>
</div>
      </mat-step>
    </mat-horizontal-stepper>




</div>
</div>

<div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
  <div id = "notificationtext"></div>
  </div>
