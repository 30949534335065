import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthComponent } from './components/auth/auth.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PostloginComponent } from './components/postlogin/postlogin.component';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AddadminComponent } from './components/addadmin/addadmin.component';
import { ViewadminsComponent } from './components/viewadmins/viewadmins.component';
import { EditadminComponent } from './components/editadmin/editadmin.component';
import { AdduserComponent } from './components/adduser/adduser.component';
import { EdituserComponent } from './components/edituser/edituser.component';
import { ViewusersComponent } from './components/viewusers/viewusers.component';
import { AddloanComponent } from './components/addloan/addloan.component';
import { EditloanComponent } from './components/editloan/editloan.component';
import { ViewloansComponent } from './components/viewloans/viewloans.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ControlComponent } from './components/control/control.component';
import { CommonModule } from '@angular/common';
import { TestComponent } from './components/test/test.component';
import { SellgoldComponent } from './components/sellgold/sellgold.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NewuserComponent } from './components/newuser/newuser.component';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

//Material
import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import { SelectuserComponent } from './components/selectuser/selectuser.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NewloanComponent } from './components/newloan/newloan.component';
import { SettingsComponent } from './components/settings/settings.component';
import { LoaneditComponent } from './components/loanedit/loanedit.component';
import { ViewgoldloansComponent } from './components/viewgoldloans/viewgoldloans.component';
import { EditgoldloansComponent } from './components/editgoldloans/editgoldloans.component';
import { NewgoldloanComponent } from './components/newgoldloan/newgoldloan.component';
import { PrintComponent } from './components/print/print.component';
import { InitialComponent } from './components/initial/initial.component';
import { GoldComponent } from './components/gold/gold.component';
import { PrintloanComponent } from './components/printloan/printloan.component';
import { GinitialComponent } from './components/ginitial/ginitial.component';
import { RatesComponent } from './components/rates/rates.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { PendingloansComponent } from './components/pendingloans/pendingloans.component';
import { PendinggoldComponent } from './components/pendinggold/pendinggold.component';
import { PayloanComponent } from './components/payloan/payloan.component';
import { PaygoldloanComponent } from './components/paygoldloan/paygoldloan.component';
import { AddpgoldComponent } from './components/addpgold/addpgold.component';
import { BuyratesComponent } from './components/buyrates/buyrates.component';
import { RevenueComponent } from './components/revenue/revenue.component';
import { LoandetailsComponent } from './components/loandetails/loandetails.component';
import { GoldloandetailsComponent } from './components/goldloandetails/goldloandetails.component';
import { IduploadComponent } from './components/idupload/idupload.component';
import { ExcelComponent } from './components/excel/excel.component';
import { ExcelgoldComponent } from './components/excelgold/excelgold.component';
import { EditpurchasedComponent } from './components/editpurchased/editpurchased.component';
import { RevenueloansComponent } from './components/revenueloans/revenueloans.component';
import { RevenuegoldloansComponent } from './components/revenuegoldloans/revenuegoldloans.component';
import { MessagesComponent } from './components/messages/messages.component';
import { RedeemsignComponent } from './components/redeemsign/redeemsign.component';
import { RedeemsigngoldComponent } from './components/redeemsigngold/redeemsigngold.component';
import { OwnersignatureComponent } from './components/ownersignature/ownersignature.component';
import { GoldbuyersComponent } from './components/goldbuyers/goldbuyers.component';
import { CashforgoldComponent } from './components/cashforgold/cashforgold.component';
import { GoldloanComponent } from './components/goldloan/goldloan.component';
import { GoldsellComponent } from './components/goldsell/goldsell.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoanratesComponent } from './components/loanrates/loanrates.component';
import { HeaderComponent } from './components/header/header.component';
import { PgoldloanComponent } from './components/pgoldloan/pgoldloan.component';
import { ItemsrevenueComponent } from './components/itemsrevenue/itemsrevenue.component';
import { TcComponent } from './components/tc/tc.component';
import { SearchComponent } from './components/search/search.component';
import { GlpendingComponent } from './components/glpending/glpending.component';
import { PlpendingComponent } from './components/plpending/plpending.component';
import { PrintpurchasedComponent } from './components/printpurchased/printpurchased.component';
import { ExcelService } from './services/excel.service';
import { NoCommaPipe } from './no-comma.pipe';
import { GoldsignComponent } from './components/goldsign/goldsign.component';
import { RgoldloansComponent } from './components/rgoldloans/rgoldloans.component';
import { RloansComponent } from './components/rloans/rloans.component';
import { RgoldComponent } from './components/rgold/rgold.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    HomeComponent,
    NavComponent,
    FooterComponent,
    AuthComponent,
    PostloginComponent,
    AdminComponent,
    DashboardComponent,
    AddadminComponent,
    ViewadminsComponent,
    EditadminComponent,
    AdduserComponent,
    EdituserComponent,
    ViewusersComponent,
    AddloanComponent,
    EditloanComponent,
    ViewloansComponent,
    ControlComponent,
    TestComponent,
    SellgoldComponent,
    NewuserComponent,
    SelectuserComponent,
    AnalyticsComponent,
    SidebarComponent,
    NewloanComponent,
    SettingsComponent,
    LoaneditComponent,
    ViewgoldloansComponent,
    EditgoldloansComponent,
    NewgoldloanComponent,
    PrintComponent,
    InitialComponent,
    GoldComponent,
    PrintloanComponent,
    GinitialComponent,
    RatesComponent,
    PaymentsComponent,
    PendingloansComponent,
    PendinggoldComponent,
    PayloanComponent,
    PaygoldloanComponent,
    AddpgoldComponent,
    BuyratesComponent,
    RevenueComponent,
    LoandetailsComponent,
    GoldloandetailsComponent,
    IduploadComponent,
    ExcelComponent,
    ExcelgoldComponent,
    EditpurchasedComponent,
    RevenueloansComponent,
    RevenuegoldloansComponent,
    MessagesComponent,
    RedeemsignComponent,
    RedeemsigngoldComponent,
    OwnersignatureComponent,
    GoldbuyersComponent,
    CashforgoldComponent,
    GoldloanComponent,
    GoldsellComponent,
    LandingComponent,
    LoanratesComponent,
    HeaderComponent,
    PgoldloanComponent,
    ItemsrevenueComponent,
    TcComponent,
    SearchComponent,
    GlpendingComponent,
    PlpendingComponent,
    PrintpurchasedComponent,
    NoCommaPipe,
    GoldsignComponent,
    RgoldloansComponent,
    RloansComponent,
    RgoldComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
    AngularFireStorageModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
  ],
  providers: [
    ExcelService,
    ScreenTrackingService,
    UserTrackingService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: PERSISTENCE, useValue: 'session' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

