import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../helpers/must-match.validator';
import { AdminflagService } from '../../../app/services/adminflag.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent {
  loginForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder, 
    public auth: AngularFireAuth,
    private adminflagService: AdminflagService,
    public router: Router
    ) {
  }
  login(email, password) {
    this.auth.signInWithEmailAndPassword(email, password)
    .then((user) => {
      this.notificationPop("Logged in successfully.");
      this.router.navigate(['/dashboard']);
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      this.notificationPop("Invaild credentials. Please try again!");
    });
  }
  logout() {
    this.auth.signOut();
  }
  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          user: ['', Validators.required],
          password: ['', [Validators.required, Validators.minLength(6)]]
      });
  }
  ngOnDestroy() {
    this.adminflagService.setAdminFlag('');
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }
      this.login(this.loginForm.value.user, this.loginForm.value.password);

  }

  onReset() {
      this.submitted = false;
      this.loginForm.reset();
  }


notificationHide() {
  document.getElementById('notification').classList.remove('pop');
  }
  
  notificationDisplay() {
  document.getElementById('notification').classList.add('pop');
  }
  
  notificationPop(message: string) {
    document.getElementById('notificationtext').innerHTML = message;
    this.notificationDisplay();
    setTimeout(this.notificationHide, 2500);
  }
}

