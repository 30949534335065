<div *ngIf="auth.user | async as user; else show"> 
<div class = "flexscreen">
<div class = "heading">
<div class = "formtitle">Add a new customer</div>
<div class = "formsubtitle">Fill in the required details.</div>
</div>
<div class = "restarea">
<div class = "useraddform" id = "useraddform">
<mat-horizontal-stepper #stepper linear>
    <mat-step [stepControl]="userDetails">
        <ng-template matStepLabel>Fill customer details</ng-template>
        <form [formGroup]="userDetails" class = "form1">
            <mat-form-field>
            <mat-label>Name of customer</mat-label>
            <input matInput
            formControlName="name" 
            class="form-control"
            #name (keyup)="0" 
            required
            [ngClass]="{ 'is-invalid': submitted && fdetails.name.errors }"
            >
            <mat-error *ngIf="fdetails.name.invalid">Name is required.</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Photo ID of customer</mat-label>
            <input matInput
            formControlName="photoidno" 
            class="form-control"
            #photoidno (keyup)="0" 
            required
            [ngClass]="{ 'is-invalid': submitted && fdetails.photoidno.errors }"
            >
            <mat-error *ngIf="fdetails.photoidno.invalid">Photo ID is required.</mat-error>
          </mat-form-field>
            <mat-form-field>
            <mat-label>Email of customer</mat-label>
            <input matInput
            email
            formControlName="email" 
            class="form-control"
            #email (keyup)="0"
            >
            <mat-error *ngIf="fdetails.email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-form-field>
            <mat-label>Phone of customer</mat-label>
            <input matInput
            formControlName="phone" 
            class="form-control"
            #phone (keyup)="0" 
            required
            [ngClass]="{ 'is-invalid': submitted && fdetails.phone.errors }"
            >
            <mat-error *ngIf="fdetails.phone.invalid">Phone is required.</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Address of customer</mat-label>
            <input matInput
            formControlName="address" 
            class="form-control"
            #phone (keyup)="0" 
            required
            [ngClass]="{ 'is-invalid': submitted && fdetails.address.errors }"
            >
            <mat-error *ngIf="fdetails.address.invalid">Address is required.</mat-error>
          </mat-form-field>

            <mat-form-field>
            <mat-label>Password of customer</mat-label>
            <input matInput
            formControlName="password" 
            class="form-control"
            #password (keyup)="0"
            required minlength="6"
            [ngClass]="{ 'is-invalid': submitted && fdetails.password.errors }"
            >
            <mat-error *ngIf="fdetails.password.invalid">{{getPasswordError()}}</mat-error>
          </mat-form-field>
</form>
        <div>
          <button mat-raised-button color="primary" type="button" class = "xbutton" (click)="goForward(stepper)">Proceed to upload Photo ID</button>
        </div>
    </mat-step>
    <mat-step  label="Upload Photo ID" [stepControl]="userProfile">
  <form [formGroup]="userProfile">
  <input type="hidden" 
                formControlName="photoid" 
                class="form-control"/>
</form>
<div class = "uploader show" id = "uploader">
      <div class = "uploadertitle">Click on button or drag drop image below.</div>
      <input type="file" (change)="uploadFile($event)" accept=".png, .jpg, .jpeg, .gif"/>
  </div>
  </mat-step>
  </mat-horizontal-stepper>
</div>
  <div class = "uploaded" id = "uploaded">
    <div class = "uploadertitle">The user has been added successfully!</div>
    <div class = "roption" [routerLink]="['/users']">View Customers</div>
    <div class = "roption" (click)="stepper.reset()">Add Another Customer</div>
  </div>

  <div class="container" id ="loading">
    <div class="loader">
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--text"></div>
    </div>
</div>
  </div>


  </div>
  </div>
  <ng-template #show>
    <div class = "errorscreen">
    <div class = "errortitle">Unauthorised Access.</div>
    </div>
  </ng-template>


<div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
<div id = "notificationtext"></div>
</div>

