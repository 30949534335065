<div class = "contactscreen">
  <div class = "navaddress">
    <a href = "https://prakahsgold.com.au">Home</a> > <span>Contact</span>
  </div>
    <div class = "restarea">
    <div class = "useraddform" id = "useraddform">
        <div class = "formtitle">Get in touch</div>
    <div class = "infox">127 Walker Street, Dandenong, VIC 3175<br>
      Email: prakahsgold@gmail.com<br>
    Phone: (03) 8772 2557</div>

    <form [formGroup]="messageDetails" class = "form1">
                <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput
                formControlName="name" 
                class="form-control"
                #name (keyup)="0" 
                required
                [ngClass]="{ 'is-invalid': submitted && fdetails.name.errors }"
                >
                <mat-error *ngIf="fdetails.name.invalid">Name is required.</mat-error>
              </mat-form-field>
                <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput
                email
                formControlName="email" 
                class="form-control"
                #email (keyup)="0"
                >
                <mat-error *ngIf="fdetails.email.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
              <mat-form-field>
                <mat-label>Message</mat-label>
                <input matInput
                formControlName="message" 
                class="form-control"
                #message (keyup)="0" 
                required
                [ngClass]="{ 'is-invalid': submitted && fdetails.message.errors }"
                >
                <mat-error *ngIf="fdetails.message.invalid">Address is required.</mat-error>
              </mat-form-field>
              <button mat-raised-button color="primary" type="button" class = "xbutton" (click)="addMessage()">Send message</button>
    </form>
    </div>
      <div class = "uploaded" id = "uploaded">
        <div class = "uploadertitle">The message has been sent successfully!</div>
      </div>
    
      <div class="container" id ="loading">
        <div class="loader">
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--text"></div>
        </div>
    </div>
      </div>
    </div>
    
    
    
    <div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
    <div id = "notificationtext"></div>
    </div>
    <div class = "maps">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.560134693869!2d145.21334681499394!3d-37.9873933797213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad615e3034a70b5%3A0x894e9b5e95fa4d2c!2sPrakahs%20Gold%20Finance!5e0!3m2!1sen!2sin!4v1637671254267!5m2!1sen!2sin" height="450" style="border:0; width:100%;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    <app-footer></app-footer>