import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddadminComponent } from './components/addadmin/addadmin.component';
import { AddpgoldComponent } from './components/addpgold/addpgold.component';
import { AdminComponent } from './components/admin/admin.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { AuthComponent } from './components/auth/auth.component';
import { BuyratesComponent } from './components/buyrates/buyrates.component';
import { CashforgoldComponent } from './components/cashforgold/cashforgold.component';
import { ContactComponent } from './components/contact/contact.component';
import { ControlComponent } from './components/control/control.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EditgoldloansComponent } from './components/editgoldloans/editgoldloans.component';
import { EditloanComponent } from './components/editloan/editloan.component';
import { EditpurchasedComponent } from './components/editpurchased/editpurchased.component';
import { EdituserComponent } from './components/edituser/edituser.component';
import { ExcelComponent } from './components/excel/excel.component';
import { ExcelgoldComponent } from './components/excelgold/excelgold.component';
import { GinitialComponent } from './components/ginitial/ginitial.component';
import { GlpendingComponent } from './components/glpending/glpending.component';
import { GoldbuyersComponent } from './components/goldbuyers/goldbuyers.component';
import { GoldloanComponent } from './components/goldloan/goldloan.component';
import { GoldloandetailsComponent } from './components/goldloandetails/goldloandetails.component';
import { GoldsellComponent } from './components/goldsell/goldsell.component';
import { GoldsignComponent } from './components/goldsign/goldsign.component';
import { HomeComponent } from './components/home/home.component';
import { IduploadComponent } from './components/idupload/idupload.component';
import { InitialComponent } from './components/initial/initial.component';
import { ItemsrevenueComponent } from './components/itemsrevenue/itemsrevenue.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoandetailsComponent } from './components/loandetails/loandetails.component';
import { LoaneditComponent } from './components/loanedit/loanedit.component';
import { LoanratesComponent } from './components/loanrates/loanrates.component';
import { MessagesComponent } from './components/messages/messages.component';
import { NewgoldloanComponent } from './components/newgoldloan/newgoldloan.component';
import { NewloanComponent } from './components/newloan/newloan.component';
import { NewuserComponent } from './components/newuser/newuser.component';
import { OwnersignatureComponent } from './components/ownersignature/ownersignature.component';
import { PaygoldloanComponent } from './components/paygoldloan/paygoldloan.component';
import { PayloanComponent } from './components/payloan/payloan.component';
import { PgoldloanComponent } from './components/pgoldloan/pgoldloan.component';
import { PlpendingComponent } from './components/plpending/plpending.component';
import { PostloginComponent } from './components/postlogin/postlogin.component';
import { PrintComponent } from './components/print/print.component';
import { PrintloanComponent } from './components/printloan/printloan.component';
import { PrintpurchasedComponent } from './components/printpurchased/printpurchased.component';
import { RatesComponent } from './components/rates/rates.component';
import { RedeemsignComponent } from './components/redeemsign/redeemsign.component';
import { RedeemsigngoldComponent } from './components/redeemsigngold/redeemsigngold.component';
import { RevenueComponent } from './components/revenue/revenue.component';
import { RevenuegoldloansComponent } from './components/revenuegoldloans/revenuegoldloans.component';
import { RevenueloansComponent } from './components/revenueloans/revenueloans.component';
import { RgoldComponent } from './components/rgold/rgold.component';
import { RgoldloansComponent } from './components/rgoldloans/rgoldloans.component';
import { RloansComponent } from './components/rloans/rloans.component';
import { SearchComponent } from './components/search/search.component';
import { SelectuserComponent } from './components/selectuser/selectuser.component';
import { SellgoldComponent } from './components/sellgold/sellgold.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TcComponent } from './components/tc/tc.component';
import { TestComponent } from './components/test/test.component';
import { ViewadminsComponent } from './components/viewadmins/viewadmins.component';
import { ViewgoldloansComponent } from './components/viewgoldloans/viewgoldloans.component';
import { ViewloansComponent } from './components/viewloans/viewloans.component';
import { ViewusersComponent } from './components/viewusers/viewusers.component';

const routes: Routes = [
  { path:'', component:LandingComponent },
  { path: 'about', component: HomeComponent },
  { path: 'contact-gold-buyer-melbourne', component: ContactComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'loan-requirements-melbourne', component: PostloginComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'dashboard', component: DashboardComponent },  
  { path: 'admins', component: ViewadminsComponent },
    { path: 'addadmin', component: AddadminComponent },
    { path: 'users', component: ViewusersComponent },
    { path: 'addusers', component: TestComponent },
    { path: 'loans', component: ViewloansComponent },
    { path: 'goldloans', component: ViewgoldloansComponent },
    { path: 'newloan', component: NewloanComponent },
    { path: 'newgoldloan', component: NewgoldloanComponent },
    { path: 'login', component: ControlComponent },
    { path: 'test', component: TestComponent },
    { path: 'sell-gold-rates-melbourne', component: SellgoldComponent },
    { path: 'newuser', component:NewuserComponent },
    { path: 'select', component:SelectuserComponent },
    { path: 'analytics', component:AnalyticsComponent },
    { path: 'editusers', component: EdituserComponent },
    { path: 'editloans', component: EditloanComponent },
    { path: 'editgoldloans', component: EditgoldloansComponent },
    { path: 'settings', component: SettingsComponent },
    { path: 'loanedit', component: LoaneditComponent },
    { path: 'search', component:SearchComponent },
    { path: 'uploadsignedgold/:id', component: InitialComponent },
    { path: 'printsignedgold/:id', component: PrintComponent },
    { path: 'printloan/:id', component: PrintloanComponent },
    { path: 'uploadsignedloan/:id', component: GinitialComponent },
    { path: 'gold', component:EditpurchasedComponent },
    { path: 'editrates', component: RatesComponent },
    { path: 'editbuyrates', component: BuyratesComponent },
    { path: 'payloan', component: PayloanComponent },
    { path: 'paygoldloan', component: PaygoldloanComponent },
    { path: 'addpgold', component: AddpgoldComponent },
    { path: 'revenue', component: RevenueComponent },
    { path: 'loandetails/:id', component: LoandetailsComponent },
    { path: 'goldloandetails/:id', component: GoldloandetailsComponent },
    { path: 'uploadid/:id', component: IduploadComponent },
    { path: 'reportgoldloans', component: RevenuegoldloansComponent },
    { path: 'reportloans', component: RevenueloansComponent },
    { path: 'messages', component:MessagesComponent },
    { path: 'pawnsign', component: OwnersignatureComponent },
    { path: 'gold-buyers-melbourne', component: GoldbuyersComponent },
    { path: 'cash-for-gold-melbourne', component: CashforgoldComponent },
    { path: 'gold-loan-melbourne', component: GoldloanComponent },
    { path: 'sell-gold-melbourne', component: GoldsellComponent },
    { path: 'loan-rates-melbourne', component: LoanratesComponent },
    { path: 'redeemloan/:id', component: RedeemsignComponent },
    { path: 'redeemgold/:id', component: RedeemsigngoldComponent },
    { path: 'revenueitems', component: ItemsrevenueComponent },
    { path: 'tc', component: TcComponent },
    { path: 'excelgold', component: GlpendingComponent },
    { path: 'excel', component: PlpendingComponent }, 
    { path: 'printpurchased/:id', component: PrintpurchasedComponent },
    { path: 'goldsign/:id', component: GoldsignComponent },
    { path: 'rloans', component: RloansComponent },
    { path: 'rgoldloans', component: RgoldloansComponent },
    { path: 'rgold', component: RgoldComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
