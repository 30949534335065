
    <div class = "flexscreen">
        <div class = "pickerarea">
            <div class = "formtitle">View Revenue from Gold loans</div>
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date" (keyup)="0">
              <input matEndDate formControlName="end" placeholder="End date" (keyup)="0">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
          <div class = "buttons">
            <button class = "filterbutton" (click)="filterByDate()">Filter by Date range</button>
        </div>
        </div>
        <div class = "exportbutton" (click)="exportexcel()">
            <mat-icon> cloud_download</mat-icon> <span>Export to Excel</span>
        </div>
        <div class = "xarea">
    <table id="excel-table"> 
    <tr>
        <th>Pawn Ticket</th>
        <th>Date</th>
        <th>Name</th>
        <th>Phone</th>
        <th>Amount</th>
        <th>Rate</th>
        <th>Duration</th>
        <th>Revenue</th>
        <th>Status</th>
    </tr>
    <tr class = "users notpending" *ngFor="let loan of loanarray">
    <ng-container *ngIf = "loan?.id">
    <td>{{loan?.id}}</td>
    <td>{{loan?.date}}.{{loan?.month}}.{{loan?.year}}</td>
    <td>{{loan?.details.name}}</td>
    <td>{{loan?.details.phone}}</td>
        <td>${{loan?.details.principle}}</td>
        <td>{{loan?.details.rate}}</td>
        <td>{{loan?.details.time}} ({{ loan?.details.type }})</td>
    <td>${{loan?.erevenue}}</td>
    <ng-template #def><td>${{ loan?.totalrevenue }}</td></ng-template>
    
        <td *ngIf="loan?.status == 1" class="green">Paid</td>
        <td *ngIf="loan?.status == 0">Pending</td>
        <td *ngIf="loan?.status == 4">Closed</td>
    </ng-container>
    </tr>
    </table>
    </div>
    
    <ng-template #loading>
    <div class="container" id ="loading">
        <div class="loader">
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--text"></div>
        </div>
    </div>
    </ng-template>
    
    </div>