import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import firebase from 'firebase/app';
import { map, mergeMap } from 'rxjs/operators';
import { DateTime } from "luxon";

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.css']
})
export class RevenueComponent implements OnInit {
  
  LoansArray = [];
  WeekLoansArray = [];
  MonthLoansArray = [];
  YearLoansArray = [];

  gLoansArray = [];
  gWeekLoansArray = [];
  gMonthLoansArray = [];
  gYearLoansArray = [];
  count: number;
  now:Date;
  
  //PERSONAL
  //ALL TIME
  totalrevenue: number;
  totaltrevenue: number;
  totalamount: number;
  totalpayments: number;
  //WEEK
  weekrevenue: number;
  weektrevenue: number;
  weekamount: number;
  weekpayments: number;
  //MONTH
  monthrevenue: number;
  monthtrevenue: number;
  monthamount: number;
  monthpayments: number;
  //YEAR
  yearrevenue: number;
  yeartrevenue: number;
  yearamount: number;
  yearpayments: number;

  //GOLD
  //ALL TIME
  gtotalrevenue: number;
  gtotaltrevenue: number;
  gtotalamount: number;
  gtotalpayments: number;
  //WEEK
  gweekrevenue: number;
  gweektrevenue: number;
  gweekamount: number;
  gweekpayments: number;
  //MONTH
  gmonthrevenue: number;
  gmonthtrevenue: number;
  gmonthamount: number;
  gmonthpayments: number;
  //YEAR
  gyearrevenue: number;
  gyeartrevenue: number;
  gyearamount: number;
  gyearpayments: number;


  
  ngOnInit(): void {
    this.getLoans();
    this.getGoldLoans();
    this.getWeekLoans();
    this.getMonthLoans();
    this.getYearLoans();
    this.getGoldWeekLoans();
    this.getGoldMonthLoans();
    this.getGoldYearLoans();
  }
  
  constructor(firestore: AngularFirestore) {
    this.now = DateTime.now();
  } 

  async getLoans() {
    firebase.firestore().collection('loans').get()
      .then(querySnapshot => {
        querySnapshot.docs.forEach(doc => {
        this.LoansArray.push(doc.data());
      });
      var lrevenue = 0;
      var trevenue = 0;
      for(var i = 0; i<this.LoansArray.length; i++) {
        lrevenue = lrevenue + parseInt(this.LoansArray[i].totalrevenue);
        trevenue = trevenue + parseInt(this.LoansArray[i].details.transactionfee);
      }
      this.totalrevenue = Math.round((lrevenue + Number.EPSILON) * 100) / 100;
      this.totaltrevenue = Math.round((trevenue + Number.EPSILON) * 100) / 100;
    });
  }

  async getWeekLoans() {
    var dat = DateTime.now();
    var weekago = dat.minus({ weeks: 1 });
    var weekObject = new Date(weekago);
   
    var citiesRef = firebase.firestore().collection("loans");
    var query = citiesRef.where("details.date", ">=", weekObject);
    query.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
      this.WeekLoansArray.push(doc.data());
    });
    var wrevenue = 0;
    var wtrevenue = 0;
      for(var i = 0; i<this.WeekLoansArray.length; i++) {
        wrevenue = wrevenue + parseInt(this.WeekLoansArray[i].totalrevenue);
        wtrevenue = wtrevenue + parseInt(this.WeekLoansArray[i].details.transactionfee);
      }
      this.weekrevenue = Math.round((wrevenue + Number.EPSILON) * 100) / 100;
      this.weektrevenue = Math.round((wtrevenue + Number.EPSILON) * 100) / 100;
    });
  }

  async getMonthLoans() {
    var dat = DateTime.now();
    var monthago = dat.minus({ months: 1 });
    var monthObject = new Date(monthago);

    var citiesRef = firebase.firestore().collection("loans");
    var query = citiesRef.where("details.date", ">=", monthObject);
    query.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
      this.MonthLoansArray.push(doc.data());
    });
    var mrevenue = 0;
    var mtrevenue = 0;
      for(var i = 0; i<this.MonthLoansArray.length; i++) {
        mrevenue = mrevenue + parseInt(this.MonthLoansArray[i].totalrevenue);
        mtrevenue = mtrevenue + parseInt(this.MonthLoansArray[i].details.transactionfee);
      }
      this.monthrevenue = Math.round((mrevenue + Number.EPSILON) * 100) / 100;
      this.monthtrevenue = Math.round((mtrevenue + Number.EPSILON) * 100) / 100;
    });
  }

  async getYearLoans() {
    var dat = DateTime.now();
    var yearago = dat.minus({ years: 1 });
    var yearObject = new Date(yearago);

    var citiesRef = firebase.firestore().collection("loans");
    var query = citiesRef.where("details.date", ">=", yearObject);
    query.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
      this.YearLoansArray.push(doc.data());
    });
    var yrevenue = 0;
    var ytrevenue = 0;
      for(var i = 0; i<this.YearLoansArray.length; i++) {
        yrevenue = yrevenue + parseInt(this.YearLoansArray[i].totalrevenue);
        ytrevenue = ytrevenue + parseInt(this.YearLoansArray[i].details.transactionfee);
      }
      this.yearrevenue = Math.round((yrevenue + Number.EPSILON) * 100) / 100;
      this.yeartrevenue = Math.round((ytrevenue + Number.EPSILON) * 100) / 100;
    });
  }

  async getGoldLoans() {
    firebase.firestore().collection('goldloans').get()
      .then(querySnapshot => {
        querySnapshot.docs.forEach(doc => {
        this.gLoansArray.push(doc.data());
      });
      var grevenue = 0;
      var gtrevenue = 0;
      for(var j = 0; j<this.gLoansArray.length; j++) {
        grevenue = grevenue + parseInt(this.gLoansArray[j].totalrevenue);
        gtrevenue = gtrevenue + parseInt(this.gLoansArray[j].details.transactionfee);
      }
      this.gtotalrevenue = Math.round((grevenue + Number.EPSILON) * 100) / 100;
      this.gtotaltrevenue = Math.round((gtrevenue + Number.EPSILON) * 100) / 100;
    });
  }

  async getGoldWeekLoans() {
    var dat = DateTime.now();
    var weekago = dat.minus({ days: 7 });
    var weekObject = new Date(weekago);
    var gloansRef = firebase.firestore().collection("goldloans");
    var weekquery = gloansRef.where("details.date", ">=", weekObject);
    weekquery.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
      this.gWeekLoansArray.push(doc.data());
    });
    var gwrevenue = 0;
    var gwtrevenue = 0;
      for(var k = 0; k<this.gWeekLoansArray.length; k++) {
        gwrevenue = gwrevenue + parseInt(this.gWeekLoansArray[k].totalrevenue);
        gwtrevenue = gwtrevenue + parseInt(this.gWeekLoansArray[k].details.transactionfee);
      }
      this.gweekrevenue = Math.round((gwrevenue + Number.EPSILON) * 100) / 100;
      this.gweektrevenue = Math.round((gwtrevenue + Number.EPSILON) * 100) / 100;
    });
  }

  async getGoldMonthLoans() {
    var dat = DateTime.now();
    var monthago = dat.minus({ months: 1 });
    var monthObject = new Date(monthago);

    var citiesRef = firebase.firestore().collection("goldloans");
    var query = citiesRef.where("details.date", ">=", monthObject);
    query.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
      this.gMonthLoansArray.push(doc.data());
    });
    var mrevenue = 0;
    var gmtrevenue = 0;
      for(var i = 0; i<this.gMonthLoansArray.length; i++) {
        mrevenue = mrevenue + parseInt(this.gMonthLoansArray[i].totalrevenue);
        gmtrevenue = gmtrevenue + parseInt(this.gMonthLoansArray[i].details.transactionfee);
      }
      this.gmonthrevenue = Math.round((mrevenue + Number.EPSILON) * 100) / 100;
      this.gmonthtrevenue = Math.round((gmtrevenue + Number.EPSILON) * 100) / 100;
    });
  }

  async getGoldYearLoans() {
    var dat = DateTime.now();
    var yearago = dat.minus({ years: 1 });
    var yearObject = new Date(yearago);

    var citiesRef = firebase.firestore().collection("goldloans");
    var query = citiesRef.where("details.date", ">=", yearObject);
    query.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
      this.gYearLoansArray.push(doc.data());
    });
    var yrevenue = 0;
    var ytrevenue = 0;
      for(var i = 0; i<this.gYearLoansArray.length; i++) {
        yrevenue = yrevenue + parseInt(this.gYearLoansArray[i].totalrevenue);
        ytrevenue = ytrevenue + parseInt(this.gYearLoansArray[i].details.transactionfee);
      }
      this.gyearrevenue = Math.round((yrevenue + Number.EPSILON) * 100) / 100;
      this.gyeartrevenue = Math.round((ytrevenue + Number.EPSILON) * 100) / 100;
    });
  }

  

}

