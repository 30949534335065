import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  users$: Observable<any[]>;
  filteredUsers$: Observable<any[]>;

  constructor(private firestore: AngularFirestore,
    public auth: AngularFireAuth) {
    this.users$ = firestore.collection('messages', ref => ref.orderBy('id','desc')).valueChanges({ idField: 'id' });
    }

  ngOnInit() {
    this.filteredUsers$ = this.users$;
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value:string) {
    if(value) {

      
      this.filteredUsers$ = this.users$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((users: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return users.filter((user: any) => user.details.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
        })
       
      )
  } else {
    // reload the full data set
    this.filteredUsers$ = this.users$;
  }
}

selectUser(email) {
  alert(email);
}

}
