import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx'; 
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";

@Component({
  selector: 'app-excelgold',
  templateUrl: './excelgold.component.html',
  styleUrls: ['./excelgold.component.css']
})
export class ExcelgoldComponent implements OnInit {

  fileName= 'PendingGoldLoans.xlsx'; 
  loans: Array<any>;
  loans$: Observable<any[]>;
  filteredLoans$: Observable<any[]>;
  math = Math;
  parsefloat = parseFloat;
  now:any;

  constructor(private firestore: AngularFirestore,
    public auth: AngularFireAuth) {
    this.loans$ = firestore.collection('goldloans', ref => ref.where('status', '==', 0)).valueChanges({ idField: 'id' });
    }

  ngOnInit() {
    this.filteredLoans$ = this.loans$;
    this.now = DateTime.now().toString();
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value:string) {
    if(value) {

      
      this.filteredLoans$ = this.loans$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((loans: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return loans.filter((loan: any) => loan.id.toLowerCase().indexOf(value.toLowerCase()) > -1)
        })
       
      )
  } else {
    // reload the full data set
    this.filteredLoans$ = this.loans$;
  }
}

selectUser(email) {
  alert(email);
}

showPending(event, date, status) {
  var pendingloan = document.getElementById(event);
  if(this.now > date && status !== 'Paid') {

    

  pendingloan.classList.remove("notpending");
}
}

exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
			
    }
}