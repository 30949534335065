<div class = "egrid">
<div class = "esection" id = "e2">
<div class = "ehead">Personal Loans</div>
<div class = "esubhead">All Time</div>
<div class = "elabel">Total Revenue : ${{ totalrevenue }}</div>
<div class = "elabel">Total Transaction Fee Revenue : ${{ totaltrevenue }}</div>
<div class = "esubhead">Last 7 Days</div>
<div class = "elabel">Total Revenue : ${{ weekrevenue }}</div>
<div class = "elabel">Total Transaction Fee Revenue : ${{ weektrevenue }}</div>
<div class = "esubhead">Last 30 Days</div>
<div class = "elabel">Total Revenue : ${{ monthrevenue }}</div>
<div class = "elabel">Total Transaction Fee Revenue : ${{ monthtrevenue }}</div>
<div class = "esubhead">Last 365 Days</div>
<div class = "elabel">Total Revenue : ${{ yearrevenue }}</div>
<div class = "elabel">Total Transaction Fee Revenue : ${{ yeartrevenue }}</div>
</div>
<div class = "esection" id = "e1">
    <div class = "ehead">Gold Loans</div>
    <div class = "esubhead">All Time</div>
    <div class = "elabel">Total Revenue : ${{ gtotalrevenue }}</div>
    <div class = "elabel">Total Transaction Fee Revenue : ${{ gtotaltrevenue }}</div>
    <div class = "esubhead">Last 7 Days</div>
    <div class = "elabel">Total Revenue : ${{ gweekrevenue }}</div>
    <div class = "elabel">Total Transaction Fee Revenue : ${{ gweektrevenue }}</div>
    <div class = "esubhead">Last 30 Days</div>
    <div class = "elabel">Total Revenue : ${{ gmonthrevenue }}</div>
    <div class = "elabel">Total Transaction Fee Revenue : ${{ gmonthtrevenue }}</div>
    <div class = "esubhead">Last 365 Days</div>
    <div class = "elabel">Total Revenue : ${{ gyearrevenue }}</div>
    <div class = "elabel">Total Transaction Fee Revenue : ${{ gyeartrevenue }}</div>
</div>
</div>