
    <div class = "navaddress">
        <a href = "https://prakahsgold.com.au">Home</a> > <span>Loan Rates</span>
      </div>
<div class = "xresponsive">
  <h1>Get instant loans at the best rates in Melbourne</h1>
  <p class = "centered">We are transparent with our loan rates/fees</p>

  <div class="rates">
      <div class="ratesleft">
      <div class = "rtitle">Loan Amount</div>
  <div>$100 +</div>
  <div>$2000 +</div>
  <div>$5000 +</div>
  <div>$10,000 +</div>
  <div>$20,000 +</div>
      </div>
      <div class="ratesright" *ngFor="let loan of loans$ | async">
        <div class = "rtitle">7 day rates</div>
    <div>{{ loan.f }}</div>
    <div>{{ loan.g }}</div>
    <div>{{ loan.h }}</div>
    <div>{{ loan.i }}</div>
    <div>{{ loan.j }}</div>
        </div>
      <div class="ratesright" *ngFor="let loan of loans$ | async">
      <div class = "rtitle">30 day rates</div>
  <div>{{ loan.a }}</div>
  <div>{{ loan.b }}</div>
  <div>{{ loan.c }}</div>
  <div>{{ loan.d }}</div>
  <div>{{ loan.e }}</div>
      </div>
    </div>
  
  <p>We provide our interest rates upfront for a 30 day
  period and these rates are offered with a one-off $10
  transaction fee. If you want to keep the loan for
  more than 30 day just pay the next months interest.</p>
  
  <div class="buttonareax">
      <a class="buttonx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
      <a class="buttonx"
        href="https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit
        Store</a>
    </div>
  
  </div>
  <div class = "maps">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.560134693869!2d145.21334681499394!3d-37.9873933797213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad615e3034a70b5%3A0x894e9b5e95fa4d2c!2sPrakahs%20Gold%20Finance!5e0!3m2!1sen!2sin!4v1637671254267!5m2!1sen!2sin" height="450" style="border:0; width:100%;" allowfullscreen="" loading="lazy"></iframe>
  </div>
<app-footer></app-footer>