<div *ngIf="auth.user | async as user; else show">
    <div class = "flexscreen">
      <div class = "heading">
        <div class = "formtitle">View Gold loans</div>
        <div class = "formsubtitle">Search database for gold loans</div>
      </div>
    
      <input class="search-box" placeholder="Search using pawn ticket" (keyup)=search($event.target.value) type="text">
            <div class = "users" *ngFor="let loan of filteredLoanarray">
            <div class = "user">
              <div class = "green"></div>
            <div class = "red" *ngIf = "loan.status == 4"></div>
            
            <div class = "loantitle">PAWN TICKET #{{loan?.id}}</div>
            <div class = "userlabel">Type : {{loan?.details.type}}</div>
            <div class = "userlabel">Date : {{loan?.details.date.toDate() | date:'dd/MM/yyyy'}}</div>
            <div class = "userlabel">Customer Name : {{loan?.details.name}}</div>
            <div class = "userlabel">Customer Address : {{loan?.details.address}}</div>
            <div class = "userlabel">Customer Phone : {{loan?.details.phone}}</div>
            <div class = "userlabel">Customer Email : {{loan?.details.email}}</div>
            <div class = "userlabel">Transaction Fee : ${{ loan?.details.transactionfee }}</div>
            <div class = "userlabel">Loan Amount : ${{loan?.details.principle}}</div>
            <div class = "userlabel">Rate of Interest % : {{loan?.details.rate}}</div>
            <div class = "userlabel">Duration : {{loan?.details.time}}</div>
            <div class = "userlabel">Total Interest : ${{ loan?.details.totalinterest }}</div>
            <div class="userlabel">{{ loan?.details.type }} Interest : ${{
              loan?.details.totalinterest/loan?.details.time | number: '1.0-2' }}</div>



              <div class = "userlabel">
              <strong>Calculated Interest</strong><br>
                Duration : {{ loan?.details.time }}
                <ng-container *ngIf="loan.details.type == 'Monthly'">months</ng-container>
                <ng-container *ngIf="loan.details.type == 'Weekly'">weeks</ng-container>
                <ng-container *ngIf="loan.details.type == 'Fortnightly'">fortnights</ng-container>
                <br>
                Interest : ${{ loan.details.totalinterest }}<br>
                Remaining : ${{ loan.details.totalinterest - loan.erevenue }}<br>
              </div>
                <div class = "userlabel">
                <strong>Actual Interest till today</strong><br>

                Duration : {{loan?.ctime }}
                <ng-container *ngIf="loan.details.type == 'Monthly'">months</ng-container>
                <ng-container *ngIf="loan.details.type == 'Weekly'">weeks</ng-container>
                <ng-container *ngIf="loan.details.type == 'Fortnightly'">fortnights</ng-container>
                <br>
                Interest : ${{ loan?.cinterest }}<br>
                Remaining : ${{ loan.cinterest - loan.erevenue }}
                <br>
                <span class = "red" *ngIf="loan?.ctime > loan?.ftime">Overdue by {{ loan?.ctime - loan?.ftime }}
                  <ng-container *ngIf="loan.details.type == 'Monthly'">month(s)</ng-container>
                  <ng-container *ngIf="loan.details.type == 'Weekly'">week(s)</ng-container>
                  <ng-container *ngIf="loan.details.type == 'Fortnightly'">fortnight(s)</ng-container>
                </span>
              </div>

              <div class = "userlabel"><strong>Remaining loan : ${{loan?.remainingloan}}</strong></div>


              <div class = "userlabel" *ngIf = "loan.comment">Comments : {{ loan?.comment }}</div>
            <div class = "gitemholder">
            <div *ngFor = "let gdoc of loan?.items | keyvalue: undefined; let ix = index">
            <div class = "itemc">
            <div class = "itemp">
            <img src = "{{ loan?.urls[ix] }}"/>
            </div>
            <div class = "xlabel">Gold Item {{ ix+1 }}</div>
            <div class = "xlabel">Type : {{ gdoc?.value.type }}</div>
            <div class = "xlabel">Weight : {{ gdoc?.value.weight }}</div>
            <div class = "xlabel">Carats : {{ gdoc?.value.carats }}</div>
            <div class = "xlabel">Additional info : {{ gdoc?.value.additional }}</div>
            <div class = "xlabel" *ngIf = "gdoc?.value.status">Status : {{ gdoc?.value.status }}</div>
            </div>
            </div>
            </div>



<ng-container *ngFor = "let pdoc of loan?.pendingpayments | keyvalue: undefined; let px = index"> 
    <div class = "orange" *ngIf = "pdoc.value.status == 'Pending'"></div>
</ng-container>

            <div class = "editbuttons">
            <a class = "editbutton" *ngIf = "loan.signed == 'yes'"
            href = "{{ loan.signedurl }}">Print Pawn Ticket</a>

            <div class = "editbutton" *ngIf = "loan.digitallysigned == 'yes'"
            [routerLink]="['/printsignedgold', loan.id ]">Print Pawn Ticket</div>


            <div class = "editbutton" *ngIf = "loan.signed !== 'yes' && loan.digitallysigned !== 'yes'"
            [routerLink]="['/uploadsignedgold', loan.id ]">Add Signature</div>
            
            <div class = "editbutton" *ngIf = "loan.signed == 'yes' || loan.digitallysigned == 'yes'"
            [routerLink]="['/uploadsignedgold', loan.id ]">Edit Signature</div>

            

            <div class = "editbutton" *ngIf = "loan.redeemsign == 'yes'"
            [routerLink]="['/redeemgold', loan.id]">Edit Redemption Signature</div>

            <div class = "editbutton" *ngIf = "loan.redeemsign !== 'yes'"
            [routerLink]="['/redeemgold', loan.id]">Redemption Signature</div>

            <div class = "editbutton" [routerLink]="['/goldloandetails', loan.id]">View Full Details</div>
            </div>
      </div>
    </div>
    </div>
    </div>
    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>