import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
  imgu: String;
  math = Math;
  itemurls = [];
  users$: Observable<any[]>;
  filteredUsers$: Observable<any[]>;
  selectedemail: string;
  submitted = false;
  loanDetails: FormGroup;
  loanItems: FormGroup;
  loanHidden: FormGroup;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private firestore: AngularFirestore,
    private _formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    public auth: AngularFireAuth) {
    this.users$ = firestore.collection('users').valueChanges();
    }

  ngOnInit() {
    this.filteredUsers$ = this.users$;

    this.loanDetails = this._formBuilder.group({
      selectedemail: [''],
      loanid: ['', Validators.required],
      date: ['', Validators.required],
      principle: ['', Validators.required],
      rate: ['', Validators.required],
      time: ['', Validators.required]
  });
  this.loanItems = this._formBuilder.group({
    golditems: this._formBuilder.array([]),
  });

  this.loanHidden = this._formBuilder.group({
    loanuser: ['', Validators.required]
  });
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value:string) {
    if(value) {

      
      this.filteredUsers$ = this.users$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((users: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return users.filter((user: any) => user.details.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
        })
       
      )
  } else {
    // reload the full data set
    this.filteredUsers$ = this.users$;
  }
}

selectUser(email) {
  alert(email);
}

  goForward(loanemail, stepper: MatStepper){
        this.selectedemail = loanemail;
        this.loanHidden.controls['loanuser'].setValue(this.selectedemail);
        this.loanDetails.controls['selectedemail'].setValue(this.selectedemail);
        stepper.next();
    }
    get fdetails() { return this.loanDetails.controls; }
    get fitems() { return this.loanItems.controls; }

  
    addLoan(stepper: MatStepper) {
        this.submitted = true;
        this.loanDetails.markAllAsTouched();
        this.loanItems.markAllAsTouched();
        if ((this.loanDetails.invalid) || (this.loanItems.invalid)) {
          this.notificationPop("Check credentials");
            return;
        }
          stepper.next();
          this.createLoan();      
    }
  

    
    onReset() {
        this.submitted = false;
        this.loanDetails.reset();
        this.loanItems.reset();
    }
notificationHide() {
document.getElementById('notification').classList.remove('pop');
}

notificationDisplay() {
document.getElementById('notification').classList.add('pop');
}

notificationPop(message: string) {
  document.getElementById('notificationtext').innerHTML = message;
  this.notificationDisplay();
  setTimeout(this.notificationHide, 2500);
}

createLoan() {
  var currentDate = new Date();
            var cmonth = currentDate.getMonth();
            var dateadded = currentDate.getDate();
            var yearadded = currentDate.getFullYear();
            var monthadded = cmonth + 1;
  let goldloan = [
    { details: this.loanDetails.value }, 
    { items: this.loanItems.value },
    { urls: this.itemurls },
    { date: dateadded, month: monthadded, year: yearadded }
    ],
    object = Object.assign({}, ...goldloan);
      this.firestore.collection(`goldloans`).add(object);
      this.notificationPop("New loan added successfully!");
    }

  fakeArray(length: number): Array<any> {
      if (length >= 0) {
        return new Array(length);
      }
    }

  addCreds() {
    const creds = this.loanItems.controls.golditems as FormArray;
    creds.push(this._formBuilder.group({
      type: '',
      weight: '',
      additional: '',
      diamond: '',
      carats: ''
    }));
  }

  trackByFn(index: any, item: any) {
    return index;
 }

  uploadFile(event, itemindex) {
    const file = event.target.files[0];
    const filePath = 'goldloans/'+this.loanDetails.controls['loanid'].value+'golditem'+itemindex;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(downloadURLResponse => { 
            this.imgu = downloadURLResponse;
            this.itemurls[itemindex]=this.imgu;
          });
         }),
     )
    .subscribe()
  }

}
