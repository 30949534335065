<div id="parallax">
  <div class="navaddress">
    <a href="https://prakahsgold.com.au">Home</a> > <span>About Us</span>
</div>

  <section>
    <div class="block">
      <div class="title">
        <h3>Our Services</h3>
      </div>
      <p>- Cash loan for Gold.<br>
        - Competitive interest rates.<br>
        - Short term and long term loans.<br>
        - We give instant cash.<br>
        - No appointments required.<br>
        - No credit checks.<br><br>
        We buy &amp; loan against : <br><br>- 9ct - 24ct gold jewellery.<br>
        - Gold coins.<br>
        - Gold bullions.<br>
        - Diamond and Diamond rings<br>
        - Silver<br>
        - Scrap gold.<br><br>
        Getting a loan is as simple as : <br><br>- Visiting us in store at Dandenong.<br>
        - We will assess and value your gold items with you.<br>
        - We will pay you cash on the spot.
      </p>
      <div class="buttons">
        <a class="navx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
        <a class="navx"
          href="https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit
          Store</a>
      </div>
    </div>
  </section>

  <section>
    <div class="title">
      <h3>About Prakahs Gold</h3>
    </div>
  </section>

  <section id="cashforgold">
    <div class="parallax-one">
      <h2>Cash For Gold</h2>
    </div>
  </section>

  <section>
    <div class="block">

      <div class="thumb">
        <picture>
          <source srcset="../../../assets/money1.webp" type="image/webp">
          <source srcset="../../../assets/money1.png" type="image/png"> 
          <img src="../../../assets/money1.png" alt="sell gold for cash melbourne" loading="lazy">
        </picture>

      </div>
      <div class="title">
        <h3>It’s Easy to Sell Gold For Cash!</h3>
      </div>
      <p>So you need cash and you need it quickly? You may be able to solve this problem, with a little help
        from us. Did your ex leave you with a bunch of tacky gold jewelry you can't stand to look at? Did you get a coin
        collection in a storage unit auction, but have no clue what to do with it? Did you inherit some bars of gold
        that are making you uneasy? Don't use them as gaudy paperweights! Prakahs Gold Finance can give you Cash For
        Gold! Come to us and receive instant cash for your unwanted gold at competitive rates! We loan on 9 to 24 karat
        gold jewelry, gold coins and gold bullions. Most gold pieces will have a number followed with a k to clue you in
        what percentage of the gold is pure. If that number is between 9 and 24, bring it to us and we'll talk. Maybe
        you're looking for a loan or maybe you just want to sell the gold outright. We can negotiate. Come to us and
        we'll give you a fair deal.
      </p>
      <div class="buttons">
        <a class="navx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
        <a class="navx"
          href="https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit
          Store</a>
      </div>
    </div>
  </section>

  <section id="cashloansforgold">
    <div class="parallax-two">
      <h2>Cash Loans For Gold</h2>
    </div>
  </section>

  <section>
    <div class="block">

      <div class="thumb">
        <picture>
          <source srcset="../../../assets/money2.webp" type="image/webp">
          <source srcset="../../../assets/money2.png" type="image/png"> 
          <img src="../../../assets/money2.png" alt="cash loans for gold melbourne" loading="lazy">
        </picture>

      </div>
      <div class="title">
        <h3>Get a Cash Loan For Gold Jewellery!</h3>
      </div>
      <p>
        You can get cash loans for gold! Our interest rates are paid up front for a thirty day period. What's more,
        these rates are provided with a one-off $10 transaction fee. Should you desire to keep said loan for more than
        thirty days just pay the next month's interest. It's no trouble at all! This is a great way to get rid of some
        clutter while making money.

        At just 5% interest at a monthly rate, you can turn that unwanted gold jewelry into cash you can use to get
        something you actually want! You told your ex you wanted a laptop, they gave you a gold necklace with their name
        on it. You can still get what you want! Gran called it vintage, you call it tacky and out of style, we call it
        good as cash! Did the catch break on an old gold bracelet? Did the engagement fall through but the sucker let
        you keep the ring? Did someone give you gold hoops, forgetting that you don't have any piercings? Did you find
        something on the beach that isn't quite your size or style? Did your watch take a licking and stop ticking?
        Well, there's still something you can do with it!</p>
      <div class="buttons">
        <a class="navx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
        <a class="navx"
          href="https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit
          Store</a>
      </div>
    </div>
  </section>
  <section class="central">
    <div class="title">
      <h3>WHAT YOU NEED TO BRING TO GET A LOAN TODAY?</h3>
    </div>
    <a class="navx" href="https://prakahsgoldx.web.app/loan-requirements-melbourne">Click here to find out</a>
  </section>


  <section id="cashloansforjewellery">
    <div class="parallax-three">
      <h2>Best Place To Sell Gold</h2>
    </div>
  </section>

  <section>
    <div class="block">
      <div class="thumb">
        <picture>
          <source srcset="../../../assets/gold1.webp" type="image/webp">
          <source srcset="../../../assets/gold1.png" type="image/png"> 
          <img src="../../../assets/gold1.png" alt="sell gold near me melbourne" loading="lazy">
        </picture>
      </div>
      <div class="title">
        <h3>Where to Sell Gold Near Me</h3>
      </div>
      <p>We are the best place to sell gold! No appointment is required. Just step into our Dandenong office Monday
        through Sunday. Just bring in your unwanted gold and some valid ID. Sorting it out first is helpful but not
        entirely necessary. Let us weigh and appraise your unwanted gold. You will walk out with cash that day!<br><br>
        Business is booming here in Dandenong, Victoria! People of all sorts live here and we're proud of it! This place
        is getting spruced up, welcoming families, singles, students and professionals. Of course, that means some
        prices might be going up. It's a nice, quiet little suburb that's improving every day. There aren't a lot of
        clubs here to wear your bling in anyway. So, if you got some gold just sitting around, bring it to us at 127
        Walker Street and we'll be glad to take it off your hands and let you walk out with cash so you can get what you
        really want.</p>
      <div class="buttons">
        <a class="navx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
        <a class="navx"
          href="https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit
          Store</a>
      </div>
    </div>
  </section>

  <section>
    <div class="parallax-four">
      <h2>Gold Pawn Shop</h2>
    </div>
  </section>

  <section>
    <div class="block">
      <div class="thumb">
        <picture>
          <source srcset="../../../assets/gold2.webp" type="image/webp">
          <source srcset="../../../assets/gold2.png" type="image/png"> 
          <img src="../../../assets/gold2.png" alt="place to sell gold pawn melbourne" loading="lazy">
        </picture>
      </div>
      <div class="title">
        <h3>The Best Place to Sell Gold Is a Local Gold Pawn Shop!</h3>
      </div>
      <p>Yes, we are a gold pawn shop. But we are not an ordinary pawn shop! We come highly recommended; offering great
        service with the professionalism you deserve. Our interest rate will not change! We understand that more than
        seventy percent of the residents of Greater Dandenong speak a language other than English. That's the largest
        proportion in all of Victoria! We pride ourselves on being multilingual so we can help anyone get rid of their
        unwanted gold.<br><br>
        You can type "Gold Buyers Near Me" into your search engine and you will find us! Dandenong is undergoing urban
        renewal which means businesses are going to be more competitive. We are ready for competition! We at Prakahs
        understand that and will do our best to give you the very best service we can! We take care of our customers.
        We've been in Dandenong for the past five years and hope to do so for many more. It's not so close to Melbourne
        as to be crowded but not so far away as to be deserted either. And the people who are here are just the best!
        Come see for yourself! Let us take care of you.<br><br></p>
      <div class="buttons">
        <a class="navx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
        <a class="navx"
          href="https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit
          Store</a>
      </div>
    </div>
  </section>
  <section class="central">
    <div class="title">
      <h3>WHAT YOU NEED TO BRING TO GET A LOAN TODAY?</h3>
    </div>
    <a class="navx" [routerLink]="['/loan-requirements-melbourne']">Click here to find out</a>
  </section>
</div>


<app-footer></app-footer>