import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import { Router} from "@angular/router";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  logger:string=sessionStorage.getItem("prakahsuser");
  userData:any;
  constructor ( 
    public afs: AngularFirestore,
     private router: Router,
      public auth: AngularFireAuth
      ) {
    this.auth.authState.subscribe(this.firebaseAuthChangeListener);
}
private firebaseAuthChangeListener = (user) => {
  if (user) {
    console.log('Logged in :)');
  } else {
    console.log('Logged out :(');
  }
}

uiShownCallback() {
console.log('uiShown');
}

ngOnInit(): void {
}

async signout() {
  await this.auth
    .signOut()
    .then(() => this.router.navigate(['']))
    .catch((error) => {
      console.log(error);
      return;
    });
}
toggleNavMenu() {
  var body = document.querySelector('body');
  body.classList.remove('nav-active');
}
logout() {
  sessionStorage.removeItem('prakahsuser');
  window.location.reload();
}
}