import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DateTime } from "luxon";
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';
import { map, filter } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-revenuegoldloans',
  templateUrl: './revenuegoldloans.component.html',
  styleUrls: ['./revenuegoldloans.component.css']
})

export class RevenuegoldloansComponent implements OnInit {

  fileName = 'Report_GoldLoans.xlsx';
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  now: any;
  loanarray: any;

  constructor(private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private excelService: ExcelService) {
  }

  ngOnInit(): void {
    this.now = DateTime.now();
    this.loanarray = [];
  }

  filterByDate() {
    const queryObservable = this.afs.collection('goldloans', ref => ref.orderBy('id', 'desc')).valueChanges();
    queryObservable.subscribe(queriedItems => {
      for (let [index, loan] of queriedItems.entries()) {
        const [cchecker, crevenue, cloanreturned] = this.filterLoans(loan);
        if (cchecker == true) {
          this.loanarray[index] = loan;
          this.loanarray[index].erevenue = crevenue;
          this.loanarray[index].eloanreturned = cloanreturned;
        }
      }
    });
  }

  filterLoans(loan) {
    var revenue: number = 0;
    var loanreturned: number = 0;
    var checker = false;
    var loandate = loan.details.date.toDate();

    if((loandate >= this.range.controls['start'].value) && (loandate <= this.range.controls['end'].value)) {
      checker = true;
      if (loan.fullpaymentloanpaid) {
        revenue = revenue + loan.fullpaymentloanpaid;
        loanreturned = loan.details.principle;
      }
      else if (loan.loancloseinterestpaid) {
        revenue = revenue + loan.loancloseinterestpaid;
        loanreturned = loanreturned + parseInt(loan.loanclosefinalpaid);
      }
      else {
        for (var looper = 0; looper < loan.details.time; looper++) {
          var datetobechecked = DateTime.fromISO(loan.pendingpayments[looper].date);
          if (datetobechecked >= this.range.controls['start'].value && datetobechecked <= this.range.controls['end'].value) {
            revenue = revenue + parseInt(loan.pendingpayments[looper].interestreceived);
            if(loan.pendingpayments[looper].loanreturned) {
            loanreturned = loanreturned + parseInt(loan.pendingpayments[looper].loanreturned);
            }
          }
        }
      }
    }
    else if (loandate < this.range.controls['start'].value || loandate > this.range.controls['end'].value) {
      checker = false;
    }
    return [checker, revenue, loanreturned];
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    this.excelService.exportAsExcelFile(wb, 'RevenueGoldLoans');
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}