import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { map } from 'rxjs/operators';
import Admin from 'src/app/models/admin.model';

@Component({
  selector: 'app-viewadmins',
  templateUrl: './viewadmins.component.html',
  styleUrls: ['./viewadmins.component.css']
})
export class ViewadminsComponent implements OnInit {

  admins?: Admin[];
  currentAdmin?: Admin;
  currentIndex = -1;
  title = '';

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.retrieveAdmins();
  }

  refreshList(): void {
    this.currentAdmin = undefined;
    this.currentIndex = -1;
    this.retrieveAdmins();
  }

  retrieveAdmins(): void {
    this.adminService.getAll().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(data => {
      this.admins = data;
    });
  }

  setActiveAdmin(admin: Admin, index: number): void {
    this.currentAdmin = admin;
    this.currentIndex = index;
  }

}

