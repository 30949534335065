import { Component, OnInit } from '@angular/core';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-cashforgold',
  templateUrl: './cashforgold.component.html',
  styleUrls: ['./cashforgold.component.css']
})
export class CashforgoldComponent implements OnInit {

  constructor(
    private adminflagService: AdminflagService,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService
  ) { 
    this.adminflagService.setAdminFlag('coco');
    this.meta.addTags([
      { name: 'description', content: 'Do you need money urgently? Consider obtaining cash for gold in Melbourne when you sell precious jewellery or coins. Here’s how you start the process.' },
      { name: 'author', content: 'Prakahs Gold Finance' },
      { name: 'keywords', content: 'cash for gold melbourne, cash for gold, cash for gold and silver, cash for gold coins, cash for your gold, cash loans for gold, cash your gold' }
    ]);
    this.setTitle('Cash for Gold Melbourne | Prakahs Gold Finance');
    this.createLinkForCanonicalURL();
  }

  ngOnInit(): void {
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 