<div *ngIf="auth.user | async as user; else show">
<div class = "xscreen">
        <div class = "formcontainer">
            <div class = "page1" id = "page1">
            <div class = "formtitle">Add a new user</div>
            <div class = "formsubtitle">Fill in the required details.</div>


        <form [formGroup]="newuserForm">
        <div class="form-group col-5">
            <input type="text" 
            formControlName="name" 
            class="form-control"
            placeholder="Name"
            #name (keyup)="0" 
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
        </div>

        <div class="form-group col-5">
            <input type="text" 
            formControlName="email" 
            class="form-control"
            placeholder="Email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
        </div>

        <div class="form-group col-5">
            <input type="text" 
            formControlName="phone" 
            class="form-control"
            placeholder="Phone"
            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
            <div *ngIf="f.phone.errors.required">Phone is required</div>
            </div>
        </div>

        <div class="form-group col-5">
            <input type="text" 
            formControlName="password" 
            class="form-control"
            placeholder="Password"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
        </div>

        
                    <div class="text-center">
                        <div class="xbutton" (click)="changeView()">Proceed to upload Photo ID</div>
                    </div>
                </form>
        </div>

    <div class="uploader" id = "uploader">
        <div class = "formtitle">Upload Photo ID</div>
        <label for="file">Upload Photo ID by dragging it below</label>
        <input type="file" (change)="uploadFile($event)" id = "file" accept=".png, .jpg, .jpeg, .gif"/>
        <div *ngIf="uploadPercent | async" class = "loader">{{ uploadPercent | async }}% uploaded.</div>
        <div class = "xbutton" (click) = "createUser()">Finish adding user</div>
      </div>

<div class = "lastpage" id = "lastpage">
    <div class = "formtitle">Added successfully!</div>
    <div class = "formsubtitle">Would you like to</div>
    <div class = "xbutton" href="prakahsgoldx.web.app/addusers">Add more users</div>
    <div class = "xbutton" [routerLink]="['/viewusers']">View all users</div>
</div>
</div>
</div>

<div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
<div id = "notificationtext"></div>
</div>
</div>
<ng-template #show>
    <div class = "errorscreen">
    <div class = "errortitle">Unauthorised Access.</div>
    </div>
  </ng-template>