import { Component, OnInit } from '@angular/core';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';
import { DateTime } from "luxon";

@Component({
  selector: 'app-sellgold',
  templateUrl: './sellgold.component.html',
  styleUrls: ['./sellgold.component.css']
})
export class SellgoldComponent implements OnInit {
  now:any;
  loans: Array<any>;
  loans$: Observable<any[]>;

  constructor(
    private adminflagService: AdminflagService,
    private firestore: AngularFirestore,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService
  ) {
    this.adminflagService.setAdminFlag('coco');
    this.meta.addTags([
      { name: 'description', content: 'Do you want to sell gold in Melbourne? With us, it’s easy. We buy your unwanted jewellery, coins, etc. and pay cash instantly. Contact us today.' },
      { name: 'author', content: 'Prakahs Gold Finance' },
      { name: 'keywords', content: 'sell gold melbourne, sell gold for cash, sell scrap gold, sell your gold jewellery, selling your gold, jewellery buyers' }
    ]);
    this.setTitle('Sell Gold Melbourne | Prakahs Gold Finance');
    this.now = DateTime.now();
  }

  ngOnInit(): void {
    this.loans$ = this.firestore.collection('buyrates').valueChanges({ idField: 'id' });
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
    this.createLinkForCanonicalURL();
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 


