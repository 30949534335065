<div *ngIf="auth.user | async as user; else show">
    <div class = "flexscreen">
      <div class = "heading">
        <div class = "formtitle">Pending loan payments</div>
        <div class = "formsubtitle">Search database for loans</div>
      </div>
    
      <input class="search-box" placeholder="Search using pawn ticket" (keyup)=search($event.target.value) type="text">
      <div class = "users" *ngFor="let loan of filteredLoans$ | async; let ux = index">

        <div class = "user" id = "{{ ux }}">
        <div *ngFor = "let doc of loan.pendingpayments | keyvalue: 0; let ix = index">
            <div *ngIf = "now > doc.value.pendingdate && doc.value.fullpaymentloanpaid > 0; then showPending(ux, doc.value.pendingdate)"></div>
            </div>
            <div class = "loantitle">PAWN TICKET #{{loan.id}}</div>
<div *ngFor = "let pdates of loan.pendingpayments | keyvalue: 0; let px = index">
<div class = "pendingdates" *ngIf = "now > pdates.value.pendingdate && pdates.value.status !== 'Paid'">
  <div class = "pendinglabel"> Payment {{ px+1 }} pending. Date - {{ pdates.value.day }}.{{ pdates.value.month }}.{{ pdates.value.year }}</div>
</div>
</div>

            <div class = "userlabel">Type : {{loan.details.type}}</div>
            <div class = "userlabel">Date : {{loan.date}}/{{loan.month}}/{{loan.year}}</div>
            <div class = "userlabel">Customer Name : {{loan?.details.name}}</div>
            <div class = "userlabel">Customer Address : {{loan?.details.address}}</div>
            <div class = "userlabel">Customer Phone : {{loan?.details.phone}}</div>
            <div class = "userlabel">Customer Email : {{loan?.details.email}}</div>
            <div class = "userlabel">Transaction Fee : ${{ loan.details.transactionfee }}</div>
            <div class = "userlabel">Loan Amount : ${{loan.details.principle}}</div>
            <div class = "userlabel">Rate of Interest % : {{loan.details.rate}}</div>
            <div class = "userlabel">Duration : {{loan.details.time}}</div>
            <div class = "userlabel">EMI : ${{ loan.details.emi }}</div>
            <div class = "userlabel">Interest : ${{ loan.details.totalinterest }}</div>
            <div class = "userlabel">Total Revenue : ${{ loan.revenue }}</div>
    
    </div>
    </div>
</div>
</div>
    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>