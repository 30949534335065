import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import{ Triggers } from '../../triggers';
import { AdminflagService } from '../../../app/services/adminflag.service';

@Component({
  selector: 'app-loanedit',
  templateUrl: './loanedit.component.html',
  styleUrls: ['./loanedit.component.css']
})
export class LoaneditComponent implements OnInit {
  authnot: string; 
  loans$: Observable<any[]>;
  filteredLoans$: Observable<any[]>;
  math = Math;
  newer: any;
  parsefloat = parseFloat;

  constructor(
    private firestore: AngularFirestore,
    public auth: AngularFireAuth,
    private adminflagService: AdminflagService
    ) {
      this.loans$ = firestore.collection('goldloans').valueChanges();
      this.adminflagService.setAdminFlag('coco');
    }

  ngOnInit() {
    this.filteredLoans$ = this.loans$;
  }
  ngOnDestroy() {
    this.adminflagService.setAdminFlag('');
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value:string) {
    if(value) {

      
      this.filteredLoans$ = this.loans$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((loans: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return loans.filter((loan: any) => loan.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
        })
       
      )
  } else {
    // reload the full data set
    this.filteredLoans$ = this.loans$;
  }
}

selectUser(email) {
  alert(email);
}
}