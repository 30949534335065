<div *ngIf="auth.user | async as user; else show">
    <div class="printscreen">
        <div class="users">
            <div class="user">
                <div class="mainhead">
                    <div class="mtitle">Prakahs PTY LTD</div>
                    <div class="msub">ABN: 78 165 251 445<br>
                        127 Walker St. Dandenong VIC 3175<br>
                        Call: Dan 0421279530<br>
                    </div>
                </div>
                <div class="deets">
                    <div class="leftdeets">
                        <div class="userlabel">Name : {{ guser?.details.name }}</div>
                        <div class="userlabel">Address : {{ guser?.details.address }}</div>
                        <div class="userlabel">Phone : {{ guser?.details.phone }}</div>
                        <div class="userlabel">Email : {{ guser?.details.email }}</div>
                        <div class="userlabel">Type : {{gloan?.details.type}}</div>
                        <div class="userlabel">Date : {{gloan?.date}}/{{gloan?.month}}/{{gloan?.year}}</div>
                        <div class="userlabel">Transaction Fee : ${{ gloan?.details.transactionfee }}</div>
                    </div>
                    <div class="rightdeets">
                        <div class="userlabel red">PAWN TICKET #{{ id }}</div>
                        <div class="userlabel">Loan Amount : ${{gloan?.details.principle}}</div>
                        <div class="userlabel">Rate of Interest % : {{gloan?.details.rate}} ({{ gloan?.details.type }})
                        </div>
                        <div class="userlabel">Duration : {{gloan?.details.time}}</div>
                        <div class="userlabel">{{ gloan?.details.type }} Interest : ${{
                            gloan?.details.totalinterest/gloan?.details.time | number: '1.0-2' }}</div>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Interest</th>
                            <th>Loan Paid</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <div class="disclaimer">
                    <div class="xtitle">Contract Agreement:</div>
                    <div class="xbody">

                        I warrant that I am the legal owner of the described goods pawned above. I the undersigned, pawn the goods described on this ticket to the pawnbroker as security for the loan, interest and charges on the terms of this agreement.<br><br>

                        I understand that:<br><br>
                        <ul>
                        <li>Interest charges upon the loan are required to be paid every 30 days or as per the terms of the
                        contract above. If I do not meet the interest payments required, I have failed to renew my loan,
                        hence the pawnbroker is entitled to sell the goods free of any claim by me.</li>
                        <li>The pawnbroker is entitled to deduct from the proceeds of the sale the outstanding amount of the
                        loan, the interest and the reasonable costs of sale of goods.</li>
                        <li>Storage and selling charges of $10 or 1% per day (whichever is higher) can be applied to overdue
                        pawntickets and non collected paid items.</li></ul><br>

                        NOTE: Sufficient evidence is required for the pawned items to be redeemed. Goods are not insured
                        while on premisses or elsewhere, Prakahs PTY LTD, its staff and associates will not be liable to
                        the loss, damage or deterioration of the pawned goods arising from fire, theft, storm or
                        tempest, act of God, civil commotion or other acts while in our care. Persons wishing to have
                        goods insured must arrange their own insurance. I declare I have received notice of ‘What are my
                        rights when I pawn goods’ of second-Hand pawnbroker Act 1989 Section 23(3)(b).
                        .</div>
                    <div class = "xsign">
                        <div class="xxsign">
                        <div>Signed by customer</div>
                            <img class = "sign" src = "{{gloan?.signurl}}"/>
                        </div>
                        <div class="xxsign rce">
                            <div>Date</div>
                            <div class = "lighter"><br>{{ gloan?.signature.date.toDate() | date: 'dd-MM-yyyy'}}</div>
                        </div>
                        <div class="xxsign redeem">
                            <div>Signed on behalf of Pawnbroker</div>
                        </div>
                    </div>

                    <div class="xtitle redeems">REDEMPTION</div>
                    <div class="xbody">
                        I certify that I have redeemed the goods and that I have received them back in good order and
                        condition.</div>
                    <div class="xsign">
                        <div class="xxsign">
                        <div>Paid on redemption</div>
                        <div class = "lighter"><br>${{ gloan?.redemption?.paid }}</div>
                        </div>
                        <div class="xxsign rce">
                        <div>Date of redemption</div>
                        <div class = "lighter"><br>{{ gloan?.redemption?.date.toDate() | date: 'dd-MM-yyyy'}}</div>
                        </div>
                        <div class="xxsign redeem">
                        <div>Signature on redemption (customer)</div>
                        <img class = "sign" src = "{{gloan?.redeemsignurl}}"/>
                        </div>
                    </div>
                </div>
                <div class="paymentdetails">
                    <div class="paymentdetailstext">
                        Repayments by bank transfer can be made to the following account, please include your name and the pawn number as description/reference when making a bank transfer and call to confirm you have sent your payment.<br><br>

Please note that goods will not be released until funds have cleared into our bank account as per Prakahs Pty Ltd Company policy.<br><br>

<span>Bank:</span> Commonwealth Bank   <span>Account Name:</span> Prakahs Pty Ltd<br>
<span>Bank BSB:</span> 063-245     <span>Account Number:</span> 10843869<br><br>

PayID: select Other select ABN enter 165 251 445, company Prakahs Pty Ltd

                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
<ng-template #show>
    <div class="errorscreen">
        <div class="errortitle">Unauthorised Access.</div>
    </div>
</ng-template>









