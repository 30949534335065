<div *ngIf="auth.user | async as user; else show">
  <div class="flexscreen">
    <div class="heading">
      <div class="formtitle">View customers</div>
      <div class="formsubtitle">Search database for customers</div>
    </div>

    <input class="search-box" placeholder="Search for a user" (keyup)=search($event.target.value) type="text">
    <div class="usersc">
      <div *ngFor="let user of filteredUsers$ | async">



        <div class="userc" id="card{{user.id}}">
          <a class="userdp" href="{{user.profile.photoid}}"><img [src]="user.profile.photoid" /></a>
          <div class="userlabel usertitle">{{user.details.name}}</div>
          <div class="userlabel">
            <div class="usvg"><svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"
                viewBox="0 0 24 24">
                <path
                  d="M14.101 24l-14.101-14.105v-9.895h9.855l14.145 14.101c-3.3 3.299-6.6 6.599-9.899 9.899zm-4.659-23h-8.442v8.481l13.101 13.105 8.484-8.484c-4.381-4.368-8.762-8.735-13.143-13.102zm-1.702 3.204c.975.976.975 2.56 0 3.536-.976.975-2.56.975-3.536 0-.976-.976-.976-2.56 0-3.536s2.56-.976 3.536 0zm-.708.707c.586.586.586 1.536 0 2.121-.585.586-1.535.586-2.121 0-.585-.585-.585-1.535 0-2.121.586-.585 1.536-.585 2.121 0z" />
              </svg></div>
            <div class="utxt">{{user.id}}</div>
          </div>

          <div class="userlabel">
            <div class="usvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M14 18.597v.403h-10v-.417c-.004-1.112.044-1.747 1.324-2.043 1.403-.324 2.787-.613 2.122-1.841-1.973-3.637-.563-5.699 1.554-5.699 2.077 0 3.521 1.985 1.556 5.699-.647 1.22.688 1.51 2.121 1.841 1.284.297 1.328.936 1.323 2.057zm6-9.597h-4v2h4v-2zm0 4h-4v2h4v-2zm0 4h-4v2h4v-2zm-4-12v2h6v14h-20v-14h6v-2h-8v18h24v-18h-8zm-6-4v6h4v-6h-4zm2 4c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z" />
              </svg></div>
            <div class="utxt">{{user.details.photoidno}}</div>
          </div>

          <div class="userlabel">
            <div class="usvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
              </svg></div>
            <div class="utxt">{{user.details.email}}</div>
          </div>
          <div class="userlabel">
            <div class="usvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z" />
              </svg></div>
            <div class="utxt">{{user.details.phone}}</div>
          </div>
          <div class="userlabel">
            <div class="usvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
              </svg></div>
            <div class="utxt">{{user.details.address}}</div>
          </div>
          <div class="loanexpander" (click)="viewLoans(user.id)">View Loans</div>



          <div class="list">
            <div class="loanexptitle">Personal loans</div>
            <div *ngFor="let item of items | async" id="i">
              <div class="ebox" [routerLink]="['/loandetails', item.id]">
                <div class="ehead">Pawn Ticket : {{ item.id }}</div>
                <div class="elabel">Date : {{ item.date }}.{{ item.month }}.{{ item.year }}</div>
                <div class="elabel">Weekly : {{ item.details.type }}</div>
                <div class="elabel">Principle : {{ item.details.principle }}</div>
                <div class="elabel">Rate of Interest : {{ item.details.rate }}</div>
                <div class="elabel">Time Period : {{ item.details.time }}</div>
                <div class="elabel">EMI : {{ item.details.emi }}</div>
                  <div *ngIf="item.status == 1" class="green"></div>
                  <div *ngIf="item.status == 4" class="purple"></div>
                  <div *ngIf="item.status !== 1 && item.status !== 4">
                    <div *ngFor="let pldates of item.pendingpayments | keyvalue">
                      <div *ngIf="now > pldates.value.pendingdate && pldates.value.status !== 'Paid'" class="red"></div>
                    </div>
                  </div>

              </div>
            </div>
            <div class="loanexptitle">Gold loans</div>
            <div *ngFor="let gitem of gitems | async" id="ig">

              <div class="ebox" [routerLink]="['/goldloandetails', gitem.id]">
                <div class="ehead">Pawn Ticket : {{ gitem.id }}</div>
                <div class="elabel">Date : {{ gitem.date }}.{{ gitem.month }}.{{ gitem.year }}</div>
                <div class="elabel">Weekly : {{ gitem.details.type }}</div>
                <div class="elabel">Principle : {{ gitem.details.principle }}</div>
                <div class="elabel">Rate of Interest : {{ gitem.details.rate }}</div>
                <div class="elabel">Time Period : {{ gitem.details.time }}</div>
                <div class="elabel">EMI : {{ gitem.details.emi }}</div>
                <div *ngIf="gitem.status == 1" class="green"></div>
                <div *ngIf="gitem.status == 4" class="purple"></div>
                <div *ngIf="gitem.status !== 1 && gitem.status !== 4">
                  <div *ngFor="let pdates of gitem.pendingpayments | keyvalue">
                    <div *ngIf="now > pdates.value.pendingdate && pdates.value.status !== 'Paid'" class="red"></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</div>
<ng-template #show>
  <div class="errorscreen">
    <div class="errortitle">Unauthorised Access.</div>
  </div>
</ng-template>