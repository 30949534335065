import { Component, OnInit } from '@angular/core';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-goldbuyers',
  templateUrl: './goldbuyers.component.html',
  styleUrls: ['./goldbuyers.component.css']
})
export class GoldbuyersComponent implements OnInit {

  constructor(
    private adminflagService: AdminflagService,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService
  ) { 
    this.adminflagService.setAdminFlag('coco');
    this.meta.addTags([
      { name: 'description', content: 'Learn tips for selling gold valuables from established gold buyers in Melbourne. We offer competitive rates on gold coins, gold bars, and jewellery.' },
      { name: 'author', content: 'Prakahs Gold Finance' },
      { name: 'keywords', content: 'gold buyers melbourne, gold and jewellery buyers, gold bar buyer, gold bars buyers, gold buyers, gold buyers and sellers, gold buyers victoria, we buy gold for cash, gold coins buyer, bullion buyers, buyers of gold, scrap gold buyers' }
    ]);
    this.setTitle('Gold Buyers Melbourne | Prakahs Gold Finance');
    this.createLinkForCanonicalURL();
  }

  ngOnInit(): void {
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 