<router-outlet></router-outlet>

<div *ngIf="adminflagService.adminflag === 'coco'; then floaters"></div>
<div *ngIf="adminflagService.adminflag === 'boco'; then none"></div> 
<div *ngIf="adminflagService.adminflag !== 'coco' && adminflagService.adminflag !== 'boco'; then sides"></div>  

<ng-template #floaters>
<app-nav></app-nav>
<a class = "floatingmap" href = "https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
  </a>
  
  <a class = "floatingwhatsapp" href="tel:(03) 8772 2557">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
  </a>

<app-header></app-header>
</ng-template>
   
   
<ng-template #none></ng-template>
<ng-template #sides><app-sidebar></app-sidebar></ng-template>
