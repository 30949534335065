<div *ngIf="auth.user | async as user; else show">
<div class = "flexscreen">
  <div class = "formcontainer">
      <div class = "formtitle">Add a new admin</div>
      <div class = "formsubtitle">Fill in the required details.</div>
      <form [formGroup]="adminForm" (ngSubmit)="onSubmit()">
              <div class="form-group col-5">
                  <input type="text"
                   formControlName="user"
                   class="form-control" 
                   [ngClass]="{ 'is-invalid': submitted && f.user.errors }" 
                    placeholder="Enter admin email"/>
                  <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
                      <div *ngIf="f.user.errors.required">Email is required</div>
                  </div>
              </div>
              <div class="form-group col">
                  <input type="password" 
                  formControlName="password" 
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" 
                  placeholder="Enter admin password" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                      <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                  </div>
              </div>
              <button class="xbutton">Add Admin</button>
      </form>
      </div>
      </div>
      <div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
        <div id = "notificationtext"></div>
        </div>
    </div>
    <ng-template #show>
        <div class = "errorscreen">
        <div class = "errortitle">Unauthorised Access.</div>
        </div>
      </ng-template>