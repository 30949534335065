import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx'; 
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";

@Component({
  selector: 'app-itemsrevenue',
  templateUrl: './itemsrevenue.component.html',
  styleUrls: ['./itemsrevenue.component.css']
})
export class ItemsrevenueComponent implements OnInit {

  fileName= 'PurchasedItemsRevenue.xlsx'; 
  items: Observable<any[]>;
  math = Math;
  parsefloat = parseFloat;
  now:any;

  constructor(private firestore: AngularFirestore,
    public auth: AngularFireAuth) {
    this.items = firestore.collection('purchaseditems', ref => ref.orderBy('id','desc')).valueChanges();
    }

  ngOnInit() {
    this.now = DateTime.now().toString();
  }


exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
			
    }
}