<div *ngIf="auth.user | async as user; else show">
  <div class = "flexscreen">
    <div class = "heading">
      <div class = "formtitle">View purchased items</div>
      <div class = "formsubtitle">View a list of all the purchased gold/jewellery</div>
    </div>
      <div class = "users">
        <div *ngFor="let user of item$ | async">
          <div class = "user">
          <div class = "userdp">
          <img [src] = "user.profile.photo"/></div>
          <div class = "userlabel">Type : {{user.details.type}}</div>
          <div class = "userlabel">Weight : {{ user.details.weight }}</div>
          <div class = "userlabel">Price : {{ user.details.price }}</div>
          <div class = "userlabel">Purchased from : {{ user.details.purchasedfrom }}</div>
        </div>
    </div>
  </div>
  </div>
  </div>
  <ng-template #show>
    <div class = "errorscreen">
    <div class = "errortitle">Unauthorised Access.</div>
    </div>
  </ng-template>