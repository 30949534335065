<div class="nav">
	<div class="navitems">

        <div class = "loginctn">
        <p>127 Walker Street, Dandenong, VIC 3175<br>
            Phone: (03) 8772 2557<br>
            Mon - Sat: 9:30am - 5:30pm<br>
            Sun: Closed</p>
            <a class = "navx" href="https://prakahsgold.com.au">Home</a>
            <a class = "navx" [routerLink]="['/contact']" (click) = "toggleNavMenu()">Contact Us</a>
            <div *ngIf="logger; else clogin">
                <div class = "xus">Hello, <span>{{ logger.substring(0, logger.lastIndexOf("@")) }}.</span></div>
                <a class = "navx" [routerLink]="['/login']" (click) = "toggleNavMenu()">View loans</a>
                <a class = "navx" (click) = "logout()">Log out</a>
            </div>
            <ng-template #clogin>
                <a class = "navx" [routerLink]="['/login']" (click) = "toggleNavMenu()">Login</a>
              </ng-template>
        
	</div>
</div>
</div>