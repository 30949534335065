import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DateTime } from "luxon";
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';
import { map, filter } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-rgold',
  templateUrl: './rgold.component.html',
  styleUrls: ['./rgold.component.css']
})
export class RgoldComponent implements OnInit {

  fileName = 'RevenueGoldItems.xlsx';
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  now: any;
  loanarray: any;

  constructor(private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private excelService: ExcelService) {
  }

  ngOnInit(): void {
    this.now = DateTime.now();
    this.loanarray = [];
  }

  filterByDate() {
    const queryObservable = this.afs.collection('purchaseditems', ref => ref.orderBy('id', 'desc')).valueChanges();
    queryObservable.subscribe(queriedItems => {
      for (let [index, loan] of queriedItems.entries()) {
        const [cchecker, crevenue] = this.filterLoans(loan);
        if (cchecker == true) {
          this.loanarray[index] = loan;
          this.loanarray[index].erevenue = crevenue;
        }
      }
    });
  }

  filterLoans(loan) {
    var revenue: number = 0;
    var checker;
    if(typeof loan.details.selldate !== 'undefined') {
    var loandate = new Date(loan.details.selldate);
    if((loandate >= this.range.controls['start'].value) && (loandate <= this.range.controls['end'].value)) {
      checker = true;
      revenue = revenue + loan.details.soldamount;
    }
  }
    return [checker, revenue];
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    this.excelService.exportAsExcelFile(wb, 'RevenueGoldLoans');
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}