<div *ngIf="auth.user | async as user; else show">
    <div class = "ftt">
      <div class = "heading">
        <div class = "formtitle">View loans</div>
        <div class = "formsubtitle">Search database for loans</div>
      </div>
      
    
      <input class="search-box" placeholder="Search for a loan" (keyup)=search($event.target.value) type="text">
    
        <div class = "users">
            <div *ngFor="let loan of filteredLoans$ | async">
            <div class = "user">
            <div class = "usertitle">ID : {{loan.details.loanid}}</div>
            <div class = "userlabel">Date : {{loan.date}}/{{loan.month}}/{{loan.year}}</div>
            <div class = "userlabel">User : {{loan.details.selectedemail}}</div>
            <div class = "userlabel">Principle : {{loan.details.principle}}</div>
            <div class = "userlabel">Rate of Interest (%p.a.) : {{loan.details.rate}}</div>
            <div class = "userlabel">Duration (months) : {{loan.details.time}}</div>
            <div class = "userlabel">EMI : {{ loan.details.principle * (loan.details.rate/12/100) * math.pow((1+(loan.details.rate/12/100)), loan.details.time) / (math.pow((1+(loan.details.rate/12/100)), loan.details.time) - 1) }}</div>
            <div class = "userlabel">Total Interest : {{ ((loan.details.principle * (loan.details.rate/12/100) * math.pow((1+(loan.details.rate/12/100)), loan.details.time) / (math.pow((1+(loan.details.rate/12/100)), loan.details.time) - 1)) * loan.details.time) - loan.details.principle }}</div>
            <div class = "userlabel">Total Amount : {{ (loan.details.principle * (loan.details.rate/12/100) * math.pow((1+(loan.details.rate/12/100)), loan.details.time) / (math.pow((1+(loan.details.rate/12/100)), loan.details.time) - 1)) * loan.details.time }}</div>
      </div>
    </div>
    </div>
    </div>
    </div>
    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>
  