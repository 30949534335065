<div *ngIf="auth.user | async as user; else show">
    <div class="selectscreen" id = "selectscreen">
    <div class = "loanformcontainer">
        <div class = "formtitle">Add a new loan</div>
        <div class = "formsubtitle">Select user for loan.</div>
<input class="search-box" placeholder="Search for a user" (keyup)=search($event.target.value) type="text">
</div>    
      <div class = "users">
          <div *ngFor="let user of filteredUsers$ | async">
          <div class = "user" (click) = "proceedLoan(user.email)">
          <div class = "usertitle">{{user.name}}</div>
          <div class = "userlabel">{{user.email}}</div>
          <div class = "userlabel">{{user.phone}}</div>
        </div>
    </div>
  </div>
  </div>
  
  <div class = "loanscreen" id = "loanscreen">
        <div class = "loanformcontainer">
            <div class = "formtitle">Add a new loan</div>
            <div class = "formsubtitle">Add gold items for the loan.</div>
        <form [formGroup]="newloanForm">
            <div class = "loanpage1" id = "loanpage1">
        <div class="inputsection">
            <mat-form-field>
            <mat-label>Loan ID</mat-label>
            <input matInput
            formControlName="loanid" 
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.loanid.errors }"
            >
          </mat-form-field>
        </div>

        <div class="inputsection">
        <mat-form-field>
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker"
            formControlName="date"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.date.errors }" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        

        <div class="inputsection">
            <input type="hidden"
            formControlName="selectedemail"
            class="form-control"
            placeholder="Selected Email"/>
            <div *ngIf="submitted && f.selectedemail.errors" class="invalid-feedback">
                <div *ngIf="f.selectedemail.errors.required">There was an error in selecting the user</div>
                </div>
        </div>
                    <div class="text-center">
                        <button class = "xbutton" mat-button (click)="addItems()">Proceed to add gold items</button>
                    </div>
            </div>
        <div class = "loanpage2" id = "loanpage2">
          <button class = "xbutton" mat-button (click)="addCreds()" id = "topbutton">Click to add gold item</button>
          <div formArrayName="golditems" *ngFor="let creds of newloanForm.controls.golditems?.value; let i = index; trackBy:trackByFn">
            <div class = "golditemcontainer">
                <div class = "itemheading">Item {{ i+1 }}</div>
            <ng-container [formGroupName]="i">
              <div class="inputsection">
                <mat-form-field>
                <mat-label>Type</mat-label>
                <input matInput
                formControlName="type" 
                class="form-control"
                >
              </mat-form-field>
            </div>
            <div class="inputsection">
                <mat-form-field>
                <mat-label>Weight</mat-label>
                <input matInput
                formControlName="weight" 
                class="form-control"
                >
              </mat-form-field>
            </div>
            <div class="inputsection">
                <mat-form-field>
                <mat-label>Additional Info</mat-label>
                <input matInput
                formControlName="additional" 
                class="form-control"
                >
              </mat-form-field>
            </div>

        <div class="inputsection">
            <mat-form-field>
            <mat-label>Carats (If item has diamond)</mat-label>
            <input matInput
            formControlName="carats" 
            class="form-control"
            >
          </mat-form-field>
        </div>

        <div class="inputsection" id = "uploader">
            <label for="file">Upload Image of Item</label>
            <input type="file" (change)="uploadFile($event, i)" id = "file" accept=".png, .jpg, .jpeg, .gif"/>
            <div *ngIf="uploadPercent | async" class = "loader">{{ uploadPercent | async }}% uploaded.</div>
          </div>

            </ng-container>
          </div></div>
          <div class="financebutton" id ="financebutton">
            <button class = "xbutton" mat-button (click)="addFinance()">Proceed to add finance details</button>
        </div>
        </div>


        <div class = "loanpage3" id = "loanpage3">
            <div class="inputsection">
                <mat-form-field>
                <mat-label>Loan Amount</mat-label>
                <input matInput
                #principle (keyup)="0"
                formControlName="principle" 
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.principle.errors }"
                >
              </mat-form-field>
            </div>
    
            <div class="inputsection">
                <mat-form-field>
                <mat-label>Rate of Interest</mat-label>
                <input matInput
                #rate (keyup)="0"
                formControlName="rate" 
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rate.errors }"
                >
              </mat-form-field>
            </div>
    
            <div class="inputsection">
                <mat-form-field>
                <mat-label>Time Period (months)</mat-label>
                <input matInput
                #time (keyup)="0"
                formControlName="time" 
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.time.errors }"
                >
              </mat-form-field>
            </div>
            <div class = "interest">Monthly EMI : {{ (principle.value * rate.value * (time.value/12))/100 }}</div>
                        <div class="text-center">
                            <button class = "xbutton" mat-button (click)="addLoan()">Proceed to add loan</button>
                        </div>
        </div>
</form>
        </div>
  </div>
</div>
<ng-template #show>
    <div class = "errorscreen">
    <div class = "errortitle">Unauthorised Access.</div>
    </div>
  </ng-template>