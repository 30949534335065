import { Component, OnInit } from '@angular/core';
import { AdminflagService } from '../../../app/services/adminflag.service';

import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { ViewportScroller } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loans: Array<any>;
  loans$: Observable<any[]>;
  
  constructor(
    private firestore: AngularFirestore,
    private adminflagService: AdminflagService,
    private _vps: ViewportScroller,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService
  ) { 
    this.adminflagService.setAdminFlag('coco');
    this.meta.addTags([
      { name: 'description', content: 'About the best gold buyers and loan specialists in Melbourne. Call Prakahs Gold Finance.' },
      { name: 'author', content: 'Prakahs Gold Finance' },
      { name: 'keywords', content: 'gold buyers melbourne, jewelery buyers melbourne, best jewelery buyer, sell gold melbourne, sell gold for cash, sell scrap gold, sell your gold jewellery, selling your gold, jewellery buyers' }
    ]);
    this.setTitle('About Us | Prakahs Gold Finance');
    this.createLinkForCanonicalURL();
    }


  ngOnInit(): void {
    this.loans$ = this.firestore.collection('rates').valueChanges({ idField: 'id' });
  }
  

scrollFn(anchor: string): void{
  	this._vps.scrollToAnchor(anchor)
}
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 