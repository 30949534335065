<div class = "sidepanel" id = "sidepanel">
    <div class = "splogo"><img src = "../../../assets/logo.png"/></div>
    <div class = "spoption" [routerLink]="['/dashboard']" (click) = "toggleMenu()">Dashboard<div class = "spacer"></div></div>
    <div class = "spoption" [routerLink]="['/messages']" (click) = "toggleMenu()">View Messages<div class = "spacer"></div></div>
    
    <div class = "spoption smallsvg" id = "payhead" (click) = "showPayOptions()">Purchased Gold/Jewellery<div class = "spacer"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg></div>
    <div class = "usersub" id = "paysub">
    <div class = "soption" [routerLink]="['/gold']" (click) = "toggleMenu()">View Purchased Gold/Jewellery</div>
    <div class = "soption" [routerLink]="['/addpgold']" (click) = "toggleMenu()">Add Purchased Gold/Jewellery</div>
    <div class = "soption" [routerLink]="['/rgold']" (click) = "toggleMenu()">View revenue</div>
    <div class = "soption" [routerLink]="['/revenueitems']" (click) = "toggleMenu()">View Purchased Gold/Jewellery report</div>
    </div>


    <div class = "spoption smallsvg" id = "userhead" (click) = "showUserOptions()">Customers<div class = "spacer"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg></div>
    <div class = "usersub" id = "usersub">
    <div class = "soption" [routerLink]="['/users']" (click) = "toggleMenu()">View all customers</div>
    <div class = "soption" [routerLink]="['/addusers']" (click) = "toggleMenu()">Add customers</div>
    </div>
    <div class = "spoption smallsvg" id = "loanhead" (click) = "showLoanOptions()">Loans<div class = "spacer"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg></div>
    <div class = "loansub" id = "loansub">
    <div class = "soption smallsvg" id = "goldhead" (click) = "showGoldLoanOptions()">Gold loans<div class = "spacer"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg></div>

    <div class = "goldsub" id = "goldsub">
        <div class = "soption" [routerLink]="['/newgoldloan']" (click) = "toggleMenu()">- Create a gold loan</div>
        <div class = "soption" [routerLink]="['/goldloans']" (click) = "toggleMenu()">- View gold loans</div>
        <div class = "soption" [routerLink]="['/paygoldloan']" (click) = "toggleMenu()">- Add received payments</div>
        <div class = "soption" [routerLink]="['/excelgold']" (click) = "toggleMenu()">- View pending payments</div>
        <div class = "soption" [routerLink]="['/rgoldloans']" (click) = "toggleMenu()">- View revenue</div>
        <div class = "soption" [routerLink]="['/reportgoldloans']" (click) = "toggleMenu()">- View loan report</div>
    </div>

    <div class = "soption smallsvg" id = "personalhead" (click) = "showPersonalLoanOptions()">Personal loans<div class = "spacer"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg></div>

    <div class = "personalsub" id = "personalsub">
        <div class = "soption" [routerLink]="['/newloan']" (click) = "toggleMenu()">- Create a personal loan</div>
        <div class = "soption" [routerLink]="['/loans']" (click) = "toggleMenu()">- View personal loans</div>
        <div class = "soption" [routerLink]="['/payloan']" (click) = "toggleMenu()">- Add received payments</div>
        <div class = "soption" [routerLink]="['/excel']" (click) = "toggleMenu()">- View pending payments</div>
        <div class = "soption" [routerLink]="['/rloans']" (click) = "toggleMenu()">- View revenue</div>
        <div class = "soption" [routerLink]="['/reportloans']" (click) = "toggleMenu()">- View loan report</div>
    </div>
    </div>
    <div class = "spacer"></div>
    <div class = "checker" *ngIf="auth.user | async as user; else showX">
    <div class = "spoption bigsvg" [routerLink]="['/settings']" (click) = "toggleMenu()">Admin settings<div class = "spacer"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"/></svg></div>
    <div class = "spoption bigsvg" (click) = "logout()">Log out<div class = "spacer"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-2 10v-.083c-1.178.685-2.542 1.083-4 1.083-4.411 0-8-3.589-8-8s3.589-8 8-8c1.458 0 2.822.398 4 1.083v-2.245c-1.226-.536-2.577-.838-4-.838-5.522 0-10 4.477-10 10s4.478 10 10 10c1.423 0 2.774-.302 4-.838v-2.162z"/></svg></div>
</div>
<ng-template #showX>
    <div class = "spoption bigsvg" [routerLink]="['/auth']" (click) = "toggleMenu()">Log in<div class = "spacer"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 9v-4l8 7-8 7v-4h-8v-6h8zm-2 10v-.083c-1.178.685-2.542 1.083-4 1.083-4.411 0-8-3.589-8-8s3.589-8 8-8c1.458 0 2.822.398 4 1.083v-2.245c-1.226-.536-2.577-.838-4-.838-5.522 0-10 4.477-10 10s4.478 10 10 10c1.423 0 2.774-.302 4-.838v-2.162z"/></svg></div>
  </ng-template> 
</div>
<div class = "ssicon" id = "ssicon" (click) = "toggleMenu()">
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path _ngcontent-dsx-c50="" d="M24 21h-24v-2h24v2zm0-4.024h-24v-2h24v2zm0-3.976h-24v-2h24v2zm0-4h-24v-2h24v2zm0-6v2h-24v-2h24z"></path></svg>
        </div>


          