import { Component, OnInit } from '@angular/core';
import { AdminflagService } from '../../../app/services/adminflag.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-postlogin',
  templateUrl: './postlogin.component.html',
  styleUrls: ['./postlogin.component.css']
})
export class PostloginComponent implements OnInit {

  constructor(
    private adminflagService: AdminflagService,
    private meta: Meta,
    private title: Title,
    private seoService: SeoService
  ) { 
    this.adminflagService.setAdminFlag('coco');
    this.meta.addTags([
      { name: 'description', content: 'Documents you need to get a loan in melbourne' },
      { name: 'author', content: 'Prakahs Gold Finance' },
      { name: 'keywords', content: 'loan melbourne, what you need for loan melbourne, gold loan documents melbourne, loan documents melbourne, gold loan melbourne' }
    ]);
    this.setTitle('Loan Requirements Melbourne | Prakahs Gold Finance');
    this.createLinkForCanonicalURL();
  }

  ngOnInit(): void {
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 
} 