import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {map, filter} from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";

@Component({
  selector: 'app-viewusers',
  templateUrl: './viewusers.component.html',
  styleUrls: ['./viewusers.component.css']
})
export class ViewusersComponent implements OnInit {
  private itemsCollection: AngularFirestoreCollection<any>;
  goldItemsCollection: AngularFirestoreCollection<any>;
  items: Observable<any[]>;
  gitems: Observable<any[]>;
  users$: Observable<any[]>;
  filteredUsers$: Observable<any[]>;
  newloanForm: FormGroup;
  selectedemail: string;
    submitted = false;
    uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  math = Math;
  now:any;

  constructor(private firestore: AngularFirestore,
    private formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    public auth: AngularFireAuth) {
    this.users$ = firestore.collection('users').valueChanges({ idField: 'id' });
    }

  ngOnInit() {
    this.now = DateTime.now().toString();
    this.filteredUsers$ = this.users$;
  }
  showHide(user: any) {
    // for each user we can add a property of show and use this as 
    // a boolean flag to turn each one on/off for a list of any size
    user.show = !user.show;
  }

  search(value:string) {
    if(value) {

      
      this.filteredUsers$ = this.users$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((users: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return users.filter((user: any) => user.details.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
        })
       
      )
  } else {
    // reload the full data set
    this.filteredUsers$ = this.users$;
  }
}

selectUser(email) {
  alert(email);
}

  viewLoans(userid) {
    var opener = 'card'+userid;
    var x = document.getElementsByClassName("userc");
    var i;
    for (i = 0; i < x.length; i++) {
    x[i].classList.remove("oclicked");
    }
    var changer = document.getElementById(opener);
    changer.classList.add('oclicked');
    this.itemsCollection = this.firestore.collection<any>('loans', ref => ref.where('details.loanuid', "==", userid));
    this.items = this.itemsCollection.valueChanges({ idField: 'id' });

    this.goldItemsCollection = this.firestore.collection<any>('goldloans', ref => ref.where('details.loanuid', "==", userid));
    this.gitems = this.goldItemsCollection.valueChanges({ idField: 'id' });
  }

  showPending(event, date, status) {
    var doc = "doc" + event;
    var pendingloan = document.getElementById(doc);
    if(this.now > date && status !== 'Paid') {
    pendingloan.classList.add("red");
  }
  }
}