<div id="parallax">
    <div class = "navaddress">
        <a href = "https://prakahsgold.com.au">Home</a> > <span>Loan Documents</span>
      </div>
    <section class = "require">
    <div class="block">
        <h3>WHAT YOU NEED TO BRING TO GET A LOAN TODAY?</h3>
        <div class = "thumb">
        <picture>
            <source srcset="../../../assets/wallet.webp" type="image/webp">
            <source srcset="../../../assets/wallet.png" type="image/png"> 
            <img src="../../../assets/wallet.png" alt="documents for loan melbourne" loading="lazy">
          </picture>

        </div>
<p>If the identification has a photograph, only ONE of the below is required:<br><br>- A passport (local or foreign)<br>- A driver's licence (Full or Learners)<br>- A tertiary education photo ID <br>- A licence issued under the Firearms Act 1996.<br>- A key-pass ID cards<br><br>If the identification used does not include a photograph, TWO forms of identification are required: <br><br>- A full birth certificate or extract<br>- A certificate of Australian citizenship<br>- A marriage certificate<br><br>One only of the following health or pensioner cards:<br><br>- Health care card<br>- Pensioner concession card<br>- Commonwealth seniors health card</p>
<div class = "buttons">
    <a class = "navx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
  <a class = "navx" href = "https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit Store</a>
      </div>
</div>
    </section>
    </div>
<app-footer></app-footer>