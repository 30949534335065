<div *ngIf="auth.user | async as user; else show">
    <div class = "flexscreen">
        <div class = "exportbutton" (click)="exportexcel()">Export to Excel</div>
        <table id="excel-table"> 
        <tr>
            <th>Id</th>
            <th>Description</th>
            <th>Amount Paid</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Sold For</th>
            <th>Revenue</th>
        </tr>
<tr class = "users" *ngFor="let item of items | async">
    <td>{{item.id}}</td>
    <td>{{item.details.description}}</td>
    <td>{{item.details.amountpaid}}</td>
    <td>{{item.details.name}}</td>
    <td>{{item.details.phone}}</td>
    <td>{{item.details.status}}</td>
    <td>{{item.details.soldamount}}</td>
    <td>{{item.details.soldamount - item.details.amountpaid}}</td>
</tr>
</table>
    </div>
    </div>
    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>