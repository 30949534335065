export default class Loan {
    date?: any;
    month?: any;
    year?:any;
    details: {
       amount?:any;
       date?:any;
       emi?:any;
       loanuid?:any;
       principle?:any;
       rate?:any;
       revenue?:any;
       time?:any;
       totalinterest?:any;
       transactionfee?:any;
       type?:any;
    };
    pendingpayments?: {}
  }
  