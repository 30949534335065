<footer class="footer">
    <div class = "fnav">
        <a class = "fnavopt" href = "https://prakahsgold.com.au">&#8226; Home &#8226;</a>
        <div class = "fnavopt" [routerLink] = "['/about']">&#8226; About Us &#8226;</div>
        <div class = "fnavopt" [routerLink] = "['/contact-gold-buyer-melbourne']">&#8226; Contact &#8226;</div>
        <a class = "fnavopt" [routerLink]="['/loan-requirements-melbourne']">&#8226; Loan Documents &#8226;</a>
        <div class = "fnavopt" [routerLink] = "['/sell-gold-rates-melbourne']">&#8226; Gold Rates &#8226;</div>
        <div class = "fnavopt" [routerLink] = "['/loan-rates-melbourne']">&#8226; Loan Rates &#8226;</div>
        <div class = "fnavopt" [routerLink] = "['/cash-for-gold-melbourne']">&#8226; Cash for Gold &#8226;</div>
        <div class = "fnavopt" [routerLink] = "['/gold-loan-melbourne']">&#8226; Cash Loans for Gold &#8226;</div>
        <div class = "fnavopt" [routerLink] = "['/sell-gold-melbourne']">&#8226; Sell Gold fast &#8226;</div>
        <div class = "fnavopt" [routerLink] = "['/gold-buyers-melbourne']">&#8226; Gold Selling Tips &#8226;</div>
    </div>
    <div class="footerflex"> 
    <div class="flogo">
        <img src="../../../assets/first.png" title="Studio Melange" alt="Studio Melange Logo" />
    </div>
    <div class = "footerdeets">127 Walker Street, Dandenong, VIC 3175<br>
            Phone: (03) 8772 2557<br>
            Email: prakahsgold@gmail.com<br>
            Mon - Sat : 9:30am - 5:30pm<br>
            Sun : Closed
    </div>
    </div>
    <div class="footlabel">© Prakahs PTY Limited, 2021</div>
</footer>


