<div *ngIf="auth.user | async as user; else show">
  <div class="flexscreen">
    <div class="heading">
      <div class="formtitle">Add a new gold loan</div>
      <div class="formsubtitle">Fill in the required details.</div>
    </div>
    <div class="restarea">
      <div class="useraddform" id="useraddform">
        <mat-horizontal-stepper #stepper linear>
          <mat-step [stepControl]="loanHidden">
            <ng-template matStepLabel>Select a customer</ng-template>
            <div class="usertable">
              <div class="search"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" />
                </svg>
                <input class="search-box" placeholder="Search for a user" (keyup)=search($event.target.value)
                  type="text">
              </div>
              <div class="users">
                <div *ngFor="let user of filteredUsers$ | async">
                  <div class="user"
                    (click)="goForward(user.uid, user.details.name, user.details.phone, user.details.email, user.details.address, stepper)">
                    <div class="userdp">
                      <img [src]="user.profile.photoid" />
                    </div>
                    <div class="usertitle">{{user.details.name}}</div>
                    <div class="userlabel" *ngIf="user.details.email">
                      <div class="lsvg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path
                            d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
                        </svg></div>
                        <div class="llabel">{{user.details.email}}</div>
                      </div>
                      <div class="userlabel"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path
                            d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
                        </svg>
                        <div class="llabel">{{user.details.phone}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </mat-step>
          <mat-step label="Fill loan details" [stepControl]="loanDetails">
            <div class="form1">
              <form [formGroup]="loanDetails">
                <mat-form-field>
                  <mat-label>Date</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="date" class="form-control" required />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <input type="hidden" formControlName="loanuid" class="form-control" />
                <input type="hidden" formControlName="name" class="form-control" />
                <input type="hidden" formControlName="address" class="form-control" />
                <input type="hidden" formControlName="phone" class="form-control" />
                <input type="hidden" formControlName="email" class="form-control" />
                <mat-form-field>
                  <mat-label>Choose duration</mat-label>
                  <mat-select [(value)]="selected" formControlName="type" required>
                    <mat-option *ngFor="let dur of durs" [value]="dur.value">
                      {{dur.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="selected == 'Monthly' || selected == 'Fortnightly' || selected == 'Weekly'" class="fform">
                  <mat-checkbox class="example-margin" (change)="toggleTransactionFee($event.checked)">Include
                    Transaction Fee</mat-checkbox>
                  <div *ngIf="showTransactionFee">
                    <mat-form-field>
                      <mat-label>
                        Transaction Fee
                      </mat-label>
                      <input matInput class="form-control" value=0 formControlName="transactionfee" type="number" required>
                    </mat-form-field>
                  </div>
                  <mat-form-field>
                    <mat-label>
                      Loan Amount
                    </mat-label>
                    <input matInput class="form-control" #principle (keyup)="0" formControlName="principle"
                      type="number" required>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>
                      <div *ngIf="selected == 'Monthly'">Rate of Interest per month</div>
                      <div *ngIf="selected == 'Fortnightly'">Rate of Interest per fortnight</div>
                      <div *ngIf="selected == 'Weekly'">Rate of Interest per week</div>
                    </mat-label>
                    <input matInput class="form-control" #rate (keyup)="0" formControlName="rate" type="number" required>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>
                      <div *ngIf="selected == 'Monthly'">No. of months</div>
                      <div *ngIf="selected == 'Fortnightly'">No. of fortnights</div>
                      <div *ngIf="selected == 'Weekly'">No. of weeks</div>
                    </mat-label>
                    <input matInput class="form-control" #time (keyup)="0" formControlName="time" type="number" required>
                  </mat-form-field>
                  <input type="hidden" formControlName="emi" class="form-control" />
                  <input type="hidden" formControlName="totalinterest" class="form-control" />
                  <div class="xinterest">EMI : {{ math.round((principle.value * (rate.value/100) *
                    math.pow((1+(rate.value/100)), time.value) / (math.pow((1+(rate.value/100)), time.value) - 1)) *
                    100) / 100 }}
                  </div>
                </div>
                <button mat-raised-button color="primary" (click)="addItems(stepper)" class="xbutton">Finish adding
                  loan</button>
              </form>
            </div>
          </mat-step>
          <mat-step [stepControl]="loanItems">
            <ng-template matStepLabel>Add loan items</ng-template>
            <div class="holderc">
              <div class="itemsheader">
                <div class="itemshead">Use the buttons below to add items or finish adding items.</div>
                <div class="buttonsarea">
                  <button class="addbutton" mat-raised-button color="primary" (click)="addCreds()" id="topbutton">Add a
                    gold/jewellery item</button>
                  <button mat-raised-button color="primary" (click)="addLoan(stepper)" class="addbutton">Finish adding
                    items</button>
                </div>
              </div>
              <form [formGroup]="loanItems" class="loanItems">

                <div formArrayName="golditems"
                  *ngFor="let creds of loanItems.controls.golditems?.value; let i = index; trackBy:trackByFn">
                  <div class="golditemcontainer">
                    <div class="itemheading">Item {{ i+1 }}</div>
                    <ng-container [formGroupName]="i">
                      <div class="inputsection">
                        <mat-form-field>
                          <mat-label>Type</mat-label>
                          <input matInput formControlName="type" required>
                        </mat-form-field>
                      </div>
                      <div class="inputsection">
                        <mat-form-field>
                          <mat-label>Weight</mat-label>
                          <input matInput formControlName="weight" class="form-control" required>
                        </mat-form-field>
                      </div>
                      <div class="inputsection">
                        <mat-form-field>
                          <mat-label>Addtional info</mat-label>
                          <input matInput formControlName="additional" class="form-control">
                        </mat-form-field>
                      </div>
                      <div class="inputsection">
                        <mat-form-field>
                          <mat-label>Carats</mat-label>
                          <input matInput formControlName="carats" class="form-control" required>
                        </mat-form-field>
                      </div>

                      <div class="inputsection" id="uploader">
                        <label for="file">Click on button or drag drop image below.</label>
                        <input type="file" (change)="uploadFile($event, i)" id="file"
                          accept=".png, .jpg, .jpeg, .gif" />
                      </div>
                      <div class="istatus" id="status{{i}}"></div>
                    </ng-container>
                  </div>
                </div>
              </form>

            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>

      <div class="uploaded" id="uploaded">
        <div class="uploadedd">
          <div class="uploadertitle">The gold loan has been added successfully!</div>
          <div class="roption" [routerLink]="['/goldloans']">View Gold loans</div>
          <a class="roption" href="https://prakahsgold.com.au/newgoldloan">Add Another gold loan</a>
        </div>
      </div>

      <div class="container" id="loading">
        <div class="loader">
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--dot"></div>
          <div class="loader--text"></div>
        </div>
      </div>

    </div>
  </div>
</div>
<ng-template #show>
  <div class="errorscreen">
    <div class="errortitle">Unauthorised Access.</div>
  </div>
</ng-template>

<div class="notification" id="notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
    viewBox="0 0 24 24">
    <path
      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z" />
  </svg>
  <div id="notificationtext"></div>
</div>