<div class = "flexscreen">
    <div class = "screenform">
<div class = "screentitle">List of accounts with admin control</div>
<div class = "screenusercontainer">
<div
          class="screenaccount"
          *ngFor="let admin of admins; let i = index"
          [class.active]="i == currentIndex"
          (click)="setActiveAdmin(admin, i)">
          {{ admin.details.email }}
</div>
    </div>
<div *ngIf="currentAdmin">
<app-editadmin
          (refreshList)="refreshList()"
          [admin]="currentAdmin"
        ></app-editadmin>
      </div>
<div *ngIf="!currentAdmin">
        <br />
        <div class = "screentitle">Click on an account to edit</div>
      </div>
    </div>
</div>
  