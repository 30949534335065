<div *ngIf="auth.user | async as user; else show">
    <div class="printscreen">
        <div class="users">
            <div class="user">
                <div class="mainhead">
                    <div class="mtitle">Prakahs PTY LTD</div>
                    <div class="msub">ABN: 78 165 251 445<br>
                        127 Walker St. Dandenong VIC 3175<br>
                        Call: Dan 0421279530<br>
                    </div>
                </div>
                <div class="deets">
                    <div class="leftdeets">
                        <div class="userlabel red">Receipt Number : {{ gloan?.id | number:'6.0' | noComma}}</div>
                        <div class="userlabel">Name : {{ gloan?.details.name }}</div>
                        <div class="userlabel">Phone : {{ gloan?.details.phone }}</div>
                        <div class="userlabel">Address : {{ gloan?.details.address }}</div>
                        <div class="userlabel">Email : {{ gloan?.details.email }}</div>
                        <div class="userlabel">Date : {{ gloan?.date | date }}</div>
                        <div class="userlabel">Description : {{gloan?.details.description}}</div>
                        <div class="userlabel">Total Weight (Grams) : {{gloan?.details.totalweight}}</div>
                        <div class="userlabel">Amount Paid : ${{ gloan?.details.amountpaid }}</div>
                        <div class="xtitle">Contract Agreement:</div>
    <div class="xbody">
      I declare I am the legal owner of the goods described above. I have sold the items to Prakahs Pty Ltd, in doing so I
      relinquish any claims I may have against the sold items.</div>
                        <div class = "sign">
                        <img src = "{{ gloan?.signurl }}"/>
                        </div>
                        <div class="userlabel">Signed on {{ gloan?.signatureDate.date.toDate() | date:'dd/MM/yyyy' }}</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    </div>
<ng-template #show>
    <div class="errorscreen">
        <div class="errortitle">Unauthorised Access.</div>
    </div>
</ng-template>