import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import Loan from 'src/app/models/loan.model';

@Component({
  selector: 'app-idupload',
  templateUrl: './idupload.component.html',
  styleUrls: ['./idupload.component.css']
})
export class IduploadComponent implements OnInit {

  id:string;
  pageid:string;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  loansCollection: AngularFirestoreCollection<any>;
  loans: Observable<any[]>;
  loan: any;

  constructor(
    private route: ActivatedRoute,
    public auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private afs: AngularFirestore
    ) {
    }

  ngOnInit() {
    this.route.paramMap.subscribe(params => { 
      this.pageid = params.get('id'); 
  });
}

    uploadFile(event) {

      this.route.paramMap.subscribe(params => { 
        document.getElementById('loading').classList.add('load');
        this.id = params.get('id');
        const file = event.target.files[0];
        const filePath = 'userids/' + this.id;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);
        this.uploadPercent = task.percentageChanges();
        task.snapshotChanges().pipe(
        finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(downloadURLResponse => { 
              var surl = downloadURLResponse;
              const gloansRef = this.afs.collection('users');
              gloansRef.doc(this.id).update({ 
                "profile.photoid": surl
              });
            });
            document.getElementById('loading').classList.remove('load');
            document.getElementById('uploader').classList.add('hide');
            document.getElementById('uploaded').classList.add('finished');
            this.notificationPop('Uploaded successfully');
        }),
        )
        .subscribe()
        });
    }

notificationHide() {
document.getElementById('notification').classList.remove('pop');
}

notificationDisplay() {
document.getElementById('notification').classList.add('pop');
}

notificationPop(message: string) {
  document.getElementById('notificationtext').innerHTML = message;
  this.notificationDisplay();
  setTimeout(this.notificationHide, 2500);
}
}