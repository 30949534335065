import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { 
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
      var currentScrollPos = window.pageYOffset;
      if(currentScrollPos > 0) {
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("hlogo").style.top = "0";
      } else {
        document.getElementById("hlogo").style.top = "-500px";
      }
      prevScrollpos = currentScrollPos;
    }
    else {
      document.getElementById("hlogo").style.top = "0";
    }
  }
  }

  ngOnInit(): void {
  }

  expandMenu() {
    var menu = document.getElementById('mmenuoptions');
    var menuicon = document.getElementById('hmenu');
    console.log('clicked');
    if (menu?.classList.contains('show')) {
    menu?.classList.remove('show');
    menuicon?.classList.remove('show');
    }
    else {
    menu?.classList.add('show');
    menuicon?.classList.add('show');
  }
}


}
