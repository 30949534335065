<div *ngIf="auth.user | async as user; else show">
    <div class = "printscreen">
            <div class = "users">
            <div class = "user">

            <div class = "red" *ngIf = "gloan?.status == 4"></div>
            <div class = "green" *ngIf = "gloan?.status == 1"></div>

            <div class = "mainhead">
            <div class = "mtitle">Prakahs PTY Limited</div>
            <div class = "msub">ABN: 78 165 251 445<br>
            127 Walker St. Dandenong VIC 3175<br>
            Call or Text: Dan 0421279530<br>
            </div>
            </div>
            <div class = "loantitle"><div>PAWN TICKET  #{{ id }}</div></div>
            <div class = "deets">
            <div class = "userlabel">Name : {{ guser?.details.name }}</div>
            <div class = "userlabel">Address : {{ guser?.details.address }}</div>
            <div class = "userlabel">Phone : {{ guser?.details.phone }}</div>
            <div class = "userlabel">Email : {{ guser?.details.email }}</div>
            <div class = "userlabel">Type : {{ gloan?.details.type }}</div>
            <div class = "userlabel">Date : {{ gloan?.details.date.toDate() | date:'dd/MM/yyyy' }}</div>
            <div class = "userlabel">Transaction Fee : ${{ gloan?.details.transactionfee }}</div>
            <div class = "userlabel">Principle : ${{gloan?.details.principle}}</div>
            <div class = "userlabel">Rate of Interest % : {{gloan?.details.rate}} ({{ gloan?.details.type }})</div>
            <div class = "userlabel">Duration : {{gloan?.details.time}}</div>
            <div class = "userlabel">EMI : ${{ gloan?.details.emi }}</div>
            <div class = "userlabel">Total Interest : ${{ gloan?.details.totalinterest }}</div>
            <div class = "userlabel">Loan Outstanding : ${{ gloan?.details.principle - totalloanpaid }}</div>

            <div *ngIf = "gloan?.fullpaymentdate">
                <div class = "userlabel">Full Payment received on : {{ gloan?.fullpaymentdate | date }}</div>
                <div class = "userlabel">Full Payment Interest received : ${{ gloan?.fullpaymentloanpaid }}</div>
                <div class = "userlabel">Full Payment Loan received : ${{ gloan?.fullpaymentinterestpaid }}</div>
            </div>

            <div *ngIf = "gloan?.loanclosedate">
                <div class = "userlabel">Loan closed on : {{ gloan?.loanclosedate | date }}</div>
                <div class = "userlabel">Loan close Interest received : ${{ gloan?.loanclosefinalpaid }}</div>
                <div class = "userlabel">Loan close Loan received : ${{ gloan?.loancloseinterestpaid }}</div>
            </div>


            </div>
            <div class = "gitemholder">
                <div *ngFor = "let doc of gloan?.items | keyvalue: undefined; let ix = index">
                <div class = "itemc">
                <div class = "itemp">
                <img src = "{{ gloan?.urls[ix] }}"/>
                </div>
                <div class = "xlabel">Gold Item {{ ix+1 }}</div>
                <div class = "xlabel">Type : {{ doc.value.type }}</div>
                <div class = "xlabel">Weight : {{ doc.value.weight }}</div>
                <div class = "xlabel">Carats : {{ doc.value.carats }}</div>
                <div class = "xlabel">Additional info : {{ doc.value.additional }}</div>
                <div class = "xlabel" *ngIf = "doc.value.status">Status : {{ doc.value.status }}</div>
                </div>
                </div>
                </div>
            <div class = "deets">
                
                <div class = "paymentss" *ngFor = "let pdoc of gloan?.pendingpayments | keyvalue: 0; let px = index" id = "payment{{ix+1}}"> 
                    <div class = "ebox">
                    <div class = "ehead">Payment {{ px+1 }}</div>
                    <div class = "elabel">Due on : {{ pdoc.value.pendingdate | date:'dd/MM/yyyy' }}</div>
                    <div class = "elabel">Status : {{ pdoc.value.status }}</div>
                    <div *ngIf = "pdoc.value.status == 'Paid' || pdoc.value.status == 'Half Paid'">
                    <div class = "elabel">Payment received on : {{ pdoc.value.date | date:'dd/MM/yyyy' }}</div>
                    <div class = "elabel">Interest received : ${{ pdoc.value.interestreceived }}</div>
      <div class = "elabel">Loan returned : ${{ pdoc.value.loanreturned }}</div>
    </div>



    </div>
    </div>
    </div>
</div>
</div>
</div>
</div>
    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>