<div class="hero hidden">
    <div class="hero-text">
        <h1 class="hero-heading h1hero">Gold loan and Gold buyer specialists</h1>
            <ul class="hero-description">
                <li>GOLD BUYERS IN DANDENONG</li>
                <li>GOLD JEWELLERY SELLER</li>
                <li>LOANS AT LOWEST RATES</li>
                <li>RECEIVE INSTANT CASH LOANS</li>
                <li>NO CREDIT CHECKS</li>
                <li>No appointments required - Receive your cash loan in minutes with our fast service from our Dandenong office.</li>
            </ul>

    </div>
</div>
<div class="xresponsive">
    <h2>Our Services</h2>
    <ul>
        <li>Sell your unwanted Gold for the Best Price</li>
        <li>Get loans on Gold items fast</li>
        <li>Sell your unwanted Silver items</li>
        <li>Competitive interest rates on a loan for your gold</li>
        <li>We offer short term and long term loans</li>
        <li>We Give Instant Cash</li>
        <li>No Appointments required</li>
        <li>We loan to everyone with a valid ID</li>
        <li>No credit checks</li>
    </ul>
    <div class="buttonarea">
        <button [routerLink]="['/sell-gold-rates-melbourne']">View Buy Rates</button>
        <button [routerLink]="['/loan-rates-melbourne']">View Loan Rates</button>
    </div>

    <h2>We buy and loan against</h2>
    <ul>
        <li>9ct - 24ct Gold jewelry</li>
        <li>Gold coins</li>
        <li>Gold bullions</li>
        <li>Scrap Gold</li>
        <li>We only buy silver and do not loan against it</li>
    </ul>
    <h2>Getting a loan is as simple as</h2>
    <ul>
        <li>Visiting us in store at Dandenong</li>
        <li>We will assess and value your gold items with you</li>
        <li>We will pay you in cash on the spot</li>
        <li>We are open Mon-Sat 10am to 5:30pm</li>
    </ul>
    <p>We access and value your gold using sophisticated XRF
        Technology for 100% accurate analysis, so you can be rest
        assured you are getting the highest prices.</p>

    <h2 class = "centered">IDENTIFICATION REQUIREMENT</h2>
    <h3 class="notop">ONE of the below photo identification:</h3>
    <ul>
        <li>a passport (local or foreign)</li>
        <li>a driver's licence (Full or Learners)</li>
        <li>a tertiary education photo ID</li>
        <li>a licence issued under the Firearms Act 1996.</li>
        <li>a key-pass ID cards</li>
    </ul>
    <h3>OR TWO forms of identification below:</h3>


    <ul>
        <li>a full birth certificate or extract</li>
        <li>a certificate of Australian citizenship</li>
        <li>a marriage certificate</li>
    </ul>
    <h3>Only one of the following health or pensioner cards:</h3>
    <ul>
        <li>health care card</li>
        <li>pensioner concession card</li>
        <li>Commonwealth seniors health card</li>
    </ul>
    <p class = "centered">We have been operating for over 10 years and are a
        reputable dealer.</p>

<div class = "ratesarea">
    <h4>WE BUY GOLD AT</h4>
    <div class="xrates bottompad">
        <div class="xratesleft">
            <div>24 ct</div>
            <div>22 ct</div>
            <div>18 ct</div>
            <div>14 ct</div>
            <div>9 ct</div>
        </div>
        <div class="xratesright" *ngFor="let loan of goldrates$ | async">
            <div>${{ loan.e }}</div>
            <div>${{ loan.d }}</div>
            <div>${{ loan.c }}</div>
            <div>${{ loan.b }}</div>
            <div>${{ loan.a }}</div>
        </div>
        <div class="xratesend">
            <div>per gram</div>
            <div>per gram</div>
            <div>per gram</div>
            <div>per gram</div>
            <div>per gram</div>
        </div>
    </div>
    <h4>WE PAWN GOLD AT</h4>
    <div class="xrates">
        <div class="xratesleft">
            <div>24 ct</div>
            <div>22 ct</div>
            <div>18 ct</div>
            <div>14 ct</div>
            <div>9 ct</div>
        </div>
            <div class="xratesright" *ngFor="let loanr of goldrates$ | async">
                <div>${{ loanr.j }}</div>
                <div>${{ loanr.i }}</div>
                <div>${{ loanr.h }}</div>
                <div>${{ loanr.g }}</div>
                <div>${{ loanr.f }}</div>
            </div>
            <div class="xratesend">
                <div>per gram</div>
                <div>per gram</div>
                <div>per gram</div>
                <div>per gram</div>
                <div>per gram</div>
            </div>


</div>
<div class = "pricedisclaimer">Prices updated: {{ now | date }}. Prices are subject to change.</div>
    </div>


<p class = "centered">We are transparent with our loan rates/fees</p>

<div class="rates">
    <div class="ratesleft">
    <div class = "rtitle">Loan Amount</div>
<div>$100 +</div>
<div>$2000 +</div>
<div>$5000 +</div>
<div>$10,000 +</div>
<div>$20,000 +</div>
    </div>
    <div class="ratesright" *ngFor="let loan of loanrates$ | async">
        <div class = "rtitle">7 day rates</div>
        <div>{{ loan.f }}</div>
        <div>{{ loan.g }}</div>
        <div>{{ loan.h }}</div>
        <div>{{ loan.i }}</div>
        <div>{{ loan.j }}</div>
    </div>

<div class="ratesright" *ngFor="let loan of loanrates$ | async">
<div class = "rtitle">30 day rates</div>
<div>{{ loan.a }}</div>
<div>{{ loan.b }}</div>
<div>{{ loan.c }}</div>
<div>{{ loan.d }}</div>
<div>{{ loan.e }}</div>
</div>

  </div>

<p>We provide our interest rates upfront for a 30 day
period and these rates are offered with a one-off $10
transaction fee. If you want to keep the loan for
more than 30 day just pay the next months interest.</p>

<div class="buttonareax">
    <a class="buttonx" id="nleft" href="tel:(03) 8772 2557">Call Now</a>
    <a class="buttonx"
      href="https://www.google.com/maps/dir/?api=1&destination=127%20Walker%20Street,%20Dandenong%20VIC%203175">Visit
      Store</a>
  </div>

</div>
<div class = "maps">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.560134693869!2d145.21334681499394!3d-37.9873933797213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad615e3034a70b5%3A0x894e9b5e95fa4d2c!2sPrakahs%20Gold%20Finance!5e0!3m2!1sen!2sin!4v1637671254267!5m2!1sen!2sin" height="450" style="border:0; width:100%;" allowfullscreen="" loading="lazy"></iframe>
</div>
<app-footer></app-footer>