import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { DateTime } from "luxon";
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';
import { map, filter } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-rgoldloans',
  templateUrl: './rgoldloans.component.html',
  styleUrls: ['./rgoldloans.component.css']
})
export class RgoldloansComponent implements OnInit {

  fileName = 'Revenue_GoldLoans.xlsx';
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  now: any;
  loanarray: any;

  constructor(private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private excelService: ExcelService) {
  }

  ngOnInit(): void {
    this.now = DateTime.now();
    this.loanarray = [];
  }

  filterByDate() {
    const queryObservable = this.afs.collection('goldloans', ref => ref.orderBy('id', 'desc')).valueChanges();
    queryObservable.subscribe(queriedItems => {
      for (let [index, loan] of queriedItems.entries()) {
        const [cchecker, ffrevenue] = this.filterLoans(loan);
        if (cchecker == true && ffrevenue !== 0) {
          this.loanarray[index] = loan;
          this.loanarray[index].erevenue = ffrevenue;
        }
      }
    });
  }

  filterLoans(loan) {
    var xstatus = loan.status;
    var revenue: number = 0;
    var loanpaid: number = 0;
    var checker;
    checker = true;
    for (var looper = 0; looper < loan.details.time; looper++) {
      var datetobechecked = new Date(loan.pendingpayments[looper].date);
      if (datetobechecked >= this.range.controls['start'].value && datetobechecked <= this.range.controls['end'].value) {
        checker = true;
        if (loan.pendingpayments[looper].interestreceived) {
          revenue = revenue + parseInt(loan.pendingpayments[looper].interestreceived);
        }
      }
    }
    if (xstatus == 1) {
      var fdate = new Date(loan.fullpaymentdate);
      if (fdate >= this.range.controls['start'].value && fdate <= this.range.controls['end'].value) {
        checker = true;
        revenue = revenue + parseInt(loan.fullpaymentinterestpaid);
        loanpaid = loanpaid + parseInt(loan.fullpaymentloanpaid);
      }
    }
    else if (xstatus == 4) {
      var cdate = new Date(loan.loanclosedate);
      if (cdate >= this.range.controls['start'].value && cdate <= this.range.controls['end'].value) {
        checker = true;
        revenue = revenue + parseInt(loan.loancloseinterestpaid);
        loanpaid = loanpaid + parseInt(loan.loanclosefinalpaid);
      }
    }
    return [checker, revenue];
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    this.excelService.exportAsExcelFile(wb, 'RevenueGoldLoans');
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}