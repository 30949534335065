import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})

export class TestComponent {
  iurl = new FormControl('');
  imgu: String;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string|null>;
  userDetails: FormGroup;
  userProfile: FormGroup;
  submitted = false;

  metrics: Array<any>;
  metrics$: Observable<any[]>;

  users$:any;
  users:number;
  puser: any;
  userid: any;

  constructor(
    public auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private formBuilder: FormBuilder
    ) {
      this.users$ = this.afs.collection(`users`).valueChanges().subscribe(userss => {
        this.users = userss.length + 1;
        });
        this.metrics$ = this.afs.collection('metrics').valueChanges();
    }
  
ngOnInit() {
        this.userDetails = this.formBuilder.group({
            name: ['', Validators.required],
            photoidno: ['', Validators.required],
            email: ['', Validators.email],
            phone: ['', Validators.required],
            address: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.userProfile = this.formBuilder.group({
          photoid: ['', Validators.required]
        });
    }

    get fdetails() { return this.userDetails.controls; }
    get fprofile() { return this.userProfile.controls; }

    addUser() {
        this.submitted = true;
        this.userDetails.markAllAsTouched();
        this.userProfile.markAllAsTouched();
        if ((this.userDetails.invalid) || (this.userProfile.invalid)) {
          this.notificationPop("Check credentials");
            return;
        }
          this.createUser();      
    }

    uploadFile(event) {
      this.afs.collection('metrics').doc('users').ref.get().then((udoc) => {
      if (udoc.exists) {
      this.puser = udoc.data();
      this.userid = this.puser.useridgen+1;

      document.getElementById('useraddform').classList.add('vanish');
      document.getElementById('loading').classList.add('load');
      const file = event.target.files[0];
      const filePath = 'userids/' + this.userid;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(downloadURLResponse => { 
              this.imgu = downloadURLResponse;
              this.userProfile.controls['photoid'].setValue(this.imgu);
              this.createUser();
            });
           }),
       )
      .subscribe()
    }
    else {
      console.log("No such document!");
  }
}).catch((error) => {
  console.log("Error getting document:", error);
});
    }
    

    createUser() {

      this.afs.collection('metrics').doc('users').ref.get().then((udoc) => {
        if (udoc.exists) {
          this.puser = udoc.data();
          this.userid = this.puser.useridgen+1;
          let user = [
            { details: this.userDetails.value }, 
            { profile: this.userProfile.value }
            ],
            object = Object.assign({}, ...user);
              this.afs.collection(`users`).doc(this.userid.toString()).set(object);
              this.afs.collection('metrics').doc('users').update({
                "useridgen": this.userid
            }).then(() => {
            this.notificationPop("New customer added successfully!");
            document.getElementById('loading').classList.remove('load');
            document.getElementById('uploader').classList.add('hide');
            document.getElementById('uploaded').classList.add('finished');
            }).catch((error) => {
            this.notificationPop("New customer add failed!");
            });
        } else {
            console.log("No such document!");
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
      }
    onReset() {
        this.submitted = false;
        this.userDetails.reset();
        this.userProfile.reset();
    }
    getErrorMessage() {
      if (this.userDetails.controls['email'].hasError('required')) {
        return 'Email is required.';
      }
      return this.userDetails.controls['email'].hasError('email') ? 'Please enter a valid email.' : '';
    }
    getPasswordError() {
      if (this.userDetails.controls['password'].hasError('required')) {
        return 'Password is required.';
      }
      return this.userDetails.controls['password'].hasError('minlength') ? 'Password needs to be atleast 6 characters.' : '';
    }
goForward(stepper: MatStepper){
    stepper.next();
}

notificationHide() {
document.getElementById('notification').classList.remove('pop');
}

notificationDisplay() {
document.getElementById('notification').classList.add('pop');
}

notificationPop(message: string) {
  document.getElementById('notificationtext').innerHTML = message;
  this.notificationDisplay();
  setTimeout(this.notificationHide, 2500);
}
}
