<div *ngIf="auth.user | async as user; else show">
  <div class = "flexscreen">
    <div class = "heading">
      <div class = "formtitle">View purchased items</div>
      <div class = "formsubtitle">View a list of all the purchased gold/jewellery</div>
    </div>
      <div class = "users">
        <div *ngFor="let user of item$ | async; let ix = index">
          <div class = "user">
          <div class = "userdp">
          <img [src] = "user.profile.photo"/></div>
          <div class = "userlabel"><span>Id</span> : {{user.id}}</div>
        <div class = "userlabel"><span>Date</span> : {{user.date | date }}</div>
        <div class = "userlabel"><span>Description</span> : {{user.details.description}}</div>
        <div class = "userlabel"><span>Additional Info</span> : {{ user.details.additional }}</div>
        <div class = "userlabel"><span>Total Weight</span> : {{ user.details.totalweight }}</div>
        <div class = "userlabel"><span>Amount Paid</span> : {{ user.details.amountpaid }}</div>
        <div class = "userlabel"><span>Purchased from</span> : {{ user.details.name }}</div>
        <div class = "userlabel"><span>Customer Phone</span> : {{ user.details.phone }}</div>
        <div class = "userlabel"><span>Customer Email</span> : {{ user.details.email }}</div>
        <div class = "userlabel"><span>Customer Address</span> : {{ user.details.address }}</div>
          <div *ngIf = "user.details.status" class = "userlabel"><span>Status</span> : {{ user.details.status }}</div>
          <div *ngIf = "user.details.soldamount" class = "userlabel"><span>Sold for</span> : ${{ user.details.soldamount }}</div>
          <button mat-raised-button color="primary" class = "txbutton"
          [routerLink]="['/printpurchased', user.id]">Print Ticket</button>

          <div class = "txbutton" *ngIf = "user.signed !== 'yes'"
          [routerLink]="['/goldsign', user.id ]">Add Signature</div>
          
          <div class = "txbutton" *ngIf = "user.signed == 'yes'"
          [routerLink]="['/goldsign', user.id ]">Edit Signature</div>

          

          <button mat-raised-button color="primary" class = "txbutton" (click) = "showItemForm(ix)">Update status</button>
          <form class = "gform" id = "gform{{ix}}">
          <mat-form-field>
            <mat-label>
                Status
            </mat-label>
            <input matInput
            class="form-control"
            name="status"
            [(ngModel)]="user.details.status"  
            >
          </mat-form-field> 

          <mat-form-field>
            <mat-label>Date sold on</mat-label>
            <input matInput [matDatepicker]="picker" name = "selldate"
            [(ngModel)]="user.details.selldate" class="form-control" required />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>
                Amount items sold for
            </mat-label>
            <input matInput
            class="form-control"
            name="soldamount"
            [(ngModel)]="user.details.soldamount" 
            type="number" 
            >
          </mat-form-field>  
          <button mat-raised-button color="primary" class = "txbutton" (click)="updateStatus(user.id, user.details.status, user.details.selldate, user.details.soldamount)">Finish updating status</button>
          </form> 
        </div>
    </div>
  </div>
  </div>
  </div>
  <ng-template #show>
    <div class = "errorscreen">
    <div class = "errortitle">Unauthorised Access.</div>
    </div>
  </ng-template>
<div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
<div id = "notificationtext"></div>
</div>