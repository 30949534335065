<div class = "xscreen">
  <div class="loan-group">
    <input class="form-control" id="rate" #rate (keyup)="filterBySize(rate.value)" placeholder="Enter Name of User">
    </div>
    <div *ngIf="items$ | async; let items; else loading">
      <ul>
        <li *ngFor="let item of items" (click) = "chooseUser(item.name)">
          {{ item.name }}
        </li>
      </ul>
      <div *ngIf="items.length === 0">No results, try clearing filters</div>
    </div>
    <ng-template #loading>Loading&hellip;</ng-template>
</div>
