<div *ngIf="auth.user | async as user; else show">
    <div class = "flexscreen">
      <div class = "heading">
        <div class = "formtitle">Edit loans</div>
        <div class = "formsubtitle">Edit existing personal loans.</div>
      </div>
    
      <input class="search-box" placeholder="Search using loan id" (keyup)=search($event.target.value) type="text">
            <div class = "users" *ngFor="let loan of filteredLoans$ | async">
            <div class = "user" id = "{{ loan.id }}">
            <div class = "loantitle">{{loan.id}}</div>
            <div class = "userlabel">Type : {{loan.details.type}}</div>
            <div class = "userlabel">Date : {{loan.date}}/{{loan.month}}/{{loan.year}}</div>
            <div class = "userlabel">Customer Name : {{loan?.details.name}}</div>
            <div class = "userlabel">Customer Address : {{loan?.details.address}}</div>
            <div class = "userlabel">Customer Phone : {{loan?.details.phone}}</div>
            <div class = "userlabel">Customer Email : {{loan?.details.email}}</div>
            <div class = "userlabel">Transaction Fee : ${{ loan.details.transactionfee }}</div>
            <div class = "userlabel">Loan Amount : ${{loan.details.principle}}</div>
            <div class = "userlabel">Rate of Interest % : {{loan.details.rate}}</div>
            <div class = "userlabel">Duration : {{loan.details.time}}</div>
            <div class = "userlabel">Total Interest : ${{ loan.details.totalinterest }}</div>
            <div class="userlabel">{{ loan?.details.type }} Interest : ${{
              loan?.details.totalinterest/loan?.details.time | number: '1.0-2' }}</div>
            <div class = "userlabel">Total Revenue : ${{ loan.totalrevenue }}</div>
            <div class = "userlabel">Comment : {{ loan.details.comment }}</div>

            <div class = "editbuttons">
                <div class = "editbutton" (click) = "showComment($event)">Comment</div>
                <div class = "editbutton" (click) = "showEditor($event)">Edit loan</div>
                <div class = "editbutton" (click) = "deleteLoan(loan.id)">Delete loan</div>
                </div>

                <form class = "editform" id = "editform" #f="ngForm">
<mat-form-field>
  <mat-label>Choose duration</mat-label>
  <mat-select 
  [value]="loan.details.type"
  name="type" 
  #type (keyup)="0"
  required>
    <mat-option *ngFor="let dur of durs" [value]="dur.value">
      {{dur.value}}
    </mat-option>
  </mat-select>
</mat-form-field>
  <mat-form-field>
    <mat-label>
        Transaction Fee
    </mat-label>
    <input matInput
    class="form-control"
    name="transactionfee"
    [(ngModel)]="loan.details.transactionfee"
    required 
    >
</mat-form-field>
<mat-form-field>
    <mat-label>
        Loan Amount
    </mat-label>
    <input matInput
    class="form-control"
    #principle (keyup)="0"
    name="principle"
    [(ngModel)]="loan.details.principle"
    required 
    >
</mat-form-field>
<mat-form-field>
  <mat-label>
      Rate
  </mat-label>
  <input matInput
  class="form-control"
  #rate (keyup)="0"
  name="rate"
  [(ngModel)]="loan.details.rate"
  required  
  >
</mat-form-field>
<mat-form-field>
    <mat-label>
        Time Period
    </mat-label>
    <input matInput
    class="form-control"
    #time (keyup)="0"
    [defaultValue]="loan.details.time"
    name="time"
    required  
    >
</mat-form-field> 
<button mat-raised-button color="primary" class = "xbutton" (click)="updateLoan(loan.id, loan.details.type, type.value, loan.details.transactionfee, loan.details.principle, loan.details.rate, loan.details.time, time.value, loan.details.date)">Finish editing loan</button>
                
</form>             

<form class = "commentform" id = "commentform" #f="ngForm">
    <mat-form-field>
      <mat-label>
          Comment
      </mat-label>
      <input matInput
      class="form-control"
      name="comment"
      [(ngModel)]="loan.details.comment"
      required 
      >
  </mat-form-field>
  <button mat-raised-button color="primary" class = "xbutton" (click)="updateComment(loan.id, loan.details.comment)">Add comment</button>
                  
  </form>   


      </div>
    </div>
    </div>
    </div>
    
    <ng-template #show>
      <div class = "errorscreen">
      <div class = "errortitle">Unauthorised Access.</div>
      </div>
    </ng-template>

    <div class = "notification" id = "notification"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
    <div id = "notificationtext"></div>
    </div>