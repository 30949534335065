import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateTime } from "luxon";

@Component({
  selector: 'app-addpgold',
  templateUrl: './addpgold.component.html',
  styleUrls: ['./addpgold.component.css']
})
export class AddpgoldComponent implements OnInit {
  iurl = new FormControl('');
  imgu: String;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string|null>;
  userDetails: FormGroup;
  userProfile: FormGroup;
  submitted = false;

  users$:any;
  users:number;
  userlist$:Observable<any>;
  filteredUsers$: any;

  item: any;
  itemid: any;

  constructor(
    public auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private formBuilder: FormBuilder
    ) {
        this.userlist$ = this.afs.collection('users').valueChanges({ idField: 'uid' });
    }
  
ngOnInit() {
        this.filteredUsers$ = this.userlist$;
        this.userDetails = this.formBuilder.group({
            uid: [''],
            name: [''],
            address: [''],
            phone: [''],
            email: [''],
            description: ['', Validators.required],
            additional: ['', Validators.required],
            totalweight: ['', Validators.required],
            amountpaid: ['', Validators.required]
        });
        this.userProfile = this.formBuilder.group({
          photo: ['', Validators.required]
        });
    }

    get fdetails() { return this.userDetails.controls; }
    get fprofile() { return this.userProfile.controls; }

    addUser() {
        this.submitted = true;
        this.userDetails.markAllAsTouched();
        this.userProfile.markAllAsTouched();
        if ((this.userDetails.invalid) || (this.userProfile.invalid)) {
          this.notificationPop("Check credentials");
            return;
        }
          this.createUser();      
    }

    uploadFile(event) {
      document.getElementById('useraddform').classList.add('vanish');
      document.getElementById('loading').classList.add('load');
      const file = event.target.files[0];
      const filePath = 'purchaseditems/' + this.userDetails.value.uid;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      this.uploadPercent = task.percentageChanges();
      task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(downloadURLResponse => { 
              this.imgu = downloadURLResponse;
              this.userProfile.controls['photo'].setValue(this.imgu);
              this.createUser();
            });
           }),
       )
      .subscribe()
    }

    createUser() {
      const now = DateTime.now().toString();
      this.afs.collection('metrics').doc('items').ref.get().then((udoc) => {
        if (udoc.exists) {
          this.item = udoc.data();
          this.itemid = parseInt(this.item.itemidgen) + 1;
            let user = [
              { details: this.userDetails.value }, 
              { profile: this.userProfile.value },
              { id: this.itemid },
              { date: now }
              ],
              object = Object.assign({}, ...user);
          this.afs.collection(`purchaseditems`).doc(this.itemid.toString()).set(object);
          this.afs.collection('metrics').doc('items').update({
            "itemidgen": this.itemid
        }).then(() => {
        this.notificationPop("New purchased item added successfully!");
        document.getElementById('loading').classList.remove('load');
        document.getElementById('uploader').classList.add('hide');
        document.getElementById('uploaded').classList.add('finished');
        }).catch((error) => {
        this.notificationPop("New loan add failed!");
        });
        }
        else {
          console.log("No such document!");
      }
      }).catch((error) => {
      console.log("Error getting document:", error);
      });
      }
    onReset() {
        this.submitted = false;
        this.userDetails.reset();
        this.userProfile.reset();
    }
    getErrorMessage() {
      if (this.userDetails.controls['email'].hasError('required')) {
        return 'Email is required.';
      }
      return this.userDetails.controls['email'].hasError('email') ? 'Please enter a valid email.' : '';
    }
    getPasswordError() {
      if (this.userDetails.controls['password'].hasError('required')) {
        return 'Password is required.';
      }
      return this.userDetails.controls['password'].hasError('minlength') ? 'Password needs to be atleast 6 characters.' : '';
    }
    goForward(loanuid, name, phone, email, address, stepper: MatStepper) {
      this.userDetails.controls['uid'].setValue(loanuid);
      this.userDetails.controls['name'].setValue(name);
      this.userDetails.controls['address'].setValue(address);
      this.userDetails.controls['phone'].setValue(phone);
      this.userDetails.controls['email'].setValue(email);
      stepper.next();
    }

    addPictures(stepper: MatStepper){
      stepper.next();
  }
  


notificationHide() {
document.getElementById('notification').classList.remove('pop');
}

notificationDisplay() {
document.getElementById('notification').classList.add('pop');
}

notificationPop(message: string) {
  document.getElementById('notificationtext').innerHTML = message;
  this.notificationDisplay();
  setTimeout(this.notificationHide, 2500);
}


showHide(user: any) {
  // for each user we can add a property of show and use this as 
  // a boolean flag to turn each one on/off for a list of any size
  user.show = !user.show;
}

search(value: string) {
  if (value) {


    this.filteredUsers$ = this.userlist$
      .pipe(
        // the stream is of a single item that is of type array
        // map(user => user.name) would not work because it is not // a stream of items inside the array
        map((users: any[]) => {
          // inside the map we use the native Array.prototype.filter() method to filter down the results by name
          return users.filter((user: any) => user.details.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
        })

      )
  } else {
    // reload the full data set
    this.filteredUsers$ = this.userlist$;
  }
}

}







